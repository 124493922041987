import { connect } from 'react-redux'
import cx from 'classnames'
import React from 'react'
import Breadcrumb from '../../app/components/Breadcrumb'
import { ReactComponent as LogoHelpCircle } from '../../assets/images/LogoComponents/ios-help-circle.svg';

const PageTitle = ({
  hint,
  enablePageTitleSubheading,
  rightBlock,
  heading,
  icon,
  iconClass,
  subheading
}) => {
  return (
    <div className='app-page-title'>
      <div className='page-title-wrapper'>
        <div className='page-title-heading d-flex flex-column align-items-center'>
          <div className='d-flex align-items-center w-100'>
            <div className='mr-2 d-flex align-items-center'>
              {icon ? icon : <i className={iconClass} />}
            </div>
            <div>
              {heading}

              {subheading && (
                <div
                  className={cx('page-title-subheading', {
                    'd-none': !enablePageTitleSubheading
                  })}>
                  {subheading}
                </div>
              )}
            </div>
          </div>

          {hint && (
            <div className='w-100 d-flex flex-1 pt-2'>
              <div
                className='d-flex align-items-center badge badge-pill badge-light pr-3'
                style={{
                  textTransform: 'unset',
                  fontSize: 10,
                  backgroundColor: '#f1f1f1',
                  color: '#495057',
                  padding: 4
                }}>
                <LogoHelpCircle
                  className='mr-1 SVGLogo'
                  style={{ fill: "#f7b924" }}
                />
                {hint}
              </div>
            </div>
          )}
        </div>
        <div className='page-title-actions'>{rightBlock}</div>
      </div>
      <Breadcrumb/>
    </div>
  )
}

const mapStateToProps = state => ({
  enablePageTitleIcon: state.ThemeOptions.enablePageTitleIcon,
  enablePageTitleSubheading: state.ThemeOptions.enablePageTitleSubheading
})

const mapDispatchToProps = dispatch => ({})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PageTitle)
