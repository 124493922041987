import React, { useState } from 'react'
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Col } from 'reactstrap'
import { useTranslation } from 'react-i18next'
import { useEffect } from 'react'
import { PopoverBody, UncontrolledPopover } from 'reactstrap'

export default function HelpMenu() {
  const [open, setOpen] = useState(false)
  const { t } = useTranslation()

  useEffect(() => {
    window.addEventListener('click', handleEventClick)
    return () => window.removeEventListener('click', handleEventClick)
  }, [])

  const handleEventClick = () => {
    setOpen(false)
  }

  const handleClick = e => {
    e.stopPropagation()
    setOpen(open => !open)
  }
  return (
    <Col className='position-relative'>
      <FontAwesomeIcon
        onClick={handleClick}
        icon={faQuestionCircle}
        className='cursor-pointer'
        id='help-icon'
      />
      <UncontrolledPopover
        placement='bottom'
        trigger='legacy'
        target='help-icon'>
        <PopoverBody>
          <p className='m0'>{t('common.helpQuestion')}</p>
          <a
            className='tip__link'
            target='_blank'
            rel='noopener noreferrer'
            href={require(`../../../assets/files/${t(
              'common.installationManual'
            )}`)}>
            {t('common.helpLinkText')}
          </a>
          <hr className='mtb-3' />
          <a
            className='tip__link'
            target='_blank'
            rel='noopener noreferrer'
            href={require(`../../../assets/files/${t('common.csvLink')}`)}>
            {t('common.csvLinkText')}
          </a>
        </PopoverBody>
      </UncontrolledPopover>
    </Col>
  )
}
