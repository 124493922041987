import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import React, { Fragment, useEffect, useState, useContext } from 'react'
import NumberFormat from 'react-number-format';

import {
  Button,
  Card,
  CardBody,
  Col,
  Form,
  FormGroup,
  Input,
  Label
} from 'reactstrap'
import Loader from 'react-loaders'
import UserContext from './../../../contexts/authContext'

const DataForm = ({ onHandleFormSubmit, owner, loading, submitting }) => {
  let history = useHistory()
  const { t } = useTranslation()
  const [formData, setformData] = useState({})
  const { user } = useContext(UserContext)

  const handleOnChange = e => {
    setformData({
      ...formData,
      [e.target.name]: e.target.value
    })
  }

  useEffect(() => {
    let updateFormData = {
      id: owner.id,
      first_name: owner.user_details.first_name,
      last_name: owner.user_details.last_name,
      username: owner.username,
      email: owner.email,
      apartment_number: owner.flat.apartment_number,
      floor: owner.flat.floor,
      entrance_number: owner.flat.entrance_number,
      ownership_start_date: owner.flat.flat_history.ownership_start_date,
      ownership_end_date: owner.flat.flat_history.ownership_end_date,
      address: owner.flat.address,
      contact_no: owner.user_details.contact_no,
      notes: owner.notes
    }
    setformData(updateFormData)
  }, [owner])

  return (
    <Fragment>
      {loading && (
        <div className='loader-wrapper w-100 d-flex justify-content-center align-items-center'>
          <Loader type='ball-beat' />
        </div>
      )}

      {!loading && (
        <React.Fragment>
          <Card className='main-card mb-3'>
            <CardBody>
              <Col md={6}>
                <Form onSubmit={e => onHandleFormSubmit(e, formData)}>
                  <FormGroup>
                    <Label for='name'>{t('common.name')}</Label>
                    <Input
                      id='name'
                      name='first_name'
                      defaultValue={owner.user_details.first_name}
                      onChange={handleOnChange}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label for='surname'>{t('common.surname')}</Label>
                    <Input
                      id='surname'
                      name='last_name'
                      onChange={handleOnChange}
                      defaultValue={owner.user_details.last_name}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label for='address'>
                      {t('formFields.ownerContactAddress')}
                    </Label>
                    <Input
                      id='address'
                      name='address'
                      onChange={handleOnChange}
                      defaultValue={owner.flat.address}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label for='entranceNumber'>
                      {t('formFields.entranceNumber')}
                    </Label>
                    <Input
                      id='entranceNumber'
                      name='entrance_number'
                      onChange={handleOnChange}
                      disabled={user.role_id === 3}
                      defaultValue={owner.flat.entrance_number}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label for='floor'>{t('formFields.floor')}</Label>
                    <Input
                      id='floor'
                      name='floor'
                      onChange={handleOnChange}
                      disabled={user.role_id === 3}
                      defaultValue={owner.flat.floor}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label for='apartmentNumber'>
                      {t('formFields.apartmentNumber')}
                    </Label>
                    <Input
                      id='apartmentNumber'
                      name='apartment_number'
                      onChange={handleOnChange}
                      disabled={user.role_id === 3}
                      defaultValue={owner.flat.apartment_number}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label for='phone'>
                      {t('formFields.ownersPhoneNumber')}
                    </Label>
                    <NumberFormat 
                      className="numberInput"
                      format="+### ### ### ###"
                      type='phone'
                      id='phone'
                      name='contact_no'
                      onChange={handleOnChange}
                      defaultValue={owner.user_details.contact_no}/>
                  </FormGroup>
                  <FormGroup>
                    <Label for='email'>
                      {t('formFields.ownersEmailAddress')}
                    </Label>
                    <Input
                      id='email'
                      name='email'
                      defaultValue={owner.email}
                      onChange={handleOnChange}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label for='note'>{t('formFields.noteToOwner')}</Label>
                    <Input
                      id='note'
                      name='notes'
                      type='textarea'
                      onChange={handleOnChange}
                      defaultValue={owner.notes}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Button
                      type='submit'
                      color='primary'
                      style={{ width: 120 }}
                      className='mr-2'>
                      {submitting ? (
                        <Loader
                          color='#ffffff'
                          innerClassName='d-flex align-items-center justify-content-center'
                          style={{ height: 18 }}
                          type='ball-beat'
                        />
                      ) : (
                        t('common.save')
                      )}
                    </Button>

                    <Button
                      type='button'
                      color='secondary'
                      onClick={() => {
                        history.goBack()
                      }}>
                      {t('common.cancel')}
                    </Button>
                  </FormGroup>
                </Form>
              </Col>
            </CardBody>
          </Card>
        </React.Fragment>
      )}
    </Fragment>
  )
}

export default DataForm
