import React, { useEffect, useState } from 'react'
import DataRanger from '../DateRanger'
import moment from 'moment'
import DatePicker from 'react-datepicker'
import { addDays } from 'date-fns'
import { Input } from 'reactstrap'
import { useTranslation } from 'react-i18next'
import { CUSTOM_PERIOD } from '../../../constants'

export default function DateRangerWithPicker({
  sortValue,
  sort,
  sortTable,
  period,
  placeholder,
  getChartsData,
  fetchData,
  startDateRange,
  endDateRange,
  setPeriod,
  setStartDateRange,
  setEndDateRange,
  setPlaceholder
}) {
  const { t, i18n } = useTranslation()
  const [isDateRange, setIsDateRange] = useState(false)
  const [isPickerOpen, setIsPickerOpen] = useState(false)
  const [dateRange, setDateRange] = useState('')

  useEffect(() => {
    if (!isPickerOpen) setPlaceholder(t('common.startDate'))
    if (startDateRange) setPlaceholder(t('common.endDate'))
    if (endDateRange) setPlaceholder(t('common.startDate'))
  }, [startDateRange, endDateRange, isPickerOpen, t])

  const handlePeriodChange = e => {
    if (e.target.value === CUSTOM_PERIOD) setIsDateRange(true)
    else setIsDateRange(false)
    setPeriod(e.target.value)
  }

  const onClickOutside = () => {
    if (!startDateRange || !endDateRange) {
      setStartDateRange()
      setEndDateRange()
      setDateRange()
    }
    setIsPickerOpen(false)
  }

  const onChangeDateRange = dates => {
    if (!dates) {
      setStartDateRange()
      setEndDateRange()
      setDateRange()
      return setIsPickerOpen(false)
    }
    const [start, end] = dates
    setStartDateRange(start)
    setEndDateRange(end)
    if (start && end) {
      setDateRange(
        moment(`${start}`).format('DD.MM.YYYY') +
          ' - ' +
          moment(`${end}`).format('DD.MM.YYYY')
      )
      getChartsData(start, end)
      if (sortValue && sort !== '')
        fetchData(true, sortTable[sortValue], start, end)
      else fetchData(false, false, start, end)
      setIsPickerOpen(false)
    }
  }

  const renderDayContents = day => {
    return (
      <span className='select-day' selectDayValue={placeholder}>
        {day}
      </span>
    )
  }

  return (
    <div className='input-datepicker-wrapper'>
      <DataRanger
        handlePeriodChange={handlePeriodChange}
        period={period}
        className='form-control mr-4'
      />
      {isDateRange && (
        <DatePicker
          renderDayContents={renderDayContents}
          dayClassName={() => 'select-date'}
          showYearDropdown
          locale={i18n.language}
          customInput={<Input />}
          selected={startDateRange}
          onChange={onChangeDateRange}
          selectsEnd
          startDate={startDateRange}
          maxDate={addDays(new Date(), -1)}
          endDate={endDateRange}
          selectsRange
          onInputClick={() => setIsPickerOpen(true)}
          open={isPickerOpen}
          value={dateRange || ''}
          onClickOutside={() => onClickOutside()}
          className='datepicker'
          placeholderText={placeholder}
          isClearable
          dateFormat='dd.MM.yyyy'
          filterDate={d => {
            return new Date() > d
          }}
        />
      )}
    </div>
  )
}
