export const breadCrumbsPaths = payload => ({
  Manager: {
    '/dashboard': [dashboardLink(payload)],
    '/svj/buildings/:buildingId': [
      dashboardLink(payload),
      showInstallationsLink(payload)
    ],
    '/list-of-devices': [listOfDevices(payload)],
    '/owners': [ownersLink(payload)],
    '/device-notifications': [deviceNotificationsLink(payload)],
    '/profile': [profileLink(payload)],
    '/svj/view/:svjVId/:flatId': [
      dashboardLink(payload),
      showInstallationsLink(payload),
      installationDetailsLink(payload)
    ],
    '/svj/create': [createANewSVJLink(payload)],
    '/svj/edit/:svjId': [dashboardLink(payload), editTheSVJLink(payload)],
    '/overview/flat-owner/detail/:flatOwnerId': [
      dashboardLink(payload),
      ownersLink(payload),
      installationDetailsLink(payload),
      flatOwnerLink(payload)
    ],
    '/owners/flat-owner/detail/:flatOwnerId': [
      ownersLink(payload),
      flatOwnerLink(payload)
    ]
  },
  Svj: {
    '/dashboard': [dashboardLink(payload)],
    '/svj/buildings/:buildingId': getChildLinks(payload, [
      'dashboardLink',
      'showInstallationsLink'
    ]),
    '/list-of-devices': [listOfDevices(payload)],
    '/device-notifications': [deviceNotificationsLink(payload)],
    '/profile': [profileLink(payload)],
    '/svj/view/:svjVId/:flat': getChildLinks(payload, [
      'dashboardLink',
      'showInstallationsLink',
      'installationDetailsLink'
    ]),
    '/svj/create': [createANewSVJLink(payload)],
    '/svj/edit/:svjId': getChildLinks(payload, [
      'dashboardLink',
      'editTheSVJLink'
    ]),
    '/owners/flat-owner/detail/:flatOwnerId': [
      dashboardLink(payload),
      flatOwnerLink(payload)
    ]
  },
  Owner: {
    '/dashboard': [dashboardLink(payload)],
    '/svj/buildings/:buildingId': [
      dashboardLink(payload),
      showInstallationsLink(payload)
    ],
    '/list-of-devices': [listOfDevices(payload)],
    '/owners': [ownersLink(payload)],
    '/device-notifications': [deviceNotificationsLink(payload)],
    '/profile': [profileLink(payload)],
    '/svj/view/:svjVId/:flatId': [
      dashboardLink(payload),
      showInstallationsLink(payload),
      installationDetailsLink(payload)
    ],
    '/svj/create': [createANewSVJLink(payload)],
    '/svj/edit/:svjId': [dashboardLink(payload), editTheSVJLink(payload)],
    '/flat-owner/detail/:flatOwnerId': [
      dashboardLink(payload),
      ownersLink(payload),
      flatOwnerLink(payload)
    ]
  }
})

const getChildLinks = (payload, types) => {
  let linksArray = []
  if (types.includes('flatOwnerLink')) {
    linksArray.push(flatOwnerLink(payload))
  }
  if (types.includes('dashboardLink')) {
    linksArray.push(dashboardLink(payload))
  }
  if (types.includes('showInstallationsLink')) {
    linksArray.push(showInstallationsLink(payload))
  }
  if (types.includes('installationDetailsLink')) {
    linksArray.push(installationDetailsLink(payload))
  }
  if (types.includes('editTheSVJLink')) {
    linksArray.push(editTheSVJLink(payload))
  }
  updateSessionParams(payload)
  return linksArray
}

const updateSessionParams = payload => {
  const object = {}
  Object.entries(payload.params).forEach(item => {
    object[item[0]] = item[1]
  })
  payload.setSessionParams({
    ...payload.sessionParams,
    ...object
  })
}

const flatOwnerLink = payload => {
  return {
    link: `/flat-owner/detail/${payload.sessionParams.flatOwnerId}`,
    text: payload.flatOwner
  }
}

const dashboardLink = payload => ({
  link: '/dashboard',
  text: payload.translation('navigation.overviewOfCustomers')
})

const showInstallationsLink = payload => {
  return {
    link: `/svj/buildings/${payload.sessionParams.buildingId}`,
    text: payload.translation('navigation.showInstallations')
  }
}

const ownersLink = payload => ({
  link: '/owners',
  text: payload.translation('navigation.owners')
})

const editTheSVJLink = payload => {
  return {
    link: `/svj/edit/${payload.sessionParams.svjId}`,
    text: payload.translation('navigation.editTheSVJ')
  }
}

const createANewSVJLink = payload => ({
  link: '/svj/create',
  text: payload.translation('navigation.createANewSVJ')
})

const installationDetailsLink = payload => {
  return {
    link: `/svj/view/${payload.sessionParams.svjVId}/${payload.sessionParams.flatId}`,
    text: payload.translation('navigation.installationDetails')
  }
}

const deviceNotificationsLink = payload => ({
  link: '/device-notifications',
  text: payload.translation('navigation.deviceNotifications')
})

const listOfDevices = payload => ({
  link: '/list-of-devices',
  text: payload.translation('navigation.listOfDevices')
})

const profileLink = payload => ({
  link: '/profile',
  text: payload.translation('navigation.profile')
})
