import React from 'react'

import Slider from 'react-slick'

import bg1 from '../../../assets/banners/obr_poz_1.jpg'
import bg2 from '../../../assets/banners/obr_poz_2.jpg'
import bg3 from '../../../assets/banners/obr_poz_3.jpg'

const SliderLeft = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    arrows: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: true,
    initialSlide: 0,
    autoplay: true,
    adaptiveHeight: true
  }

  return (
    <Slider {...settings}>
      <div className='h-100 d-flex justify-content-center align-items-center bg-premium-dark'>
        <div
          className='slide-img-bg'
          style={{
            backgroundImage: 'url(' + bg1 + ')',
            backgroundPosition: 'center'
          }}
        />
        <div className='slider-content'>
          <h3>Přehledně na jednom místě</h3>
          <p>
            Informace o vaší spotřebě vody a tepla, které vám pomáhají šetřit
            vaše peníze.
          </p>
        </div>
      </div>
      <div className='h-100 d-flex justify-content-center align-items-center bg-premium-dark'>
        <div
          className='slide-img-bg'
          style={{
            backgroundImage: 'url(' + bg2 + ')',
            backgroundPosition: 'center'
          }}
        />
        <div className='slider-content'>
          <h3>Aktuálně a srozumitelně</h3>
          <p>Pravidelné denní aktualizace stavů všech měřidel.</p>
        </div>
      </div>
      <div className='h-100 d-flex justify-content-center align-items-center bg-premium-dark'>
        <div
          className='slide-img-bg opacity-6'
          style={{
            backgroundImage: 'url(' + bg3 + ')',
            backgroundPosition: 'center'
          }}
        />
        <div className='slider-content'>
          <h3>Vždy k dispozici</h3>
          <p>
            Vaše data jsou dostupná z počítače, telefonu i tabletu kdykoliv a
            kdekoliv.
          </p>
        </div>
      </div>
    </Slider>
  )
}

export default SliderLeft
