import React, { useMemo, useState, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import {
  useTable,
  useSortBy,
  useFilters,
  useFlexLayout,
  useResizeColumns
} from 'react-table'
import { Input, Button } from 'reactstrap'
import { ColumnFilter } from './ColumnFilter'

export const DynamicTable = ({
  columns,
  data = [],
  filterable = false,
  loading,
  setSort,
  ...props
}) => {
  const { t } = useTranslation()
  const [disablePrevious, setDisablePrevious] = useState(false)
  const [disableNext, setDisableNext] = useState(false)
  const table = useRef()
  const [widthWithScroll, setWidthWithScroll] = useState()
  useEffect(() => {
    const setScreenSize = e => {
      if (document.querySelectorAll('.rt-th')) {
        let sumOfWidth = 0
        const childrens = document.querySelectorAll('.rt-th')

        for (let i = 0; i < columns.length; ++i) {
          sumOfWidth += childrens[i].offsetWidth
        }
        setWidthWithScroll(sumOfWidth)
      } else setWidthWithScroll(table.current.scrollWidth)
    }
    setScreenSize()
    window.addEventListener('resize', setScreenSize)
    return () => {
      window.removeEventListener('resize', setScreenSize)
    }
  }, [])

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow
  } = useTable(
    {
      columns: useMemo(() => columns, [t]),
      data: useMemo(() => data, [data]),
      defaultColumn: useMemo(() => {
        return {
          Filter: ColumnFilter
        }
      }, [])
    },
    useFilters,
    useSortBy,
    useFlexLayout,
    useResizeColumns
  )

  const onHandleSortData = val => {
    if (props.sortValues[val]) {
      switch (props.sort) {
        case 'asc':
          setSort('desc')
          break
        case 'desc':
          setSort('')
          break
        case '':
          setSort('asc')
          break
        default:
          setSort('asc')
          break
      }
      props.setSortValue(val)
    }
  }

  const onHandleSetPage = value => {
    if (value) props.setPage(value)
    else props.setPage(props.page)
  }

  const onHandleSetLimit = value => {
    props.setLimit(value)
  }

  useEffect(() => {
    if (props.page === props.lastPage) setDisableNext(true)
    else setDisableNext(false)

    if (props.page <= 1) setDisablePrevious(true)
    else setDisablePrevious(false)
  }, [props.page, props.limit, data])

  return (
    <>
      <div className='ReactTable'>
        <table
          className='rt-table'
          style={{ width: '100%' }}
          {...getTableProps()}>
          <thead ref={table} className='rt-thead -headerGroups'>
            {headerGroups.map(el => (
              <tr className='rt-tr' {...el.getHeaderGroupProps()}>
                {el.headers.map(header => {
                  return !header.canSort ? (
                    <th
                      className={
                        header.isSorted
                          ? header.isSortedDesc
                            ? 'rt-th -sort-desc'
                            : 'rt-th -sort-asc'
                          : 'rt-th'
                      }
                      {...header.getHeaderProps()}>
                      {header.render('Header')}
                    </th>
                  ) : null
                })}
              </tr>
            ))}
          </thead>

          <thead className='rt-thead -header'>
            {headerGroups.map(el => (
              <tr className='rt-tr' {...el.getHeaderGroupProps()}>
                {el.headers.map(header => {
                  return header.canSort ? (
                    <th
                      className={
                        props.sort && props.sortValue === header.id
                          ? props.sort === 'desc'
                            ? 'rt-th -sort-desc'
                            : 'rt-th -sort-asc'
                          : 'rt-th'
                      }
                      {...header.getHeaderProps()}>
                      <div
                        style={
                          props.sortValues[header.id]
                            ? { cursor: 'pointer' }
                            : {}
                        }
                        className='rt-resizable-header-content'
                        onClick={() => onHandleSortData(header.id)}>
                        {header.render('Header')}
                      </div>
                      <div
                        className='rt-resizer'
                        {...header.getResizerProps()}></div>
                    </th>
                  ) : null
                })}
              </tr>
            ))}
          </thead>

          <thead className='rt-thead -filters'>
            {headerGroups.map(el => (
              <tr className='rt-tr' {...el.getHeaderGroupProps()}>
                {el.headers.map(header =>
                  header.canSort ? (
                    filterable ? (
                      <th className='rt-th' {...header.getHeaderProps()}>
                        {header.canFilter ? header.render('Filter') : ''}
                      </th>
                    ) : (
                      <th className='rt-th' {...header.getHeaderProps()}></th>
                    )
                  ) : null
                )}
              </tr>
            ))}
          </thead>

          <div className={loading ? '-loading -active' : '-loading'}>
            <div className='loading-inner'>{t('common.loading')}</div>
          </div>
          <tbody className='rt-tbody' {...getTableBodyProps()}>
            {rows.map(row => {
              prepareRow(row)
              return (
                <tr className='rt-tr-group' {...row.getRowProps()}>
                  {row.cells.map(cell => {
                    return (
                      <td
                        className={`rt-td ${
                          cell?.value?.is?.length || cell?.value?.was?.length
                            ? 'rt-td-error'
                            : ''
                        }`}
                        {...cell.getCellProps()}>
                        {cell.render('Cell')}
                      </td>
                    )
                  })}
                </tr>
              )
            })}
          </tbody>

          <tfoot
            className='pagination-bottom'
            style={{ width: widthWithScroll || '100%' }}>
            <tr className='-pagination'>
              <td className='-previous'>
                <Button
                  className='-btn'
                  color='primary'
                  disabled={disablePrevious}
                  onClick={() => onHandleSetPage(props.page - 1)}>
                  {t('common.previous')}
                </Button>
              </td>
              <td className='-center'>
                <span className='-pageInfo'>
                  {t('common.page')}:
                  <Input
                    className='-pageJump'
                    type='number'
                    min={'1'}
                    max={props.lastPage}
                    value={props.page}
                    onChange={e => onHandleSetPage(Number(e.target.value))}
                  />
                  {t('common.of')} {props.lastPage}
                </span>
                <span className='select-wrap -pageSizeOptions'>
                  <select
                    value={props.limit}
                    onChange={e => onHandleSetLimit(Number(e.target.value))}>
                    {[5, 10, 20, 25, 50, 100].map(el => {
                      return (
                        <option key={el} value={el}>
                          {el} {t('common.rows')}
                        </option>
                      )
                    })}
                  </select>
                </span>
              </td>
              <td className='-next'>
                <Button
                  className='-btn'
                  color='primary'
                  disabled={disableNext}
                  onClick={() => onHandleSetPage(props.page + 1)}>
                  {t('common.next')}
                </Button>
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
    </>
  )
}
