import { Button, Card, CardBody, Col, Row } from 'reactstrap'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import axios from 'axios'
import React, { Fragment, useEffect, useState } from 'react'
import 'react-table-hoc-fixed-columns/lib/styles.css'
import PageTitleAlt from '../../../Layout/AppMain/PageTitleAlt'
import { DynamicTable } from '../../components/Tables/DynamicTable'
import { SearchFiled } from '../../components/SearchField'
import { ReactComponent as LogoContacts } from '../../../assets/images/LogoComponents/ios-contacts.svg'

const sortTable = {
  name: 'name',
  surname: 'surname',
  entrance: 'entrance',
  floor_flat: 'floor_flat',
  heatSpent: 'heat_spend',
  coldWaterSpent: 'cold_water_spend',
  hotWaterSpent: 'hot_water_spend'
}

const FlatOwners = () => {
  const period = 'lifetime'

  const { t, i18n } = useTranslation()
  const history = useHistory()
  const [owners, setOwners] = useState([])
  const [filterValue, setFilterValue] = useState('')
  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(10)
  const [loading, setLoading] = useState(false)
  const [searchQuery, setSearchQuery] = useState('')
  const [sort, setSort] = useState('asc')
  const [lastPage, setLastPage] = useState(1)
  const [sortValue, setSortValue] = useState('')
  const columns = [
    {
      Header: t('common.installationAddress'),
      accessor: 'address',
      Cell: ({ value }) => {
        return (
          <div style={{ textAlign: 'center', width: '100px' }}>{value}</div>
        )
      }
    },
    {
      Header: t('common.entrance'),
      accessor: 'entrance',
      width: 80,
      Cell: ({ value }) => {
        return (
          <div style={{ textAlign: 'center', width: '100px' }}>{value}</div>
        )
      }
    },
    {
      Header: t('common.flat'),
      accessor: 'floor_flat',
      width: 175,
      Cell: ({ value }) => {
        return (
          <div style={{ textAlign: 'center', width: '100px' }}>{value}</div>
        )
      }
    },
    {
      Header: t('common.name'),
      accessor: 'name',
      Cell: ({ value }) => {
        return (
          <div style={{ textAlign: 'center', width: '100px' }}>{value}</div>
        )
      }
    },
    {
      Header: t('common.surname'),
      accessor: 'surname',
      Cell: ({ value }) => {
        return (
          <div style={{ textAlign: 'center', width: '100px' }}>{value}</div>
        )
      }
    },
    {
      Header: t('common.contactPhone'),
      accessor: 'contact_no',
      Cell: ({ value }) => {
        return (
          <div style={{ textAlign: 'center', width: '100px' }}>{value}</div>
        )
      }
    },
    {
      Header: t('common.email'),
      accessor: 'email',
      Cell: ({ value }) => {
        return (
          <div style={{ textAlign: 'center', width: '100px' }}>{value}</div>
        )
      }
    },
    {
      Header: t('common.action'),
      accessor: 'action',
      width: 140
    }
  ]

  let apiHeaders = {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
    'Authorization': 'Bearer ' + localStorage.getItem('authToken'),
    'Content-Language': i18n.language === 'cz' ? 'cs' : 'en'
  }

  const fetchData = async (sortCol = false, col) => {
    setLoading(true)
    try {
      let response = null
      let sortQuery = ''

      if (sortCol) {
        sortQuery = `&order[0][column]=${col}&order[0][dir]=${sort}`
      }
      if (searchQuery === '') {
        response = await axios.get(
          `${
            process.env.REACT_APP_API_URL
          }/api/v1/flat-owners-with-readings-grid?period=${period}&length=${limit}&start=${page *
            limit}${sortQuery}&page=${page}`,
          {
            headers: apiHeaders
          }
        )
      } else {
        response = await axios.get(
          `${
            process.env.REACT_APP_API_URL
          }/api/v1/flat-owners-with-readings-grid?period=${period}&length=${limit}&start=${page *
            limit}${sortQuery}&search[value]=${searchQuery}&page=${page}`,
          {
            headers: apiHeaders
          }
        )
      }

      const periodValue = `${period}_totals`
      setLastPage(response.data.data.last_page)
      setOwners(
        response.data.data.data.map(item => {
          let coldWaterUsage = item[periodValue].length
            ? `${item[periodValue][0].cold_water_spend} ${item[periodValue][0].cold_water_spend_unit}`
            : 0
          let hotWaterUsage = item[periodValue].length
            ? `${item[periodValue][0].hot_water_spend} ${item[periodValue][0].hot_water_spend_unit}`
            : 0
          let heatUsage = item[periodValue].length
            ? `${item[periodValue][0].heat_spend} ${item[periodValue][0].heat_spend_unit}`
            : 0

          return {
            ...item,
            coldWaterSpent: coldWaterUsage,
            hotWaterSpent: hotWaterUsage,
            heatSpent: heatUsage,
            action: (
              <Button
                onClick={() =>
                  history.push(`/owners/flat-owner/detail/${item.id}`)
                }
                color='primary'
                className='w-100'>
                {t('common.ownerDetail')}
              </Button>
            )
          }
        })
      )
    } catch (error) {
      console.log(error)
    }
    setLoading(false)
  }

  useEffect(() => {
    if (sortValue && sort !== '') fetchData(true, sortTable[sortValue])
    else fetchData()
  }, [page, limit, period, t, sort, searchQuery])

  const rightBlock = (
    <Row className='mr-1'>
      <SearchFiled
        filterValue={filterValue}
        setFilterValue={setFilterValue}
        setSearchQuery={setSearchQuery}
      />
    </Row>
  )

  return (
    <Fragment>
      <PageTitleAlt
        rightBlock={rightBlock}
        heading={t('pageTitles.listOfFlatOwners')}
        icon={<LogoContacts className='SVGLogo' style={{ fill: '#343a40' }} />}
        hint={t('common.dataHint')}
      />
      <Row>
        <Col md='12'>
          <Card className='main-card mb-3'>
            <CardBody>
              <DynamicTable
                data={owners}
                columns={columns}
                loading={loading}
                page={page}
                limit={limit}
                setPage={setPage}
                setLimit={setLimit}
                lastPage={lastPage}
                setSortValue={setSortValue}
                sort={sort}
                setSort={setSort}
                sortValue={sortValue}
                sortValues={sortTable}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Fragment>
  )
}

export default FlatOwners
