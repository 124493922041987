import React, { Fragment, useState } from 'react'
import { useHistory } from 'react-router-dom'
import axios from 'axios'
import { useTranslation } from 'react-i18next'

import { Col, Row, Button, Form, FormGroup, Label, Input } from 'reactstrap'

import SliderLeft from '../../components/SliderLeft'
import { NotificationManager } from 'react-notifications'

const ForgotPassword = () => {
  const history = useHistory()
  const [email, setEmail] = useState(null)
  const { t, i18n } = useTranslation()

  const recover = e => {
    e.preventDefault()
    axios({
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'client-locale': i18n.language === 'cz' ? 'cs' : 'en'
      },
      url: `${process.env.REACT_APP_API_URL}/api/v1/password/create`,
      data: {
        email: email
      }
    })
      .then(function(response) {
        // handle success
        NotificationManager.success(t('common.successSentPasswordLink'))
        history.push('/login')
      })
      .catch(function(error) {
        // handle error
        NotificationManager.error(t('common.errorSentPasswordLink'))
        return true
      })
      .finally(() => {
        return true
      })
  }

  const handleClickSignIn = () => {
    history.push('/login')
  }

  return (
    <Fragment>
      <div className='h-100'>
        <Row className='h-100 no-gutters'>
          <Col lg='4' className='d-none d-lg-block'>
            <div className='slider-light'>
              <SliderLeft />
            </div>
          </Col>
          <Col
            lg='8'
            md='12'
            className='h-100 d-flex bg-white justify-content-center align-items-center'>
            <Col lg='6' md='8' sm='12' className='mx-auto app-login-box'>
              <div
                className='app-logo'
                style={{
                  backgroundImage: `url(${process.env.REACT_APP_LOGO})`,
                  width: `${process.env.REACT_APP_LOGO_WIDTH}px`,
                  height: `${process.env.REACT_APP_LOGO_HEIGHT}px`
                }}
              />
              <h4>
                <div>{t('authentication.forgotYourPassword')}</div>
                <span>{t('authentication.useTheFormToRecover')}</span>
              </h4>
              <div>
                <Form onSubmit={recover}>
                  <Row form>
                    <Col md={12}>
                      <FormGroup>
                        <Label for='exampleEmail'>
                          {t('formFields.email')}
                        </Label>
                        <Input
                          type='email'
                          name='email'
                          id='exampleEmail'
                          placeholder={t('formFields.emailPlaceholder')}
                          onBlur={e => setEmail(e.currentTarget.value)}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <div className='mt-4 d-flex align-items-center'>
                    <h6 className='mb-0'>
                      <Button color='secondary' onClick={handleClickSignIn}>
                        {t('authentication.signInToExistingAccount')}
                      </Button>
                    </h6>
                    <div className='ml-auto'>
                      <Button color='primary' size='lg' type='submit'>
                        {t('authentication.recoverPassword')}
                      </Button>
                    </div>
                  </div>
                </Form>
              </div>
            </Col>
          </Col>
        </Row>
      </div>
    </Fragment>
  )
}

export default ForgotPassword
