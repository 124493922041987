import './assets/base.scss'
import './i18n'
import './polyfills'

import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'

import Main from './app/pages'
import configureStore from './config/configureStore'
import { UserProvider, OwnerProvider, BreadcrumbProvider } from './contexts/index'
import * as serviceWorker from './serviceWorker'

import { registerLocale } from 'react-datepicker'
import cs from 'date-fns/locale/cs'
import en from 'date-fns/locale/en-GB'

registerLocale('cz', cs);
registerLocale('en', en);

const store = configureStore()
const rootElement = document.getElementById('root')

const renderApp = Component => {
  ReactDOM.render(
    <Provider store={store}>
      <BreadcrumbProvider>
      <UserProvider>
        <OwnerProvider>
        <BrowserRouter>
          <Component />
        </BrowserRouter>
        </OwnerProvider>
      </UserProvider>
      </BreadcrumbProvider>
    </Provider>,
    rootElement
  )
}

renderApp(Main)

if (module.hot) {
  module.hot.accept('./app/pages', () => {
    const NextApp = require('./app/pages').default
    renderApp(NextApp)
  })
}

serviceWorker.unregister()
