import { Button, Row } from 'reactstrap'
import { useTranslation } from 'react-i18next'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { ReactComponent as LogoBuffer } from '../../../assets/images/LogoComponents/logo-buffer.svg'
import ListOfDevices from './ListOfDevices'
import PageTitleAlt from '../../../Layout/AppMain/PageTitleAlt'
import { SearchFiled } from '../../components/SearchField'

const ListOfDevicesPage = ({ match }) => {
  const { t, i18n } = useTranslation()
  const history = useHistory()
  const [filterValue, setFilterValue] = useState('')
  const [allDevices, setAllDevices] = useState([])
  const [searchQuery, setSearchQuery] = useState('')
  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(10)
  const [totalPages, setTotalPages] = useState(null)
  const [loading, setLoading] = useState(false)
  const [sort, setSort] = useState('asc')
  const [lastPage, setLastPage] = useState(1)
  const [sortValue, setSortValue] = useState('')

  const sortTable = {
    device_serial_number: 'device_serial_number',
    name_customer: 'name_customer',
    error_translations: 'error_flag_decimal',
    installation_date: 'installation_date',
    next_change_of_the_device: 'next_change_of_the_device'
  }

  let apiSearchEndpoint = `${
    process.env.REACT_APP_API_URL
  }/api/v1/devices-grid?length=${limit}&start=${page *
    limit}&search[value]=${searchQuery}&page=${page}`

  let apiHeaders = {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
    'Authorization': 'Bearer ' + localStorage.getItem('authToken'),
    'Content-Language': i18n.language === 'cz' ? 'cs' : 'en'
  }
  let serialNumberSortingQuery = `&order[0][dir]=asc&order[0][column]=device_serial_number`

  const fetchData = async (sortCol = false, col) => {
    setLoading(true)
    let response = { data: { data: { data: [] } } }
    let sortQuery = ''
    try {
      if (sortCol) {
        sortQuery = `&order[1][column]=${col}&order[1][dir]=${sort}`
      }
      if (searchQuery === '') {
        response = await axios.get(
          `${
            process.env.REACT_APP_API_URL
          }/api/v1/devices-grid?length=${limit}&start=${page *
            limit}${sortQuery}&page=${page}${serialNumberSortingQuery}`,
          {
            headers: apiHeaders
          }
        )
      } else {
        response = await axios.get(`${apiSearchEndpoint}${sortQuery}`, {
          headers: apiHeaders
        })
      }
      setLastPage(response.data.data.last_page)
      setAllDevices(
        response.data.data.data.map(item => {
          return {
            ...item,
            action: (
              <Button
                onClick={() =>
                  history.push(`/owners/flat-owner/detail/${item.customer_id}`)
                }
                color='primary'
                className='w-100'>
                {t('common.ownerDetail')}
              </Button>
            )
          }
        })
      )
      if (searchQuery === '') {
        setTotalPages(Math.ceil(response.data.data.recordsTotal / limit))
      } else {
        setTotalPages(Math.ceil(response.data.data.recordsFiltered / limit))
      }
    } catch (err) {
      console.log(err)
    }
    setLoading(false)
  }

  useEffect(() => {
    if (sortValue && sort !== '') fetchData(true, sortTable[sortValue])
    else fetchData()
  }, [limit, page, t, sort, searchQuery])

  const handleSetLimit = limit => {
    setLimit(limit)
  }

  const handleSetPage = page => {
    setPage(page)
  }

  const rightBlock = (
    <Row>
      <SearchFiled
        filterValue={filterValue}
        setFilterValue={setFilterValue}
        setSearchQuery={setSearchQuery}
      />
    </Row>
  )

  return (
    <>
      <PageTitleAlt
        heading={t('pageTitles.listOfDevices')}
        icon={<LogoBuffer className='SVGLogo' style={{ fill: '#343a40' }} />}
        rightBlock={rightBlock}
      />
      <ListOfDevices
        data={allDevices}
        limit={limit}
        loading={loading}
        page={page}
        totalPages={totalPages}
        onSetLimit={handleSetLimit}
        onSetPage={handleSetPage}
        fetchData={fetchData}
        lastPage={lastPage}
        setLastPage={setLastPage}
        setSortValue={setSortValue}
        sortValue={sortValue}
        sort={sort}
        setSort={setSort}
      />
    </>
  )
}

export default ListOfDevicesPage
