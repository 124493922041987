import { NotificationContainer, NotificationManager } from 'react-notifications'
import { Route, Redirect, Switch, useHistory } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import axios from 'axios'
import React, { Fragment, useContext, useEffect, useLayoutEffect } from 'react'
import 'react-notifications/lib/notifications.css'

/* Layout */
import AppHeader from '../../Layout/AppHeader'
import AppSidebar from '../../Layout/AppSidebar'
import AppFooter from '../../Layout/AppFooter'

/* Dashboard */
import FlatOwnerDashboard from './Dashboard'
import SVJDashboard from './Dashboard2'
import InstallerDashboard from './Dashboard3'

/* Profile */
import FlatOwnerProfile from './FlatOwnerProfile'
import ProfileSettingsSVJ from './ProfileSettingsSVJ'
import CompanyDetails from './ProfileSettingsCompany'

/* Authentication */
import Login from './Login'
import ForgotPassword from './ForgotPassword'
import RecoveryPassword from './RecoveryPassword'

/* Error */
// import Error404 from './Errors/Error404'

/* FlatOwner */
import FlatOwnerDetail from './FlatOwnerDetail'
import FlatOwnersList from './FlatOwners'

/* List of Devices */
import ListOfDevicesPage from './ListOfDevices'
import DeviceNotifications from './DeviceNotifications'

/* SVJ */
import ViewSVJ from './HouseOwnersAssociationDetailsView'
import CreateSVJ from './SVJ/CreateSVJ'
import EditSVJ from './HouseOwnersAssociationDetail'
import ViewBuildings from './HouseOwnerAssociationBuildingsDetail'

/* User Context */
import UserContext from '../../contexts/authContext'
import { useTranslation } from 'react-i18next'

const AppMain = () => {
  const { user, setUser } = useContext(UserContext)
  const { i18n } = useTranslation()
  axios.defaults.headers.common['Content-Language'] =
    i18n.language === 'cz' ? 'cs' : 'en'
  const history = useHistory()

  useLayoutEffect(() => {
    axios.interceptors.response.use(
      res => res,
      error => {
        if (error.response.status === 401) {
          localStorage.clear()
          if (error?.config?.url?.includes('/api/v1/password/reset')) {
            NotificationManager.error(
              i18n.language === 'cz'
                ? 'Autentizační token je nevalidní. Obnovte heslo znovu pomocí odkazu Obnovit heslo.'
                : error?.response?.data?.errors
            )
            return Promise.reject(error)
          }
          history.push('/login')
          return null
        }
        if (error?.config?.url?.includes('/password/create'))
          return Promise.reject()
        if (error.response.data.errors !== undefined) {
          if (Array.isArray(error.response.data.errors)) {
            NotificationManager.error(error.response.data.errors)
          } else {
            NotificationManager.error(
              Object.values(error.response.data.errors)[0]
            )
          }
        } else if (
          error.response.data.message !== undefined &&
          error.response.data.message !== 'Unauthenticated.'
        ) {
          NotificationManager.error(error.response.data.message)
        } else if (
          error.response.statusText !== undefined &&
          error.response.statusText !== 'Unauthorized'
        ) {
          NotificationManager.error(error.response.statusText)
        }

        return Promise.reject(error)
      }
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (localStorage.getItem('authToken')) {
      if (!user || !user.role_id) {
        axios({
          method: 'get',
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('authToken')
          },
          url: `${process.env.REACT_APP_API_URL}/api/v1/auth/user`
        })
          .then(function(response) {
            setUser(response.data.data.user)
          })
          .catch(function(error) {
            return false
          })
          .finally(() => {
            return true
          })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setUser])

  const ProtectedRoute = ({
    componentOwner: ComponentOwner,
    componentSvj: ComponentSvj,
    componentManager: ComponentManager,
    ...rest
  }) => {
    const roleId = user.role_id
    if (
      (roleId === 2 && ComponentOwner === 'Login') ||
      (roleId === 3 && ComponentSvj === 'Login') ||
      (roleId === 4 && ComponentManager === 'Login')
    ) {
      history.push('/dashboard')
    }
    return (
      <Route
        render={props => (
          <div>
            {roleId === 2 && <ComponentOwner {...props} />}
            {roleId === 3 && <ComponentSvj {...props} />}
            {roleId === 4 && <ComponentManager {...props} />}
          </div>
        )}
        {...rest}
      />
    )
  }

  const showInternalLayout = () => {
    if (localStorage.getItem('roleId') && localStorage.getItem('authToken')) {
      return true
    }
    return false
  }

  return (
    <Fragment>
      <NotificationContainer />
      <ToastContainer />

      {!showInternalLayout() && (
        <Switch>
          <Route exact path='/login' component={Login} />
          <Route exact path='/forgot-password' component={ForgotPassword} />
          <Route exact path='/recover-password' component={RecoveryPassword} />
          <Route path='' render={() => <Redirect to='/login' />} />
        </Switch>
      )}

      {showInternalLayout() && (
        <React.Fragment>
          <AppHeader />
          <div className='app-main'>
            <AppSidebar />
            <div className='app-main__outer'>
              <div className='app-main__inner'>
                <Switch>
                  <ProtectedRoute
                    exact
                    path='/dashboard'
                    componentOwner={FlatOwnerDashboard}
                    componentSvj={SVJDashboard}
                    componentManager={InstallerDashboard}
                  />

                  <ProtectedRoute
                    path='/profile'
                    componentOwner={FlatOwnerProfile}
                    componentSvj={ProfileSettingsSVJ}
                    componentManager={CompanyDetails}
                  />

                  <ProtectedRoute
                    path='/owners/flat-owner/detail/:flatOwnerId'
                    componentOwner={'Login'}
                    componentSvj={FlatOwnerDetail}
                    componentManager={FlatOwnerDetail}
                  />

                  <ProtectedRoute
                    path='/overview/flat-owner/detail/:flatOwnerId'
                    componentOwner={'Login'}
                    componentSvj={FlatOwnerDetail}
                    componentManager={FlatOwnerDetail}
                  />

                  <ProtectedRoute
                    path='/list-of-devices'
                    componentOwner={'Login'}
                    componentSvj={'Login'}
                    componentManager={ListOfDevicesPage}
                  />

                  <ProtectedRoute
                    path='/owners'
                    componentOwner={'Login'}
                    componentSvj={'Login'}
                    componentManager={FlatOwnersList}
                  />

                  <ProtectedRoute
                    path='/device-notifications'
                    componentOwner={'Login'}
                    componentSvj={'Login'}
                    componentManager={DeviceNotifications}
                  />

                  <ProtectedRoute
                    path='/svj/create'
                    componentOwner={'Login'}
                    componentSvj={'Login'}
                    componentManager={CreateSVJ}
                  />

                  <ProtectedRoute
                    path='/svj/view/:svjVId/:flatId'
                    componentOwner={'Login'}
                    componentSvj={'Login'}
                    componentManager={ViewSVJ}
                  />

                  <ProtectedRoute
                    path='/svj/buildings/:buildingId'
                    componentOwner={'Login'}
                    componentSvj={'Login'}
                    componentManager={ViewBuildings}
                  />

                  <ProtectedRoute
                    path='/svj/edit/:svjId'
                    componentOwner={'Login'}
                    componentSvj={'Login'}
                    componentManager={EditSVJ}
                  />

                  <Route
                    exact
                    path='/'
                    render={() => <Redirect to='/dashboard' />}
                  />

                  <Route path='' render={() => <Redirect to='/dashboard' />} />
                </Switch>
              </div>
            </div>
          </div>
        </React.Fragment>
      )}
    </Fragment>
  )
}

export default AppMain
