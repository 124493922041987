import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import {
  useTable,
  useSortBy,
  useFilters,
  usePagination,
  useFlexLayout,
  useResizeColumns
} from 'react-table'
import { Input, Button } from 'reactstrap'
import { ColumnFilter } from './ColumnFilter'

export const StaticTable = ({
  columns,
  data = [],
  filterable = false,
  loading
}) => {
  const { t } = useTranslation()

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    prepareRow,
    canNextPage,
    canPreviousPage,
    gotoPage,
    pageCount,
    state,
    setPageSize
  } = useTable(
    {
      columns: useMemo(() => columns, [t]),
      data: useMemo(() => data, [data]),
      defaultColumn: useMemo(() => {
        return {
          Filter: ColumnFilter
        }
      }, [])
    },
    useFilters,
    useSortBy,
    usePagination,
    useFlexLayout,
    useResizeColumns
  )

  const { pageIndex, pageSize } = state

  const onHandleSetPage = value => {
    gotoPage(value)
  }

  const onHandleSetLimit = value => {
    setPageSize(value)
  }

  return (
    <>
      <div className='ReactTable'>
        <table
          className='rt-table'
          style={{ width: '100%' }}
          {...getTableProps()}>
          <thead className='rt-thead -headerGroups'>
            {headerGroups.map(el => (
              <tr className='rt-tr' {...el.getHeaderGroupProps()}>
                {el.headers.map(header => {
                  return !header.canSort ? (
                    <th
                      className={
                        header.isSorted
                          ? header.isSortedDesc
                            ? 'rt-th -sort-desc'
                            : 'rt-th -sort-asc'
                          : 'rt-th'
                      }
                      {...header.getHeaderProps(
                        header.getSortByToggleProps({
                          title: `${t('common.sortBy')}`
                        })
                      )}>
                      {header.render('Header')}
                    </th>
                  ) : null
                })}
              </tr>
            ))}
          </thead>

          <thead className='rt-thead -header'>
            {headerGroups.map(el => (
              <tr className='rt-tr' {...el.getHeaderGroupProps()}>
                {el.headers.map(header => {
                  return header.canSort ? (
                    <th
                      className={
                        header.isSorted
                          ? header.isSortedDesc
                            ? 'rt-th -sort-desc'
                            : 'rt-th -sort-asc'
                          : 'rt-th'
                      }
                      {...header.getHeaderProps(
                        header.getSortByToggleProps({
                          title: `${t('common.sortBy')}`
                        })
                      )}>
                      <div className='rt-resizable-header-content'>
                        {header.render('Header')}
                      </div>
                      <div
                        className='rt-resizer'
                        {...header.getResizerProps()}></div>
                    </th>
                  ) : null
                })}
              </tr>
            ))}
          </thead>
          <thead className='rt-thead -filters'>
            {headerGroups.map(el => (
              <tr className='rt-tr' {...el.getHeaderGroupProps()}>
                {el.headers.map(header =>
                  header.canSort ? (
                    filterable ? (
                      <th className='rt-th' {...header.getHeaderProps()}>
                        {header.canFilter ? header.render('Filter') : ''}
                      </th>
                    ) : (
                      <th className='rt-th' {...header.getHeaderProps()}></th>
                    )
                  ) : null
                )}
              </tr>
            ))}
          </thead>
          {loading ? (
            <div className='-loading -active'>
              <div className='loading-inner'>{t('common.loading')}</div>
            </div>
          ) : (
            <tbody className='rt-tbody' {...getTableBodyProps()}>
              {page.map(row => {
                prepareRow(row)
                return (
                  <tr className='rt-tr-group' {...row.getRowProps()}>
                    {row.cells.map(cell => {
                      return (
                        <td className='rt-td' {...cell.getCellProps()}>
                          {cell.render('Cell')}
                        </td>
                      )
                    })}
                  </tr>
                )
              })}
            </tbody>
          )}
          <tfoot className='pagination-bottom'>
            <tr className='-pagination'>
              <div className='-previous'>
                <Button
                  className='-btn'
                  color='primary'
                  disabled={!canPreviousPage}
                  onClick={() => previousPage()}>
                  {t('common.previous')}
                </Button>
              </div>
              <div className='-center'>
                <span className='-pageInfo'>
                  {t('common.page')}:
                  <Input
                    className='-pageJump'
                    type='number'
                    min={'1'}
                    max={pageCount}
                    value={pageIndex + 1}
                    onChange={e =>
                      onHandleSetPage(Number(e.target.value - 1))
                    }></Input>
                  {t('common.of')} {pageCount}
                </span>
                <span className='select-wrap -pageSizeOptions'>
                  <select
                    value={pageSize}
                    onChange={e => onHandleSetLimit(Number(e.target.value))}>
                    {[5, 10, 20, 25, 50, 100].map(el => {
                      return (
                        <option key={el} value={el}>
                          {el} {t('common.rows')}
                        </option>
                      )
                    })}
                  </select>
                </span>
              </div>
              <div className='-next'>
                <Button
                  className='-btn'
                  color='primary'
                  disabled={!canNextPage}
                  onClick={() => nextPage()}>
                  {t('common.next')}
                </Button>
              </div>
            </tr>
          </tfoot>
        </table>
      </div>
    </>
  )
}
