import { CardHeader, Card, CardBody } from 'reactstrap'
import {
  XAxis,
  Legend,
  ResponsiveContainer,
  BarChart,
  Bar,
  Tooltip,
  YAxis
} from 'recharts'
import React from 'react'

const BarChartComponent = ({ data, title, color1, color2, maxValue }) => {
  const currentYear = new Date().getFullYear();
  const previousYear = currentYear - 1;

  const lowerCaseTitle = title.toLowerCase();
  const formattedTitle = title.charAt(0).toUpperCase() + lowerCaseTitle.slice(1);

  return (
    <Card className='mb-3'>
      <CardHeader style={{ textTransform: "none" }} className='rm-border pb-0 responsive-center'>
        <div>
          <h5 className='menu-header-title'>{formattedTitle}</h5>
        </div>
      </CardHeader>
      <CardBody>
        <ResponsiveContainer height={244}>
          <BarChart data={data} margin={0}>
            <XAxis dataKey='name' />
            <YAxis domain={[0, maxValue]} />
            <Tooltip />
            <Legend />
            <Bar
              barGap='12'
              dataKey={currentYear.toString()}
              fill={color2}
            />
            <Bar
              barGap='12'
              dataKey={previousYear.toString()}
              fill={color1}
            />
          </BarChart>
        </ResponsiveContainer>
      </CardBody>
    </Card>
  )
}

export default BarChartComponent
