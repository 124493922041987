import { NotificationManager } from 'react-notifications'
import { useHistory } from 'react-router-dom'
import axios from 'axios'
import Loader from 'react-loaders'
import React, { useState } from 'react'

import {
  Button,
  Card,
  CardBody,
  Form,
  FormGroup,
  Input,
  Label
} from 'reactstrap'
import { useTranslation } from 'react-i18next'

const CreateSVJForm = () => {
  let { t, i18n } = useTranslation()
  let history = useHistory()
  const [SVJ, setSVJ] = useState({})
  const [submitting, setSubmitting] = useState(false)

  /* Add key:value pair to the SVJ object on input change */
  const onChange = e => {
    let urlPattern = /^((http|https|ftp):\/\/)/
    let url = null

    if (e.target.name === 'website' && !urlPattern.test(e.target.value)) {
      url = 'https://' + e.target.value
      setSVJ({ ...SVJ, [e.target.name]: url })
    } else {
      setSVJ({ ...SVJ, [e.target.name]: e.target.value })
    }
  }

  /* Handle Form Submission */
  const onSubmit = e => {
    e.preventDefault()
    setSubmitting(true)
    axios
      .post(`${process.env.REACT_APP_API_URL}/api/v1/svj-committees`, SVJ, {
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': 'Bearer ' + localStorage.getItem('authToken'),
          'Content-Language': i18n.language === 'cz' ? 'cs' : 'en'
        }
      })
      .then(response => {
        NotificationManager.success(t('common.success'))
        history.goBack()
        setSubmitting(false)
      })
      .catch(error => {
        setSubmitting(false)
      })
  }

  /* Handle on cancel button click */
  const onCancel = e => {
    e.preventDefault()
    history.goBack()
  }

  return (
    <Card className='main-card'>
      <CardBody>
        <Form onSubmit={onSubmit}>
          <FormGroup>
            <Label for='name'>
              {t('formFields.nameOfHouseOwnerAssociationSVJ')}
            </Label>
            <Input
              id='name'
              name='name'
              onChange={onChange}
              defaultValue={''}
            />
          </FormGroup>
          <FormGroup>
            <Label for='address'>
              {t('formFields.addressOfHouseOwnerAssociationSVJ')}
            </Label>
            <Input
              id='note'
              name='address'
              onChange={onChange}
              defaultValue={''}
            />
          </FormGroup>
          <FormGroup>
            <Label for='nameOfContactPerson'>
              {t('formFields.nameOfTheContactPerson')}
            </Label>
            <Input
              id='nameOfContactPerson'
              name='contact_first_name'
              onChange={onChange}
              defaultValue={''}
            />
          </FormGroup>
          <FormGroup>
            <Label for='surnameOfContactPerson'>
              {t('formFields.surnameOfTheContactPerson')}
            </Label>
            <Input
              id='surnameOfContactPerson'
              name='contact_last_name'
              onChange={onChange}
              defaultValue={''}
            />
          </FormGroup>
          <FormGroup>
            <Label for='phone'>{t('formFields.contactPhoneNumber')}</Label>
            <Input
              id='phone'
              onChange={onChange}
              name='contact_phone'
              defaultValue={''}
            />
          </FormGroup>
          <FormGroup>
            <Label for='email'>{t('formFields.contactEmailAddress')}</Label>
            <Input
              id='email'
              onChange={onChange}
              name='contact_email'
              defaultValue={''}
            />
          </FormGroup>
          <FormGroup>
            <Label for='website'>{t('formFields.svjWebsite')}</Label>
            <Input
              id='website'
              onChange={onChange}
              name='website'
              defaultValue={''}
            />
          </FormGroup>
          <FormGroup>
            <Button
              type='submit'
              color='primary'
              style={{ width: 150 }}
              disabled={submitting}
              size='lg'>
              {submitting ? (
                <Loader
                  color='#ffffff'
                  innerClassName='d-flex align-items-center justify-content-center'
                  style={{ height: 18 }}
                  type='ball-beat'
                />
              ) : (
                t('common.save')
              )}
            </Button>

            <Button
              type='button'
              color='secondary'
              className='ml-2'
              size='lg'
              onClick={onCancel}>
              {t('common.cancel')}
            </Button>
          </FormGroup>
        </Form>
      </CardBody>
    </Card>
  )
}

export default CreateSVJForm
