import { NotificationManager } from 'react-notifications'
import Doc from '../../../assets/files/guide_en.pdf'
import { useHistory, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import Select from 'react-select'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import {
  faEye,
  faTrash,
  faUserEdit,
  faQuestionCircle,
  faCloudDownloadAlt
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ReactComponent as LogoCloudUpload } from '../../../assets/images/LogoComponents/ios-cloud-upload.svg'
import { ReactComponent as LogoCreate } from '../../../assets/images/LogoComponents/ios-create.svg'
import {
  Alert,
  Button,
  Card,
  CardBody,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner
} from 'reactstrap'
import Loader from 'react-loaders'
import PageTitleAlt from '../../../Layout/AppMain/PageTitleAlt'
import { StaticTable } from '../../components/Tables/StaticTable'
import { Tooltip } from 'reactstrap'
import HelpMenu from 'assets/components/help-menu'
import { PopoverBody, UncontrolledPopover } from 'reactstrap'

const CustomTooltip = ({ id, text }) => {
  const [tooltipOpen, setTooltipOpen] = useState(false)

  const toggle = () => setTooltipOpen(!tooltipOpen)

  return (
    <Tooltip placement='top' target={id} isOpen={tooltipOpen} toggle={toggle}>
      {text}
    </Tooltip>
  )
}

const HouseOwnerAssociationBuildingsDetailPage = () => {
  const history = useHistory()
  const { buildingId } = useParams()
  const [SVJ, SetSVJ] = useState({})
  const [formError, setFormError] = useState({ address: false, code: false })
  const [uploadModal, setUploadModal] = useState(false)
  const [uploadingCsv, setUploadingCsv] = useState(false)
  const [optionsInstallation, setOptionsInstallation] = useState([])
  const [selectedOptionInstallation, setSelectedOptionInstallation] = useState(
    null
  )
  const [csv, setCsv] = useState(null)
  const [allCustomersInfo, setAllCustomersInfo] = useState([])
  const [data, setData] = useState([])
  const [installationId, setInstallationId] = useState('')
  const [selectedBuildingSVJId, setSelectedBuildingSVJId] = useState(null)
  const [address, setAddress] = useState('')
  const [code, setCode] = useState('')
  const [apartaments, setApartaments] = useState('')
  const [editAddress, setEditAddress] = useState('')
  const [editCode, setEditCode] = useState('')
  const [editApartaments, setEditApartaments] = useState('')
  const [modalView, setModalView] = useState(false)
  const [modalEdit, setModalEdit] = useState(false)
  const [modalDelete, setModalDelete] = useState(false)
  const { t, i18n } = useTranslation()
  const [loading, setLoading] = useState(false)

  const toggleDeleteModal = (id, address) => {
    setInstallationId(id)
    setEditAddress(address)
    setModalDelete(!modalDelete)
  }

  const toggleUploadModal = async () => {
    setSelectedOptionInstallation(null)
    setUploadModal(!uploadModal)
  }
  // const handleUploadCSV = async () => {
  //   try {
  //     setUploadingCsv(true)
  //     const body = new FormData()
  //     body.append('device_list', csv[0])
  //     body.append('building_id', selectedOptionInstallation)
  //     body.append('svj_id', selectedOptionSVJ)
  //     await axios.post(
  //       `${process.env.REACT_APP_API_URL
  //       }/api/v1/upload-device-installation-csv`,
  //       body,
  //       {
  //         headers: {
  //           'Content-Type': 'application/json',
  //           'Accept': 'application/json',
  //           'Authorization': 'Bearer ' + localStorage.getItem('authToken')
  //         }
  //       }
  //     )
  //     toggleUploadModal()
  //     NotificationManager.success(t('common.success'))
  //   } catch (error) {
  //     NotificationManager.error(t('common.error'))
  //   }
  //   setUploadingCsv(false)
  // }

  const handleDownloadCSV = async id => {
    try {
      const locale = i18n.language === 'cz' ? 'cs' : 'en'
      const data = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/v1/generate-flat-owners-for-building/${id}`,
        { headers: { 'client-locale': locale } }
      )
      window.open(`${process.env.REACT_APP_API_URL}/${data.data.file_path}`)
    } catch (error) {
      console.log(error)
    }
  }

  const handleChangeUploadInstallation = selectedOption => {
    setSelectedOptionInstallation(selectedOption)
  }

  const handleChange = event => {
    setCsv(event.target.files)
  }

  const handleUploadCSV = async () => {
    try {
      setUploadingCsv(true)
      const body = new FormData()
      body.append('device_list', csv[0])
      body.append('building_id', selectedOptionInstallation.value)
      body.append('svj_id', SVJ.id)
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/v1/upload-device-installation-csv`,
        body,
        {
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('authToken')
          }
        }
      )
      toggleUploadModal()
      NotificationManager.success(t('common.success'))
    } catch (error) {
      NotificationManager.error(t('common.error'))
    }
    setUploadingCsv(false)
  }

  const toggleEditModal = installation => {
    setModalEdit(!modalEdit)
    setInstallationId(installation.id)
    setEditCode(installation.code)
    setEditAddress(installation.address)
    setEditApartaments(installation.num_apartments)
    setSelectedBuildingSVJId(installation.svj_committee_id)
  }

  const columns = [
    {
      Header: t('common.addressOfTheBuilding'),
      accessor: 'address',
      Cell: ({ value }) => {
        return <div style={{ textAlign: 'center', width: '100%' }}>{value}</div>
      }
    },
    // {
    //   Header: t('common.uniqueCode'),
    //   accessor: 'code',
    //   Cell: ({ value }) => {
    //     return <div style={{ textAlign: 'center', width: '100%' }}>{value}</div>
    //   },
    //   width: 200
    // },
    {
      Header: t('common.numberOfApartments'),
      accessor: 'num_apartments',
      Cell: ({ value }) => {
        return <div style={{ textAlign: 'center', width: '100%' }}>{value}</div>
      },
      width: 200
    },
    {
      filterable: false,
      accessor: 'show_dashboard',
      maxWidth: 180,
      disableFilters: true,
      Cell: value => (
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: 'repeat(auto-fit, minmax(30px, 1fr))',
            columnGap: 8,
            rowGap: 8,
            width: '100%'
          }}>
          <Button
            id={`show-all-falsts${value.row.original.id}-1`}
            outline
            color='secondary'
            onClick={() =>
              history.push(
                `/svj/view/${value.row.original.svj_committee_id}/${value.row.original.id}`
              )
            }>
            <FontAwesomeIcon icon={faEye} />
          </Button>

          <CustomTooltip
            id={`show-all-falsts${value.row.original.id}-1`}
            text={t('common.showSvg')}
          />

          <Button
            id={`show-all-falsts${value.row.original.id}-2`}
            outline
            color='secondary'
            onClick={e => toggleEditModal(value.row.original)}>
            <FontAwesomeIcon icon={faUserEdit} />
          </Button>
          <CustomTooltip
            id={`show-all-falsts${value.row.original.id}-2`}
            text={t('common.editInstallation')}
          />
          <Button
            id={`show-all-falsts${value.row.original.id}-3`}
            outline
            color='secondary'
            onClick={() => handleDownloadCSV(value.row.original.id)}>
            <FontAwesomeIcon icon={faCloudDownloadAlt} />
          </Button>
          <CustomTooltip
            id={`show-all-falsts${value.row.original.id}-3`}
            text={t('common.downloadOwnerList')}
          />
          <Button
            id={`show-all-falsts${value.row.original.id}-4`}
            outline
            color='danger'
            onClick={() =>
              toggleDeleteModal(
                value.row.original.id,
                value.row.original.address
              )
            }>
            <FontAwesomeIcon icon={faTrash} />
          </Button>
          <CustomTooltip
            id={`show-all-falsts${value.row.original.id}-4`}
            text={t('common.deleteInstallation')}
          />
        </div>
      )
    }
  ]

  const createInstallation = async () => {
    // if (!code || !address)
    if (!address)
      return setFormError({
        // code: !code ? true : false,
        address: !address ? true : false
      })
    setLoading(true)
    try {
      await axios.post(
        `${process.env.REACT_APP_API_URL}/api/v1/buildings`,
        {
          // code,
          address,
          // num_apartments: apartaments,
          svj_committee_id: SVJ.id
        },
        {
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'svj-id': SVJ.id,
            'Authorization': 'Bearer ' + localStorage.getItem('authToken'),
            'Content-Language': i18n.language === 'cz' ? 'cs' : 'en'
          }
        }
      )
      NotificationManager.success(t('common.success'))
      setCode('')
      setApartaments('')
      setAddress('')
      fetchData()
      setModalView(false)
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
  }

  const editInstallation = async () => {
    // if (!editCode || !editAddress)
    if (!editAddress)
      return setFormError({
        // code: !editCode ? true : false,
        address: !editAddress ? true : false
      })

    setLoading(true)
    try {
      await axios.patch(
        `${process.env.REACT_APP_API_URL}/api/v1/buildings/${installationId}`,
        {
          // code: editCode,
          address: editAddress,
          // num_apartments: editApartaments,
          svj_committee_id: selectedBuildingSVJId
        },
        {
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'svj-id': SVJ.id,
            'Authorization': 'Bearer ' + localStorage.getItem('authToken'),
            'Content-Language': i18n.language === 'cz' ? 'cs' : 'en'
          }
        }
      )
      NotificationManager.success(t('common.success'))
      fetchData()
      setEditAddress('')
      setEditApartaments('')
      setEditCode('')
      setModalEdit(false)
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }

  const deleteInstallation = async () => {
    setLoading(true)
    try {
      await axios.delete(
        `${process.env.REACT_APP_API_URL}/api/v1/buildings/${installationId}`,
        {
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'svj-id': SVJ.id,
            'Authorization': 'Bearer ' + localStorage.getItem('authToken'),
            'Content-Language': i18n.language === 'cz' ? 'cs' : 'en'
          }
        }
      )
      NotificationManager.success(t('common.success'))
      fetchData()
      setLoading(false)
      setModalDelete(false)
    } catch (error) {
      setLoading(false)
    }
  }

  const fetchSVJ = async () => {
    setLoading(true)
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/v1/svj-committees/${buildingId}`,
        {
          headers: {
            'Content-Language': i18n.language === 'cz' ? 'cs' : 'en',
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('authToken')
          }
        }
      )
      SetSVJ(response.data.data)
      setLoading(false)
    } catch (error) {
      setLoading(false)
      NotificationManager.error(error.response.data.message)
    }
  }

  const fetchData = async () => {
    setLoading(true)
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/v1/get-all-buildings-by-svj/${buildingId}`,
        {
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'svj-id': buildingId,
            'Authorization': 'Bearer ' + localStorage.getItem('authToken')
          }
        }
      )
      setData(response.data.data)
      setOptionsInstallation(
        response.data.data.map(item => {
          return {
            value: item.id,
            label: item.address
          }
        })
      )

      setLoading(false)
    } catch (err) {
      setLoading(false)
      NotificationManager.error(err.response.data.message)
    }
  }

  useEffect(() => {
    fetchData()
    fetchSVJ()
  }, [])
  const rightBlock = (
    <>
      <Col className=' d-flex align-items-end'>
        <Button
          onClick={() => {
            setModalView(!modalView)
          }}
          type='button'
          color='primary'
          className={'ml-auto p-2'}>
          {t('common.createAnInstallation')}
        </Button>
        <Col className='d-flex ali align-items-center'>
          <Button
            color='primary'
            className='ml-1 mt-0 d-flex align-items-center'
            onClick={() => toggleUploadModal()}>
            <LogoCloudUpload className='mr-1 SVGLogo' />
            {t('common.upload')}
          </Button>
          <HelpMenu />
        </Col>
      </Col>
    </>
  )

  return (
    <>
      {loading && (
        <div className='loader-wrapper w-100 d-flex justify-content-center align-items-center'>
          <Loader type='ball-beat' />
        </div>
      )}
      {!loading && (
        <>
          <PageTitleAlt
            heading={`${t('pageTitles.houseOwnersAssociationDetails')} - ${
              SVJ.name
            }`}
            icon={
              <LogoCreate className='SVGLogo' style={{ fill: '#343a40' }} />
            }
            rightBlock={rightBlock}
          />

          <Card>
            <CardBody>
              <StaticTable
                data={data}
                columns={columns}
                loading={loading}
                filterable
              />
            </CardBody>
          </Card>

          {/* <Row>
                        <Col className='mt-3 d-flex align-items-end'>
                            <Button
                                onClick={() => {
                                    history.push(`/svj/view/${id}`)
                                }}
                                type='button'
                                color='primary'
                                className={'ml-auto'}>
                                {t("common.showSvg")}
                            </Button>
                        </Col>
                    </Row> */}
        </>
      )}
      <Modal isOpen={modalView} toggle={() => setModalView(!modalView)}>
        <ModalHeader toggle={() => setModalView(!modalView)}>
          <div className='d-flex'>
            {t('common.createAnInstallation')} <HelpMenu />
          </div>
        </ModalHeader>
        <ModalBody>
          {formError.address && (
            <Alert color='danger'> {t('common.addressError')}</Alert>
          )}
          {formError.code && (
            <Alert color='danger'> {t('common.uniqueCodeError')}</Alert>
          )}
          <Form>
            {/* <FormGroup>
              <Label for='code'>{t('common.uniqueCode')}</Label>
              <Input
                name='code'
                id='code'
                onChange={e => setCode(e.target.value)}
                value={code}
              />
            </FormGroup> */}
            <FormGroup>
              <Label for='address'>{t('common.address')}</Label>
              <Input
                name='address'
                id='address'
                onChange={e => {
                  setAddress(e.target.value)
                }}
                value={address}
              />
            </FormGroup>
            {/* <FormGroup>
              <Label for='apartaments'>{t('common.numberOfApartments')}</Label>
              <Input
                type='number'
                name='apartaments'
                id='apartaments'
                onChange={e => {
                  setApartaments(e.target.value)
                }}
                value={apartaments}
              />
            </FormGroup> */}
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button
            color='primary'
            onClick={() => {
              createInstallation()
            }}>
            {t('common.createAnInstallation')}
          </Button>

          <Button
            color='secondary'
            onClick={() => {
              setModalView(!modalView)
            }}>
            {t('common.cancel')}
          </Button>
        </ModalFooter>
      </Modal>

      <Modal isOpen={modalEdit} toggle={() => setModalEdit(!modalEdit)}>
        <ModalHeader toggle={() => setModalEdit(!modalEdit)}>
          {t('common.updateInstallation')}
        </ModalHeader>
        <ModalBody>
          {formError.address && (
            <Alert color='danger'> {t('common.addressError')}</Alert>
          )}
          {formError.code && (
            <Alert color='danger'> {t('common.uniqueCodeError')}</Alert>
          )}
          <Form>
            {/* <FormGroup>
              <Label for='code'>{t('common.uniqueCode')}</Label>
              <Input
                name='code'
                id='code'
                onChange={e => setEditCode(e.target.value)}
                defaultValue={editCode}
              />
            </FormGroup> */}
            <FormGroup>
              <Label for='address'>{t('common.address')}</Label>
              <Input
                name='address'
                id='address'
                onChange={e => {
                  setEditAddress(e.target.value)
                }}
                defaultValue={editAddress}
              />
            </FormGroup>
            {/* <FormGroup>
              <Label for='apartaments'>{t('common.numberOfApartments')}</Label>
              <Input
                type='number'
                name='apartaments'
                id='apartaments'
                onChange={e => {
                  setEditApartaments(e.target.value)
                }}
                defaultValue={editApartaments}
              />
            </FormGroup> */}
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button
            color='primary'
            onClick={() => {
              editInstallation()
            }}>
            {t('common.updateInstallation')}
          </Button>

          <Button
            color='secondary'
            onClick={() => {
              setModalEdit(!modalEdit)
            }}>
            {t('common.cancel')}
          </Button>
        </ModalFooter>
      </Modal>

      <Modal
        isOpen={modalDelete}
        toggle={() => {
          setModalDelete(!modalDelete)
        }}>
        <ModalHeader toggle={() => setModalDelete(!modalDelete)}>
          {t('common.deleteInstallation')}
        </ModalHeader>
        <ModalBody>
          {t('common.areYouSureYouWantToDeleteTheInstallation')}
          <b>{editAddress}</b>"?
        </ModalBody>
        <ModalFooter>
          <Button
            color='primary'
            onClick={() => {
              deleteInstallation()
            }}>
            {t('common.yesDelete')}
          </Button>
          <Button
            color='secondary'
            onClick={() => {
              setModalDelete(!modalDelete)
            }}>
            {t('common.noCancel')}
          </Button>
        </ModalFooter>
      </Modal>

      <Modal
        isOpen={uploadModal}
        toggle={() => !uploadingCsv && toggleUploadModal()}>
        <ModalHeader toggle={() => !uploadingCsv && toggleUploadModal()}>
          <Col className='d-flex'>
            {t('common.upload')}
            <HelpMenu />
          </Col>
        </ModalHeader>
        <ModalBody>
          <div style={{ margin: '10px 5px' }}>
            <Select
              isDisabled={true}
              inputValue={SVJ.name}
              className='basic-multi-select'
              classNamePrefix='select'
            />
          </div>

          <div style={{ margin: '10px 5px' }}>
            <Select
              options={optionsInstallation}
              onChange={handleChangeUploadInstallation}
              className='basic-multi-select'
              classNamePrefix='select'
            />
          </div>

          <Input
            type='file'
            name='file'
            label='Upload CSV'
            labelPosition='right'
            placeholder='UploadCSV...'
            onChange={handleChange}
          />
        </ModalBody>
        <ModalFooter>
          <Button
            disabled={uploadingCsv}
            color='danger'
            onClick={() => toggleUploadModal()}>
            {t('common.cancel')}
          </Button>{' '}
          {!uploadingCsv ? (
            <Button color='secondary' onClick={() => handleUploadCSV()}>
              {t('common.upload')}
            </Button>
          ) : (
            <Spinner size='sm' color='secondary' />
          )}
        </ModalFooter>
      </Modal>
    </>
  )
}

export default HouseOwnerAssociationBuildingsDetailPage
