import {
  Button,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Label,
  Spinner
} from 'reactstrap'
import { useTranslation } from 'react-i18next'
import DatePicker from 'react-datepicker'
import React, { useState } from 'react'
import Select from 'react-select'
import { addDays } from 'date-fns'
import moment from 'moment'

export default function Dashboard2ExportModal({
  buildingId,
  exportModal,
  optionsBuildings,
  fileType,
  setBuildingId,
  startDateRangeModal,
  endDateRangeModal,
  dateRangeModal,
  toggleExportModal,
  setCurrentFileType,
  handleDowloadFile,
  loadingDownloadFile,
  setStartDateRangeModal,
  setEndDateRangeModal,
  setDateRangeModal
}) {
  const { t, i18n } = useTranslation()
  const [isPickerOpenModal, setIsPickerOpenModal] = useState(false)

  const handleChangeUploadBuildings = async selectedOption => {
    setBuildingId(selectedOption.value)
  }

  const handleChangeFileType = selectedOption => {
    setCurrentFileType(selectedOption.value)
  }

  const onChangeDateRangeModal = dates => {
    if (!dates) {
      setStartDateRangeModal()
      setEndDateRangeModal()
      setDateRangeModal()
      return setIsPickerOpenModal(false)
    }
    const [start, end] = dates

    setStartDateRangeModal(start)
    setEndDateRangeModal(end)
    if (start && end) {
      setDateRangeModal(
        moment(`${start}`).format('DD.MM.YYYY') +
          ' - ' +
          moment(`${end}`).format('DD.MM.YYYY')
      )
      setIsPickerOpenModal(false)
    }
  }

  const onClickOutsideModal = () => {
    if (!startDateRangeModal || !endDateRangeModal) {
      setStartDateRangeModal()
      setEndDateRangeModal()
      setDateRangeModal()
    }
    setIsPickerOpenModal(false)
  }

  return (
    <Modal isOpen={exportModal} toggle={() => toggleExportModal()}>
      <ModalHeader toggle={() => toggleExportModal()}>
        {t('common.export')}
      </ModalHeader>
      <ModalBody>
        <div style={{ margin: '10px 5px' }}>
          <Select
            options={optionsBuildings}
            onChange={handleChangeUploadBuildings}
            className='basic-multi-select'
            classNamePrefix='select'
          />
        </div>

        <div style={{ margin: '10px 5px' }}>
          <Select
            options={fileType}
            onChange={handleChangeFileType}
            className='basic-multi-select'
            classNamePrefix='select'
            defaultValue={{ label: 'csv', value: 'csv' }}
          />
        </div>
        <div style={{ margin: '10px 5px' }}>
          <Label className='mr-2'>{t('common.fromTo')}</Label>
          <DatePicker
            locale={i18n.language}
            customInput={<Input />}
            selected={startDateRangeModal}
            onChange={onChangeDateRangeModal}
            selectsEnd
            startDate={startDateRangeModal}
            endDate={endDateRangeModal}
            selectsRange
            onInputClick={() => setIsPickerOpenModal(true)}
            open={isPickerOpenModal}
            value={dateRangeModal}
            maxDate={addDays(new Date(), -1)}
            onClickOutside={() => onClickOutsideModal()}
            className='datepicker'
            placeholderText={t('common.dateRangePlaceholder')}
            isClearable
            dateFormat='dd.MM.yyyy'
            filterDate={d => {
              return new Date() > d
            }}
          />
        </div>
      </ModalBody>
      <ModalFooter>
        {buildingId && !loadingDownloadFile ? (
          <Button
            color='secondary'
            onClick={handleDowloadFile}
            disable={loadingDownloadFile}>
            {t('common.export')}
          </Button>
        ) : null}
        {loadingDownloadFile && (
          <Button color='light'>
            <Spinner
              as='span'
              type='grow'
              size='sm'
              role='status'
              aria-hidden='true'
            />{' '}
            {t('common.exportData')}
          </Button>
        )}
      </ModalFooter>
    </Modal>
  )
}
