import { Card, CardBody, Col, Row } from 'reactstrap'
import { useTranslation } from 'react-i18next'
import React from 'react'
import { StaticTable } from '../../components/Tables/StaticTable'

const DeviceTable = ({ deviceReadings, loading }) => {
  const { t } = useTranslation()
  const columns = [
    {
      Header: t('common.serialNumber'),
      accessor: 'device_serial_number',
      filterable: false
    },
    {
      Header: t('common.deviceType'),
      accessor: 'device_type',
      filterable: false,
      sortable: false,
    },
    {
      Header: t('common.fabricationNumber'),
      accessor: 'fabrication_number',
      filterable: false,
      sortable: false,
    },
    {
      Header: t('common.lastReading'),
      accessor: 'last_reading',
      filterable: false,
      sortable: false,
      maxWidth: 300,
      Cell: value => {
        return (
          <span style={{ textAlign: 'right', width: '100%' }}>
            {value.row.original.last_reading}{' '}
            {value.row.original.measure_unit}
          </span>
        )
      }
    }
  ]

  return (
    <>
      <Row>
        <Col md='12'>
          <Card className='main-card mb-3'>
            <CardBody>
              <StaticTable columns={columns} data={deviceReadings} loading={loading} />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  )
}
export default DeviceTable
