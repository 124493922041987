import React from 'react';
import { useTranslation } from 'react-i18next'
import {
  Button,
  Card,
  CardBody,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
} from 'reactstrap'
import Loader from 'react-loaders'

const HouseOwnersAssociationDetailForm = ({
  SVJ,
  onHandleFormSubmit,
  onHandleChange,
  loading,
  submitting
}) => {
  const { t } = useTranslation()

  return (
    <>
      {loading && (
        <div className='loader-wrapper w-100 d-flex justify-content-center align-items-center'>
          <Loader type='ball-beat' />
        </div>
      )}

      {!loading && (
        <>
          <Card className='main-card mb-3'>
            <CardBody>
              <Col md={12}>
                <Form onSubmit={onHandleFormSubmit}>
                  <FormGroup>
                    <Label for='name'>
                      {t('formFields.nameOfHouseOwnerAssociationSVJ')}
                    </Label>
                    <Input
                      id='name'
                      name='name'
                      onChange={onHandleChange}
                      defaultValue={SVJ.name}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label for='address'>
                      {t('formFields.addressOfHouseOwnerAssociationSVJ')}
                    </Label>
                    <Input
                      id='note'
                      name='address'
                      // type='textarea'
                      onChange={onHandleChange}
                      defaultValue={SVJ.address}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label for='nameOfContactPerson'>
                      {t('formFields.nameOfTheContactPerson')}
                    </Label>
                    <Input
                      id='nameOfContactPerson'
                      name='contact_first_name'
                      onChange={onHandleChange}
                      defaultValue={SVJ.contact_first_name}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label for='surnameOfContactPerson'>
                      {t('formFields.surnameOfTheContactPerson')}
                    </Label>
                    <Input
                      id='surnameOfContactPerson'
                      name='contact_last_name'
                      onChange={onHandleChange}
                      defaultValue={SVJ.contact_last_name}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label for='phone'>
                      {t('formFields.contactPhoneNumber')}
                    </Label>
                    <Input
                      id='phone'
                      onChange={onHandleChange}
                      name='contact_phone'
                      defaultValue={SVJ.contact_phone}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label for='email'>
                      {t('formFields.contactEmailAddress')}
                    </Label>
                    <Input
                      id='email'
                      onChange={onHandleChange}
                      name='contact_email'
                      defaultValue={SVJ.contact_email}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label for='website'>{t('formFields.svjWebsite')}</Label>
                    <Input
                      id='website'
                      onChange={onHandleChange}
                      name='website'
                      defaultValue={SVJ.website}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Button
                      type='submit'
                      color='primary'
                      style={{ width: 120 }}>
                      {submitting ? (
                        <Loader
                          color='#ffffff'
                          innerClassName='d-flex align-items-center justify-content-center'
                          style={{ height: 18 }}
                          type='ball-beat'
                        />
                      )
                        : (t('common.save'))}
                    </Button>
                  </FormGroup>
                </Form>
              </Col>
            </CardBody>
          </Card>
        </>
      )}
    </>
  )
}

export default HouseOwnersAssociationDetailForm
