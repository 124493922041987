import { NotificationManager } from 'react-notifications'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import axios from 'axios'
import React, { Fragment, useEffect, useState } from 'react'
import { ReactComponent as LogoCreate } from '../../../assets/images/LogoComponents/ios-create.svg'

import HouseOwnersAssociationDetail from './HouseOwnersAssociationDetail'
import PageTitleAlt from '../../../Layout/AppMain/PageTitleAlt'

const HouseOwnersAssociationDetailPage = ({ match }) => {
  const { svjId } = useParams()
  const { t, i18n } = useTranslation()
  const [SVJ, setSVJ] = useState({})
  const [loading, setLoading] = useState(false)
  const [submitting, setSubmitting] = useState(false)

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/v1/svj-committees/${svjId}`,
          {
            headers: {
              'Content-Language': i18n.language === 'cz' ? 'cs' : 'en',
              'Content-Type': 'application/json',
              'Accept': 'application/json',
              'Authorization': 'Bearer ' + localStorage.getItem('authToken')
            }
          }
        )
        setSVJ(response.data.data)
        setLoading(false)
      } catch (err) {
        NotificationManager.error(err.response.data.message)
        setLoading(false)
      }
    }
    fetchData()
  }, [])
  const onHandleChange = e => {
    setSVJ({ ...SVJ, [e.target.name]: e.target.value })
  }

  const onHandleFormSubmit = async event => {
    event.preventDefault()
    setSubmitting(true)
    try {
      await axios.put(
        `${process.env.REACT_APP_API_URL}/api/v1/svj-committees/${svjId}`,
        { ...SVJ },
        {
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('authToken'),
            'Content-Language': i18n.language === 'cz' ? 'cs' : 'en'
          }
        }
      )
      NotificationManager.success(t('common.success'))
      setSubmitting(false)
    } catch (error) {
      if (error.response.data.errors !== undefined) {
      }
      setSubmitting(false)
    }
  }

  return (
    <Fragment>
      <PageTitleAlt
        heading={t('pageTitles.houseOwnersAssociationDetails')}
        icon={<LogoCreate className='SVGLogo' style={{ fill: '#343a40' }} />}
      />
      <HouseOwnersAssociationDetail
        loading={loading}
        submitting={submitting}
        SVJ={SVJ}
        onHandleChange={onHandleChange}
        onHandleFormSubmit={onHandleFormSubmit}
      />
    </Fragment>
  )
}

export default HouseOwnersAssociationDetailPage
