import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import cx from 'classnames'
import React from 'react'
import { Helmet } from 'react-helmet'

import AppMain from './AppMain'

class Main extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      closedSmallerSidebar: false
    }
  }

  render() {
    let { colorScheme, enablePageTabsAlt, enableMobileMenu } = this.props

    return (
      <>
        <Helmet>
          <meta charSet='utf-8' />
          <title>{process.env.REACT_APP_NAME}</title>
        </Helmet>
        <div
          className={cx(
            'app-container app-theme-' + colorScheme,
            { 'fixed-header': true },
            { 'fixed-sidebar': true },
            { 'fixed-footer': true },
            { 'closed-sidebar': false },
            { 'closed-sidebar-mobile': false },
            { 'sidebar-mobile-open': enableMobileMenu },
            { 'body-tabs-shadow-btn': enablePageTabsAlt }
          )}>
          <AppMain />
        </div>
      </>
    )
  }
}

const mapStateToProp = state => ({
  colorScheme: state.ThemeOptions.colorScheme,
  enableFixedHeader: state.ThemeOptions.enableFixedHeader,
  enableMobileMenu: state.ThemeOptions.enableMobileMenu,
  enableFixedFooter: state.ThemeOptions.enableFixedFooter,
  enableFixedSidebar: state.ThemeOptions.enableFixedSidebar,
  enableClosedSidebar: state.ThemeOptions.enableClosedSidebar,
  enablePageTabsAlt: state.ThemeOptions.enablePageTabsAlt
})

export default withRouter(connect(mapStateToProp)(Main))
