import React, { Component } from 'react'

const UserContext = React.createContext()

class UserProvider extends Component {
  // Context state
  state = {
    user: {},
    userRole: '',
  }

  // Method to update state
  setUser = user => {
    const user_role = {
      2: 'Owner',
      3: 'Svj',
      4: 'Manager'
    }
    this.setState({ user: user, userRole: user_role[user.role_id] })
  }

  render() {
    const { children } = this.props
    const { user, userRole } = this.state
    const { setUser } = this

    return (
      <UserContext.Provider
        value={{
          user,
          setUser,
          userRole
        }}>
        {children}
      </UserContext.Provider>
    )
  }
}

export default UserContext

export { UserProvider }
