import { useTranslation } from 'react-i18next'
import axios from 'axios'
import React, { Fragment, useState, useContext, useEffect } from 'react'
import Dashboard from './Dashboard'
import PageTitleAlt from '../../../Layout/AppMain/PageTitleAlt'
import UserContext from './../../../contexts/authContext'
import DataRanger from '../../components/DateRanger'
import { Row, Input } from 'reactstrap'
import DatePicker from 'react-datepicker'
import moment from 'moment'
import 'react-datepicker/dist/react-datepicker.css'
import { ReactComponent as LogoAlbums } from '../../../assets/images/LogoComponents/ios-albums.svg'
import { addDays } from 'date-fns'

const months = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec'
]

const currentYear = new Date().getFullYear()
const previousYear = currentYear - 1

const Dashboards = () => {
  const { t, i18n } = useTranslation()
  const { user } = useContext(UserContext)
  const [allStats, setAllStats] = useState([])
  const [originalStatsValues, setOriginalStatsValues] = useState([])
  const [comparisonStats, setComparisonStats] = useState([])
  const [loading, setLoading] = useState(false)
  const [period, setPeriod] = useState('lifetime')
  const [isDateRange, setIsDateRange] = useState(false)
  const [startDate, setStartDate] = useState()
  const [endDate, setEndDate] = useState(null)
  const [isPickerOpen, setIsPickerOpen] = useState(false)
  const [dateRange, setDateRange] = useState('')
  const [maxValue, setMaxValue] = useState(null)
  const [placeholder, setPlaceholder] = useState('')

  let apiHeaders = {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
    'Authorization': 'Bearer ' + localStorage.getItem('authToken')
  }

  const validateMonthsFromBackend = arr => {
    arr.forEach(val => {
      if (!months.includes(val.name)) {
        throw new Error(
          'Month names from API must be in format Jan, Feb, Mar etc'
        )
      }
    })
  }

  const addMonthLabels = arr => {
    validateMonthsFromBackend(arr)
    months.forEach((month, index) => {
      const monthFound = arr.find(val => val.name === month)
      if (monthFound) {
        monthFound.name = t(`months.${month.toLowerCase()}`)
        monthFound.monthNum = index + 1
      } else {
        // month was missing in data from BE
        arr.push({
          name: t(`months.${month.toLowerCase()}`),
          monthNum: index + 1
        })
      }
    })

    arr.sort((a, b) => a.monthNum - b.monthNum)
  }

  const handlePeriodChange = e => {
    if (e.target.value === 'custom') setIsDateRange(true)
    else setIsDateRange(false)
    setPeriod(e.target.value)
  }

  const calculateRange = (startDate, endDate) => {
    const start = moment(`${startDate}`).format('YYYY-MM-DD')
    const end = moment(`${endDate}`).format('YYYY-MM-DD')
    return `?from_date=${start}&to_date=${end}`
  }

  const fetchStats = async (startDate, endDate) => {
    try {
      setLoading(true)

      const range =
        startDate && endDate ? calculateRange(startDate, endDate) : ''

      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/v1/get-all-stats-for-flatowner/${
          user.id
        }${
          range !== '' ? range : period !== 'lifetime' && `?period=${period}`
        }`,
        { headers: apiHeaders }
      )
      const {
        cold_water_comparison,
        hot_water_comparison,
        heat_comparison
      } = response.data.data.chart_comparison

      const meters = [
        cold_water_comparison,
        hot_water_comparison,
        heat_comparison
      ]
      meters.forEach(item => addMonthLabels(item))

      setMaxValue(
        getMaxValueForChart([...cold_water_comparison, ...hot_water_comparison])
      )

      setAllStats(response.data.data.all_stats)
      if (originalStatsValues.length === 0) {
        setOriginalStatsValues(response.data.data.all_stats)
      }
      setComparisonStats(response.data.data.chart_comparison)
    } catch (error) {
      console.log(error)
    }
    setLoading(false)
  }

  const getMaxValueForChart = params =>
    params
      .filter(el => el[currentYear] || el[previousYear])
      .map(el => el[currentYear] || el[previousYear])
      .reduce((acc, val) => (acc > val ? acc : val))

  const onChange = dates => {
    if (!dates) {
      setStartDate()
      setEndDate()
      setDateRange()
      setIsPickerOpen(false)
      return fetchStats()
    }
    const [start, end] = dates

    setStartDate(start)
    setEndDate(end)
    if (start && end) {
      setDateRange(
        moment(`${start}`).format('DD.MM.YYYY') +
          ' - ' +
          moment(`${end}`).format('DD.MM.YYYY')
      )
      setIsPickerOpen(false)
      fetchStats(start, end)
    }
  }

  useEffect(() => {
    fetchStats()
  }, [period])

  useEffect(() => {
    fetchStats()
  }, [user])

  useEffect(() => {
    if (!isPickerOpen) setPlaceholder(t('common.startDate'))
    if (startDate) setPlaceholder(t('common.endDate'))
    if (endDate) setPlaceholder(t('common.startDate'))
  }, [startDate, endDate, isPickerOpen])

  const onClickOutside = () => {
    if (!startDate || !endDate) {
      setStartDate()
      setEndDate()
      setDateRange()
    }
    setIsPickerOpen(false)
  }

  const renderDayContents = day => {
    return (
      <span className='select-day' selectDayValue={placeholder}>
        {day}
      </span>
    )
  }

  // Note: we are temporarily hiding DataRanger in https://app.activecollab.com/105577/projects/248?modal=Task-38218-248
  // Note: we have reinstated DataRanger in https://app.activecollab.com/105577/my-work?modal=Task-43861-248
  const rightBlock = (
    <Row>
      <div className='input-datepicker-wrapper'>
        <DataRanger
          handlePeriodChange={handlePeriodChange}
          period={period}
          className='form-control mr-4'
          instalationDetail
        />
        {isDateRange && (
          <DatePicker
            showYearDropdown
            renderDayContents={renderDayContents}
            dayClassName={() => 'select-date'}
            locale={i18n.language}
            isClearable
            filterDate={d => {
              return new Date() > d
            }}
            customInput={<Input />}
            selected={startDate}
            onChange={onChange}
            selectsEnd
            maxDate={addDays(new Date(), -1)}
            startDate={startDate}
            endDate={endDate}
            selectsRange
            onInputClick={() => setIsPickerOpen(true)}
            onClickOutside={() => onClickOutside()}
            open={isPickerOpen}
            value={dateRange || ''}
            className='datepicker'
            placeholderText={placeholder}
            dateFormat='dd.MM.yyyy'
          />
        )}
      </div>
    </Row>
  )

  const getFilterEnabledValue = () => {
    if (period === 'weekly' || period === 'monthly' || period === 'yearly') {
      return true
    }
    if (period === 'lifetime') {
      return false
    }
    if (isDateRange && (startDate || startDate === '')) {
      return true
    }
    if (!isDateRange) {
      return false
    }
    return false
  }

  // role ids => 2: 'Owner', 3: 'Svj', 4: 'Manager'
  return (
    <Fragment>
      <PageTitleAlt
        heading={
          user.role_id === 2
            ? t('pageTitles.dashboardExtended')
            : t('pageTitles.dashboard')
        }
        icon={<LogoAlbums className='SVGLogo' style={{ fill: '#343a40' }} />}
        rightBlock={rightBlock}
        hint={t('common.dataHint')}
      />
      <Dashboard
        maxValue={maxValue}
        allStats={allStats}
        originalStatsValues={originalStatsValues}
        comparisonStats={comparisonStats}
        loading={loading}
        isFilterEnabled={getFilterEnabledValue()}
      />
    </Fragment>
  )
}

export default Dashboards
