import { useTranslation } from 'react-i18next'
import React, { Fragment } from 'react'
import { ReactComponent as LogoPersonAdd } from '../../../assets/images/LogoComponents/ios-person-add.svg';

import CreateSVJForm from '../../components/forms/CreateSVJ'
import PageTitleAlt from '../../../Layout/AppMain/PageTitleAlt'

const CreateSVJ = props => {
  const { t } = useTranslation()

  return (
    <Fragment>
      <PageTitleAlt
        heading={t('common.createANewSVJ')}
        icon={<LogoPersonAdd className="SVGLogo" style={{ fill: '#343a40' }} />}
      />

      <CreateSVJForm />
    </Fragment>
  )
}

export default CreateSVJ
