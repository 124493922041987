import { useTranslation } from 'react-i18next'
import React, { Fragment } from 'react'
import {
  Row,
  Col,
  Card,
  CardBody,
  Label,
  Input,
  Form,
  FormGroup,
  Button
} from 'reactstrap'
import Loader from 'react-loaders'

const DataForm = ({
  company,
  passwords,
  onHandleChangeCompany,
  onHandleChangePasswordSubmit,
  onHandleChangePasswords,
  onHandleFormSubmit,
  loading,
  submitting,
  passwordSubmitting
}) => {
  const { t } = useTranslation()

  return (
    <Fragment>
      {loading && (
        <div className='loader-wrapper w-100 d-flex justify-content-center align-items-center'>
          <Loader type='ball-beat' />
        </div>
      )}

      {!loading && (
        <React.Fragment>
          <Card className='main-card mb-3'>
            <CardBody>
              <Row>
                <Col md={6}>
                  <Form onSubmit={onHandleFormSubmit}>
                    <FormGroup>
                      <Label for='first_name'>
                        {t('common.nameOfTheInstaller')}
                      </Label>
                      <Input
                        id='first_name'
                        name='first_name'
                        onChange={onHandleChangeCompany}
                        defaultValue={company.first_name || ''}
                      />
                    </FormGroup>

                    <FormGroup>
                      <Label for='phone'>
                        {t('formFields.contactPhoneNumber')}
                      </Label>
                      <Input
                        id='phone'
                        name='contact_no'
                        onChange={onHandleChangeCompany}
                        defaultValue={company.contact_no || ''}
                      />
                    </FormGroup>

                    <FormGroup>
                      <Label for='email'>
                        {t('formFields.contactEmailAddress')}
                      </Label>
                      <Input
                        id='email'
                        name='contact_email'
                        onChange={onHandleChangeCompany}
                        defaultValue={company.contact_email || ''}
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label for='website'>{t('formFields.svjWebsite')}</Label>
                      <Input
                        id='website'
                        name='website'
                        onChange={onHandleChangeCompany}
                        defaultValue={company.website || ''}
                      />
                    </FormGroup>
                    <FormGroup>
                      <Button
                        type='submit'
                        color='primary'
                        disabled={submitting}
                        style={{ width: 120 }}
                        className='d-flex align-items-center justify-content-center'>
                        {submitting ? (
                          <Loader
                            color='#ffffff'
                            innerClassName='d-flex align-items-center justify-content-center'
                            style={{ height: 18 }}
                            type='ball-beat'
                          />
                        ) : (
                          t('common.save')
                        )}
                      </Button>
                    </FormGroup>
                  </Form>
                </Col>
              </Row>
            </CardBody>
          </Card>
          <Card className='main-card mb-3'>
            <CardBody>
              <Row>
                <Col>
                  <Form onSubmit={onHandleChangePasswordSubmit}>
                    <Row>
                      <Col md={4} sm={12}>
                        <FormGroup>
                          <Label for='currentPassword'>
                            {t('formFields.currentPassword')}
                          </Label>
                          <Input
                            id='currentPassword'
                            onChange={onHandleChangePasswords}
                            name='current_password'
                            type='password'
                            value={passwords.current_password}
                          />
                        </FormGroup>
                      </Col>
                      <Col md={4} sm={12}>
                        <FormGroup>
                          <Label for='newPassword'>
                            {t('formFields.newPassword')}
                          </Label>
                          <Input
                            id='newPassword'
                            onChange={onHandleChangePasswords}
                            name='new_password'
                            type='password'
                            value={passwords.new_password}
                          />
                        </FormGroup>
                      </Col>
                      <Col md={4} sm={12}>
                        <FormGroup>
                          <Label for='confirmPassword'>
                            {t('formFields.confirmNewPassword')}
                          </Label>
                          <Input
                            id='confirmPassword'
                            onChange={onHandleChangePasswords}
                            name='new_password_confirmation'
                            type='password'
                            value={passwords.new_password_confirmation}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <FormGroup>
                      <Button
                        type='submit'
                        color='primary'
                        disabled={passwordSubmitting}
                        style={{ width: 120 }}
                        className='d-flex align-items-center justify-content-center'>
                        {passwordSubmitting ? (
                          <Loader
                            color='#ffffff'
                            innerClassName='d-flex align-items-center justify-content-center'
                            style={{ height: 18 }}
                            type='ball-beat'
                          />
                        ) : (
                          t('common.save')
                        )}
                      </Button>
                    </FormGroup>
                  </Form>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </React.Fragment>
      )}
    </Fragment>
  )
}

export default DataForm
