import { Card, CardBody, Col, Row, Badge } from 'reactstrap'
import { useTranslation } from 'react-i18next'
import React from 'react'
import { DynamicTable } from '../../components/Tables/DynamicTable'
import { ReactComponent as LogoInfo } from '../../../assets/images/LogoComponents/ios-information.svg';

const sortTable = {
  "device_serial_number": "device_serial_number",
  "name_customer": "name_customer",
  "error_translations": "error_flag_decimal"
}

const DeviceNotifications = ({ data, loading, fetchData, ...props }) => {
  const { t, i18n } = useTranslation()

  const errorElement = (errorType, msg, i) => {
    return (
      <div key={`${errorType}-${i}`}>
        <Badge
          className='mr-2'
          color='warning'
          pill
          style={{
            padding: '1px 9px 1px 2px',
            width: '65px',
            textAlign: 'left',
            backgroundColor: errorType === 'is' || errorType === 'je' ? '#ffbdcd' : '#ffedc3'
          }}>
          <LogoInfo style={{ fill: (errorType === 'is' || errorType === 'je') ? '#d92550' : '#f7b924' }}
            className='mr-1 SVGLogo'
          />
          {errorType}
        </Badge>

        <span>{msg}</span>
      </div>
    )
  }

  const renderErrors = ({ is, was }) => {
    let errorElements = []

    is.forEach((msg, i) => {
      if (i18n.language === 'cz') {
        errorElements.push(errorElement(t('common.is'), msg.cs, i))
      } else {
        errorElements.push(errorElement(t('common.is'), msg.en, i))
      }
    })

    was.forEach((msg, i) => {
      if (i18n.language === 'cz') {
        errorElements.push(errorElement(t('common.was'), msg.cs, i))
      } else {
        errorElements.push(errorElement(t('common.was'), msg.en, i))
      }
    })

    return errorElements
  }

  const columns = [
    {
      Header: t('common.serialNumber'),
      accessor: 'device_serial_number',
      filterable: false,
    },
    {
      Header: t('common.customerName'),
      accessor: 'name_customer',
      filterable: false,
    },
    {
      Header: t('common.nextChangeOfTheDevice'),
      accessor: 'next_change_of_the_device',
      filterable: false,
      sortable: false,
    },
    {
      Header: t('common.condition'),
      accessor: 'error_translations',
      filterable: false,
      maxWidth: 300,
      sortable: false,
      Cell: ({ value }) => {
        return (
          <div className='errorElementsList d-flex flex-column'>
            {renderErrors(value)}
          </div>
        )
      }
    }
  ]

  return (
    <>
      <Row>
        <Col md='12'>
          <Card className='main-card mb-3'>
            <CardBody>
              <DynamicTable
                data={data}
                columns={columns}
                loading={loading}
                page={props.page}
                limit={props.limit}
                setPage={props.setPage}
                setLimit={props.setLimit}
                lastPage={props.lastPage}
                setSortValue={props.setSortValue}
                sort={props.sort}
                setSort={props.setSort}
                sortValue={props.sortValue}
                sortValues={sortTable}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  )
}

export default DeviceNotifications
