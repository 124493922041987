import { faAngleDown } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import axios from 'axios'
import React, { Fragment } from 'react'
import {
  Button,
  DropdownMenu,
  DropdownToggle,
  UncontrolledButtonDropdown
} from 'reactstrap'

import avatar1 from '../../../assets/avatar-placeholder.png'

const UserBox = ({ name }) => {
  const history = useHistory()
  const { t, i18n } = useTranslation()

  const handleClickLogout = () => {
    axios({
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('authToken'),
        'Content-Language': i18n.language === 'cz' ? 'cs' : 'en' 
      },
      url: `${process.env.REACT_APP_API_URL}/api/v1/auth/logout`
    })
      .then(function(response) {
        localStorage.clear()
        history.push('/login')
      })
      .catch(function(error) {
        return true
      })
      .finally(() => {
        return true
      })
  }
  
  return (
    <Fragment>
      <div className='header-btn-lg pr-0'>
        <div className='widget-content p-0'>
          <div className='widget-content-wrapper'>
            <div className='widget-content-left mr-2'>
              <div className='widget-heading text-dark'>{name}</div>
            </div>

            <div className='widget-content-left'>
              <UncontrolledButtonDropdown>
                <DropdownToggle color='link' className='p-0'>
                  <div style={{whiteSpace: 'nowrap', display: 'flex', alignItems: 'center'}}>
                    <FontAwesomeIcon
                      className='mr-2 text-dark'
                      icon={faAngleDown}
                    />
                    <img
                      width={40}
                      className='rounded-circle'
                      src={avatar1}
                      alt={name}
                      style={{ objectFit: 'cover' }}
                    />
                  </div>
                </DropdownToggle>

                <DropdownMenu
                  right
                  className='rm-pointers dropdown-menu-lg'
                  style={{ paddingBottom: 0 }}>
                  <div
                    className='dropdown-menu-header'
                    style={{ marginBottom: 0 }}>
                    <div className='dropdown-menu-header-inner'>
                      <div className='menu-header-content text-left'>
                        <div className='widget-content p-0'>
                          <div className='widget-content-wrapper'>
                            <div className='widget-content-left mr-3'>
                              <img
                                width={42}
                                className='rounded-circle'
                                src={avatar1}
                                alt={name}
                              />
                            </div>
                            <div className='widget-content-left'>
                              <div
                                className='widget-heading display-none-380 text-dark'
                                style={{
                                  width: '180px',
                                  overflow: 'hidden',
                                  textOverflow: 'ellipsis'
                                }}>
                                {name}
                              </div>
                            </div>
                            <div className='widget-content-right mr-2'>
                              <Button
                                color='secondary'
                                onClick={handleClickLogout}>
                                {t('common.logout')}
                              </Button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </DropdownMenu>
              </UncontrolledButtonDropdown>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default UserBox
