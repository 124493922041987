import React, { useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { Col, Row, Button, Form, FormGroup, Label, Input } from 'reactstrap'
import { NotificationManager } from 'react-notifications'
import { useTranslation } from 'react-i18next'
import axios from 'axios'
import SliderLeft from '../../components/SliderLeft'

const Login = () => {
  const history = useHistory()
  const [email, setEmail] = useState(null)
  const [password1, setPassword1] = useState()
  const [error, setError] = useState()
  const [password2, setPassword2] = useState()
  const { t, i18n } = useTranslation()

  const token = new URLSearchParams(useLocation().search).get('token')

  const signIn = e => {
    e.preventDefault()
    if (!password1 || password1 !== password2) {
      return setError('authentication.samePassowrd')
    }

    axios({
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('authToken'),
        'Content-Language': i18n.language === 'cz' ? 'cs' : 'en'
      },
      url: `${process.env.REACT_APP_API_URL}/api/v1/password/reset`,
      data: {
        email: email,
        password: password1,
        password_confirmation: password2,
        token
      }
    })
      .then(function() {
        NotificationManager.success(t('authentication.invalidToken'))
        history.push('/login')
      })
      .catch(function(error) {
        console.log(error)
      })
  }

  return (
    <>
      <div className='h-100'>
        <Row className='h-100 no-gutters'>
          <Col lg='4' className='d-none d-lg-block'>
            <div className='slider-light'>
              <SliderLeft />
            </div>
          </Col>
          <Col
            lg='8'
            md='12'
            className='h-100 d-flex bg-white justify-content-center align-items-center'>
            <Col lg='9' md='10' sm='12' className='mx-auto app-login-box'>
              <div className='app-logo' />
              <h4 className='mb-0'>
                <div>{t('authentication.welcomeBack')}</div>
                <span>{t('authentication.pleaseSignInToYourAccount')}</span>
              </h4>
              <Row className='divider' />
              <div>
                <Form onSubmit={signIn}>
                  <Row form>
                    <Col md={12}>
                      <FormGroup>
                        <Label for='exampleEmail'>
                          {t('formFields.email')}
                        </Label>
                        <Input
                          type='email'
                          name='email'
                          id='email'
                          placeholder={t('formFields.emailPlaceholder')}
                          onBlur={e => setEmail(e.currentTarget.value)}
                        />
                      </FormGroup>
                    </Col>
                    <Col md={6}>
                      <FormGroup>
                        <Label for='exampleEmail'>
                          {t('formFields.newPassword')}
                        </Label>
                        <Input
                          type='password'
                          name='password1'
                          id='password1'
                          placeholder={t('formFields.passwordPlaceholder')}
                          onBlur={e => setPassword1(e.currentTarget.value)}
                        />
                      </FormGroup>
                    </Col>
                    <Col md={6}>
                      <FormGroup>
                        <Label for='examplePassword'>
                          {t('formFields.password')}
                        </Label>
                        <Input
                          type='password'
                          name='password2'
                          id='password2'
                          placeholder={t('formFields.passwordPlaceholder')}
                          onBlur={e => setPassword2(e.currentTarget.value)}
                        />
                        {error && <p className='text-danger'>{t(error)}</p>}
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row className='divider' />
                  <div className='d-flex align-items-center'>
                    <div className='ml-auto'>
                      <Button color='primary' size='lg' type='submit'>
                        {t('authentication.recoverPassword')}
                      </Button>
                    </div>
                  </div>
                </Form>
              </div>
            </Col>
          </Col>
        </Row>
      </div>
    </>
  )
}

export default Login
