import { NotificationManager } from 'react-notifications'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import axios from 'axios'
import React, { Fragment, useContext, useEffect } from 'react'
import { ReactComponent as LogoCreate } from '../../../assets/images/LogoComponents/ios-create.svg'
import DataForm from './DataForm'
import DeviceTable from './DeviceTable'
import PageTitleAlt from '../../../Layout/AppMain/PageTitleAlt'
import { formatUnits } from '../../../utils/index'
import { OwnerContext } from '../../../contexts'

const FlatOwnerDetail = ({ match }) => {
  const { t, i18n } = useTranslation()

  const {
    owner,
    setOwner,
    deviceReadings,
    setDeviceReadings,
    submitting,
    setSubmitting,
    loading,
    setLoading
  } = useContext(OwnerContext)

  const { id, flatOwnerId } = useParams()

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/v1/flat-owners-extend/${flatOwnerId}`,
          {
            headers: {
              'Content-Type': 'application/json',
              'Accept': 'application/json',
              'Authorization': 'Bearer ' + localStorage.getItem('authToken'),
              'Content-Language': i18n.language === 'cz' ? 'cs' : 'en'
            }
          }
        )
        response.data.data.device_readings.forEach(
          el => (el.measure_unit = formatUnits(el.measure_unit))
        )
        setDeviceReadings(response.data.data.device_readings)
        setOwner(response.data.data)
        setLoading(false)
      } catch (error) {
        setLoading(false)
      }
    }
    fetchData()
  }, [])

  const onHandleFormSubmit = async (e, formData) => {
    e.preventDefault()
    setSubmitting(true)
    try {
      await axios.patch(
        `${process.env.REACT_APP_API_URL}/api/v1/flat-owners-extend/${owner.id}`,
        formData,
        {
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('authToken'),
            'Content-Language': i18n.language === 'cz' ? 'cs' : 'en'
          }
        }
      )

      setSubmitting(false)
      NotificationManager.success(t('common.updated'))
    } catch (error) {
      if (error.response.data.errors !== undefined) {
      }
      setSubmitting(false)
    }
  }

  const onHandleChange = async event => {}

  return (
    <Fragment>
      <PageTitleAlt
        heading={t('pageTitles.flatOwnerDetail')}
        icon={<LogoCreate className='SVGLogo' style={{ fill: '#343a40' }} />}
      />
      <DataForm
        loading={loading}
        submitting={submitting}
        owner={owner}
        onHandleFormSubmit={onHandleFormSubmit}
        onHandleChange={onHandleChange}
      />
      <DeviceTable deviceReadings={deviceReadings} loading={loading} />
    </Fragment>
  )
}

export default FlatOwnerDetail
