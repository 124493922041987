import { Button, Card, CardBody, Col, Row, Tooltip } from 'reactstrap'
import { faEye, faTrash, faUserEdit } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import React, { Fragment, useEffect, useState } from 'react'
import DeleteSVJModal from './DeleteSVJModal'
import { DynamicTable } from '../../components/Tables/DynamicTable'

const sortTable = {
  contact_first_name: 'contact_first_name',
  name: 'name',
  contact_last_name: 'contact_last_name'
}

const CustomTooltip = ({ id, text }) => {
  const [tooltipOpen, setTooltipOpen] = useState(false)

  const toggle = () => setTooltipOpen(!tooltipOpen)

  return (
    <Tooltip placement='top' target={id} isOpen={tooltipOpen} toggle={toggle}>
      {text}
    </Tooltip>
  )
}

const Dashboard = ({
  currentPage,
  chartsData,
  recordsCount,
  fetchData,
  setSort,
  allSVJs,
  ...props
}) => {
  const { t } = useTranslation()
  const history = useHistory()

  const [modal, setModal] = useState(false)
  const [selectedSVJ, setSelectedSVJ] = useState(null)

  const toggleModal = id => {
    setSelectedSVJ(id)
    setModal(!modal)
  }

  const columns = [
    {
      Header: t('common.svjDetails'),
      columns: [
        {
          Header: t('common.title'),
          accessor: 'name',
          Cell: ({ value }) => {
            return (
              <div style={{ textAlign: 'center', width: '100%' }}>{value}</div>
            )
          }
        },
        {
          Header: t('common.address'),
          accessor: 'address',
          Cell: ({ value }) => {
            return (
              <div style={{ textAlign: 'center', width: '100%' }}>{value}</div>
            )
          }
        }
      ]
    },
    {
      Header: t('common.contactPersonDetails'),
      columns: [
        {
          Header: t('common.name'),
          accessor: 'contact_first_name',
          Cell: ({ value }) => {
            return (
              <div style={{ textAlign: 'center', width: '100%' }}>{value}</div>
            )
          }
        },
        {
          Header: t('common.surname'),
          accessor: 'contact_last_name',
          Cell: ({ value }) => {
            return (
              <div style={{ textAlign: 'center', width: '100%' }}>{value}</div>
            )
          }
        },
        {
          Header: t('common.contactNumber'),
          accessor: 'contact_phone',
          Cell: ({ value }) => {
            return (
              <div style={{ textAlign: 'center', width: '100%' }}>{value}</div>
            )
          }
        },
        {
          Header: t('common.email'),
          accessor: 'contact_email',
          Cell: ({ value }) => {
            return (
              <div style={{ textAlign: 'center', width: '100%' }}>{value}</div>
            )
          }
        }
      ]
    },
    {
      Header: t('common.actions'),
      columns: [
        {
          accessor: 'id',
          Cell: ({ value }) => (
            <div
              style={{
                display: 'grid',
                gridTemplateColumns: 'repeat(auto-fit, minmax(30px, 1fr))',
                columnGap: 8,
                rowGap: 8,
                width: '100%'
              }}>
              <Button
                style={{ paddingLeft: 0, paddingRight: 0 }}
                outline
                id={`show-button-${value}`}
                color='secondary'
                onClick={() => history.push(`/svj/buildings/${value}`)}>
                <FontAwesomeIcon icon={faEye} />
              </Button>

              <CustomTooltip
                id={`show-button-${value}`}
                text={t('common.showTheSvj')}
              />

              <Button
                style={{ paddingLeft: 0, paddingRight: 0 }}
                outline
                color='secondary'
                id={`edit-button-${value}`}
                onClick={() => history.push(`/svj/edit/${value}`)}>
                <FontAwesomeIcon icon={faUserEdit} />
              </Button>

              <CustomTooltip
                id={`edit-button-${value}`}
                text={t('common.editTheSvj')}
              />

              <Button
                style={{ paddingLeft: 0, paddingRight: 0 }}
                outline
                id={`delete-button-${value}`}
                color='danger'
                onClick={() => {
                  toggleModal(value)
                }}>
                <FontAwesomeIcon icon={faTrash} />
              </Button>

              <CustomTooltip
                id={`delete-button-${value}`}
                text={t('common.deleteTheSvj')}
              />
            </div>
          )
        }
      ]
    }
  ]

  useEffect(() => {
    if (props.sortValue && props.sort !== '')
      fetchData(true, sortTable[props.sortValue])
    else fetchData()
  }, [t, props.sort, currentPage, props.limit, props.searchQuery])

  return (
    <Fragment>
      <Row>
        <Col md='12'>
          <Card className='main-card mb-3'>
            <CardBody>
              <DynamicTable
                lastPage={props.lastPage}
                columns={columns}
                data={allSVJs}
                loading={props.loading}
                page={currentPage}
                limit={props.limit}
                setPage={props.onSetPage}
                setLimit={props.onSetLimit}
                sortValues={sortTable}
                setSortValue={props.setSortValue}
                sort={props.sort}
                setSort={setSort}
                sortValue={props.sortValue}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
      <DeleteSVJModal
        modal={modal}
        page={props.page}
        limit={props.limit}
        allSVJs={allSVJs}
        setModal={setModal}
        selectedSVJ={selectedSVJ}
        fetchData={fetchData}
      />
    </Fragment>
  )
}

export default Dashboard
