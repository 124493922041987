import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown
} from 'reactstrap'
import { useTranslation } from 'react-i18next'
import Flag from 'react-flagkit'
import React, { Fragment } from 'react'

export const HeaderDots = props => {
  const { t, i18n } = useTranslation()

  const switchFlag = () => {
    let flag = (
      <Flag
        className='mr-3 opacity-8'
        country={i18n.language.toUpperCase()}
        size='40'
      />
    )

    if (i18n.language === 'en') {
      flag = <Flag className='mr-3 opacity-8' country={'US'} size='40' />
    }

    if (i18n.language === 'en-GB') {
      flag = <Flag className='mr-3 opacity-8' country={'US'} size='40' />
    }

    return flag
  }

  return (
    <Fragment>
      <div className='header-dots'>
        <UncontrolledDropdown>
          <DropdownToggle className='p-0' color='link'>
            <div className='icon-wrapper icon-wrapper-alt rounded-circle'>
              <div className='icon-wrapper-bg bg-focus' />
              <div className='language-icon'>{switchFlag()}</div>
            </div>
          </DropdownToggle>

          <DropdownMenu right className='rm-pointers'>
            <DropdownItem header>{t('common.changeLanguage')}</DropdownItem>
            <DropdownItem onClick={() => i18n.changeLanguage('en')}>
              <Flag className='mr-3 opacity-8' country='US' />
              English (US)
            </DropdownItem>
            <DropdownItem onClick={() => i18n.changeLanguage('cz')}>
              <Flag className='mr-3 opacity-8' country='CZ' />
              Czech (Česká republika)
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
      </div>
    </Fragment>
  )
}

export default HeaderDots
