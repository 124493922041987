import {
  Button,
  Input,
  Row,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner
} from 'reactstrap'
import { useHistory, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import axios from 'axios'
import React, { useEffect, useState, useContext } from 'react'
import HouseOwnersTable from '../../components/HouseOwnersTable/HouseOwnersTable'
import PageTitleAlt from '../../../Layout/AppMain/PageTitleAlt'
import UserContext from './../../../contexts/authContext'
import moment from 'moment'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { formatUnits } from '../../../utils'
import { SearchFiled } from '../../components/SearchField'
import DateRangerWithPicker from '../../components/DateRangerWithPicker'
import { ReactComponent as LogoAnalistics } from '../../../assets/images/LogoComponents/ios-analytics.svg'
import { ReactComponent as LogoCloudUpload } from '../../../assets/images/LogoComponents/ios-cloud-upload.svg'
import { CUSTOM_PERIOD } from '../../../constants'
import HelpMenu from 'assets/components/help-menu'
import { NotificationManager } from 'react-notifications'

const sortTable = {
  name: 'name',
  surname: 'surname',
  entrance: 'entrance',
  floor_flat: 'floor_flat',
  heatSpent: 'heat_spend',
  coldWaterSpent: 'cold_water_spend',
  hotWaterSpent: 'hot_water_spend'
}

const HouseOwnersAssociationDetailsView = () => {
  const history = useHistory()
  const user = useContext(UserContext)
  const { t, i18n } = useTranslation()
  const { svjVId, flatId } = useParams()
  const [searchQuery, setSearchQuery] = useState('')
  const [filterValue, setFilterValue] = useState('')
  const [loading, setLoading] = useState(false)
  const [chartsLoading, setChartsLoading] = useState(true)
  const [limit, setLimit] = useState(10)
  const [page, setPage] = useState(1)
  const [uploadModal, setUploadModal] = useState(false)
  const [csv, setCsv] = useState(null)
  const [csvLoading, setCsvLoading] = useState(false)
  const [lastPage, setLastPage] = useState(1)
  const [data, setData] = useState()
  const [dateScv, setDateScv] = useState()
  const [totalOwnersPages, setTotalOwnersPages] = useState(0)
  const [period, setPeriod] = useState('yearly')
  const [svj, setSvj] = useState(null)
  const [svjTitleAddress, setSvjTitleAddress] = useState(null)
  const [sort, setSort] = useState('asc')
  const [sortValue, setSortValue] = useState('')
  const [startDateRange, setStartDateRange] = useState()
  const [endDateRange, setEndDateRange] = useState(null)
  const [placeholder, setPlaceholder] = useState('')

  const [chartsData, setChartsData] = useState({
    total_number_of_flats: null,
    total_cold_water_spent: null,
    total_hot_water_spent: null,
    total_heat_spent: null,
    total_heat_spent_unit: null,
    total_heater_spent: null,
    total_heater_spent_unit: null
  })
  const [apiHeaders, setApiHeaders] = useState({
    'Content-Type': 'application/json',
    'Accept': 'application/json',
    'svj-id': svjVId || user.user.id,
    'Authorization': 'Bearer ' + localStorage.getItem('authToken'),
    'Content-Language': i18n.language === 'cz' ? 'cs' : 'en'
  })

  const getChartsData = async (startDate, endDate) => {
    setChartsLoading(true)
    try {
      let query = `?period=${period}`
      if (startDate && endDate && period === CUSTOM_PERIOD) {
        const start = moment(`${startDate}`).format('YYYY-MM-DD')
        const end = moment(`${endDate}`).format('YYYY-MM-DD')
        query = `?from_date=${start}&to_date=${end}`
      }
      let response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/v1/get-all-stats/${flatId}${query}`,
        {
          headers: apiHeaders
        }
      )
      setChartsData(response.data.data)
      setChartsLoading(false)
    } catch (error) {
      setChartsLoading(false)
    }
  }

  const getSvjAddress = async () => {
    setLoading(true)
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/v1/svj-committees`,
        {
          headers: apiHeaders
        }
      )
      const allSvjData = data.data.find(svj => svj.id === parseInt(svjVId))
      setSvj(allSvjData)
      const currentBuilding = allSvjData.buildings.find(
        building => building.id === parseInt(flatId)
      )
      setSvjTitleAddress(currentBuilding.address)
    } catch (err) {
      console.log(err)
    }
    setLoading(false)
  }

  const handleUploadCSV = async () => {
    if (csv && dateScv) {
      setCsvLoading(true)
      try {
        const date = dateScv.toLocaleDateString('en-ZA')
        const formData = new FormData()
        formData.append('datalogger', csv[0])
        formData.append('date', date)
        formData.append('overide', 0)
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/api/v1/upload-datalogger-csv`,
          formData,
          {
            headers: apiHeaders
          }
        )
        NotificationManager.success(response.data.data)

        setCsvLoading(false)
        setUploadModal(false)
      } catch (error) {
        console.log(error)
        setCsvLoading(false)
      }
    }
  }

  const fetchData = async (sortCol = false, col, startDate, endDate) => {
    setLoading(true)
    try {
      let response = null
      let sortQuery = ''
      let dateQuery = `period=${period}`
      if (startDate && endDate && period === CUSTOM_PERIOD) {
        const start = moment(`${startDate}`).format('YYYY-MM-DD')
        const end = moment(`${endDate}`).format('YYYY-MM-DD')
        dateQuery += `&from_date=${start}&to_date=${end}`
      }

      if (sortCol) {
        sortQuery = `&order[0][column]=${col}&order[0][dir]=${sort}`
      }

      if (searchQuery === '') {
        response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/v1/get-all-flats-by-building/${flatId}?${dateQuery}&length=${limit}${sortQuery}&page=${page}`,
          {
            headers: apiHeaders
          }
        )
      } else {
        response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/v1/get-all-flats-by-building/${flatId}?${dateQuery}&length=${limit}${sortQuery}&search[value]=${searchQuery}&page=${page}`,
          {
            headers: apiHeaders
          }
        )
      }
      const periodValue = `${period}_totals`

      setLastPage(response.data.data.last_page)
      setData(
        response.data.data.data.map(item => {
          let coldWaterUsage = '0'
          let hotWaterUsage = '0'
          let heatUsage = '0'

          if (period === CUSTOM_PERIOD) {
            coldWaterUsage = `${item[periodValue].cold_water_spend} ${item[periodValue].cold_water_spend_unit}`
            hotWaterUsage = `${item[periodValue].hot_water_spend} ${item[periodValue].hot_water_spend_unit}`
            heatUsage = `${item[periodValue].heat_spend} ${item[periodValue].heat_spend_unit}`
          } else if (item[periodValue].length !== 0) {
            coldWaterUsage = `${item[periodValue][0].cold_water_spend} ${item[periodValue][0].cold_water_spend_unit}`
            hotWaterUsage = `${item[periodValue][0].hot_water_spend} ${item[periodValue][0].hot_water_spend_unit}`
            heatUsage =
              item[periodValue][0]?.heater_spend 
                ? `${Number(item[periodValue][0].heater_spend || '0').toFixed(
                    2
                  )} ${item[periodValue][0]?.heater_spend_unit || ''}`
                : `${item[periodValue][0].heat_spend} ${item[periodValue][0].heat_spend_unit}`
          }

          return {
            ...item,
            coldWaterSpent: formatUnits(coldWaterUsage),
            hotWaterSpent: formatUnits(hotWaterUsage),
            heatSpent: formatUnits(heatUsage),
            action: (
              <Button
                onClick={() =>
                  history.push(`/overview/flat-owner/detail/${item.id}`)
                }
                color='primary'
                className='w-100'>
                {t('common.edit')}
              </Button>
            )
          }
        })
      )

      if (searchQuery === '') {
        setTotalOwnersPages(Math.ceil(response.data.data.recordsTotal / limit))
      } else {
        setTotalOwnersPages(
          Math.ceil(response.data.data.recordsFiltered / limit)
        )
      }
    } catch (error) {
      console.log(error)
    }
    setLoading(false)
  }

  useEffect(() => {
    if (svjVId !== undefined) {
      setApiHeaders({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'svj-id': svjVId,
        'Authorization': 'Bearer ' + localStorage.getItem('authToken')
      })
    }
    if (period !== CUSTOM_PERIOD) {
      if (sortValue && sort !== '') fetchData(true, sortTable[sortValue])
      else fetchData()
      // getSvj()
      getSvjAddress()
    }

    if (period === CUSTOM_PERIOD && startDateRange && endDateRange) {
      if (sortValue && sort !== '')
        fetchData(true, sortTable[sortValue], startDateRange, endDateRange)
      else fetchData(false, false, startDateRange, endDateRange)
      // getSvj()
      getSvjAddress()
    }
  }, [page, limit, period, t, sort, searchQuery])

  useEffect(() => {
    // TODO: Refactor header settings and stop repeating same code
    if (svjVId !== undefined) {
      setApiHeaders({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'svj-id': svjVId,
        'Authorization': 'Bearer ' + localStorage.getItem('authToken')
      })
    }
    if (period !== CUSTOM_PERIOD) {
      getChartsData()
    }
  }, [page, limit, period])

  const handleSetLimit = limit => {
    setLimit(limit)
  }

  const handleSetPage = page => {
    setPage(page)
  }
  const handleChange = event => {
    setCsv(event.target.files)
  }

  const rightBlock = (
    <Row className='mr-1'>
      <SearchFiled
        filterValue={filterValue}
        setFilterValue={setFilterValue}
        setSearchQuery={setSearchQuery}
      />
      <Col className='d-flex align-items-center'>
        <Button
          color='primary'
          className='mt-0 d-flex align-items-center'
          onClick={() => setUploadModal(!uploadModal)}>
          <LogoCloudUpload className='mr-1 SVGLogo' />
          {t('common.importData')}
        </Button>
        <HelpMenu />
      </Col>
      <DateRangerWithPicker
        sortValue={sortValue}
        sort={sort}
        sortTable={sortTable}
        period={period}
        placeholder={placeholder}
        getChartsData={getChartsData}
        fetchData={fetchData}
        startDateRange={startDateRange}
        endDateRange={endDateRange}
        setPeriod={setPeriod}
        setStartDateRange={setStartDateRange}
        setEndDateRange={setEndDateRange}
        setPlaceholder={setPlaceholder}
      />
      {!svjVId && <Button color='primary'>{t('common.export')}</Button>}
    </Row>
  )

  return (
    <>
      <PageTitleAlt
        heading={
          svj &&
          `${t('common.installationDetails')} ${
            svjTitleAddress ? ` - ${svjTitleAddress}` : ` - ${svj.name}`
          }`
        }
        icon={
          <LogoAnalistics className='SVGLogo' style={{ fill: '#343a40' }} />
        }
        rightBlock={rightBlock}
        hint={t('common.dataHint')}
      />
      <HouseOwnersTable
        lastPage={lastPage}
        svjId={svjVId}
        data={data || []}
        limit={limit}
        loading={loading}
        chartsLoading={chartsLoading}
        page={page}
        totalOwnersPages={totalOwnersPages}
        onSetLimit={handleSetLimit}
        onSetPage={handleSetPage}
        chartsData={chartsData}
        fetchData={fetchData}
        setSortValue={setSortValue}
        sort={sort}
        setSort={setSort}
        sortValue={sortValue}
        sortValues={sortTable}
      />

      <Modal isOpen={uploadModal} toggle={() => setUploadModal(!uploadModal)}>
        <ModalHeader toggle={() => setUploadModal(!uploadModal)}>
          <Col className='d-flex'>
            {t('common.importData')}
            <HelpMenu />
          </Col>
        </ModalHeader>
        <ModalBody>
          <DatePicker
            selected={dateScv}
            onChange={date => setDateScv(date)}
            dayClassName={() => 'select-date'}
            locale={i18n.language}
            customInput={<Input />}
            className='datepicker'
            placeholderText={placeholder}
            isClearable
            filterDate={d => {
              return new Date() > d
            }}
            dateFormat='dd.MM.yyyy'
          />
          <Input
            className='mt-2'
            type='file'
            name='file'
            label='Upload CSV'
            labelPosition='right'
            placeholder='UploadCSV...'
            onChange={handleChange}
          />
        </ModalBody>
        <ModalFooter>
          <Button color='danger' onClick={() => setUploadModal(!uploadModal)}>
            {t('common.cancel')}
          </Button>{' '}
          <Button
            color='secondary'
            disabled={csvLoading}
            onClick={() => handleUploadCSV()}>
            {t('common.importData')}
          </Button>
          {csvLoading && <Spinner size='sm' color='secondary' />}
        </ModalFooter>
      </Modal>
    </>
  )
}

export default HouseOwnersAssociationDetailsView
