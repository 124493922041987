import { Button } from 'reactstrap'
import { connect } from 'react-redux'
import { NavLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { withRouter } from 'react-router-dom'
import React, { Fragment, useContext } from 'react';
import { ReactComponent as LogoAlbums } from '../../assets/images/LogoComponents/ios-albums.svg';
import { ReactComponent as LogoBuffer } from '../../assets/images/LogoComponents/logo-buffer.svg';
import { ReactComponent as LogoContacts } from '../../assets/images/LogoComponents/ios-contacts.svg';
import { ReactComponent as LogoInfo } from '../../assets/images/LogoComponents/ios-information.svg';
import { ReactComponent as LogoCog } from '../../assets/images/LogoComponents/ios-cog.svg';
import { setEnableMobileMenu } from '../../reducers/ThemeOptions'
import UserContext from '../../contexts/authContext'

const Nav = props => {
  const { t } = useTranslation()
  const { user } = useContext(UserContext)

  const FlatOwnerMenu = [
    {
      icon: <LogoAlbums className="SVGLogo" style={{ marginRight: "8px" }} />,
      label: t('navigation.dashboard'),
      to: '/dashboard'
    },
    {
      icon: <LogoCog className="SVGLogo" style={{ marginRight: "8px" }} />,
      label: t('navigation.settings'),
      to: '/profile'
    }
  ]

  const SVJMenu = [
    {
      icon: <LogoAlbums className="SVGLogo" style={{ marginRight: "8px" }} />,
      label: t('navigation.dashboard'),
      to: '/dashboard'
    },
    {
      icon: <LogoCog className="SVGLogo" style={{ marginRight: "8px" }} />,
      label: t('navigation.settings'),
      to: '/profile'
    }
  ]

  const InstallerMenu = [
    {
      icon: <LogoAlbums className="SVGLogo" style={{ marginRight: "8px" }} />,
      label: t('navigation.overviewOfCustomers'),
      to: '/dashboard'
    },
    {
      icon: <LogoBuffer className="SVGLogo" style={{ marginRight: "8px" }} />,
      label: t('navigation.listOfDevices'),
      to: `/list-of-devices`
    },
    {
      icon: <LogoContacts className="SVGLogo" style={{ marginRight: "8px" }} />,
      label: t('navigation.listOfFlatOwners'),
      to: `/owners`
    },
    {
      icon: <LogoInfo className="SVGLogo" style={{ marginRight: "8px" }} />,
      label: t('navigation.deviceNotifications'),
      to: `/device-notifications`
    },
    {
      icon: <LogoCog className="SVGLogo" style={{ marginRight: "8px" }} />,
      label: t('navigation.settings'),
      to: '/profile'
    }
  ]

  let menu = []
  switch (user.role_id) {
    case 2:
      menu = FlatOwnerMenu
      break
    case 3:
      menu = SVJMenu
      break
    case 4:
      menu = InstallerMenu
      break
    default:
      break
  }

  const toggleEnableMobileMenu = () => {
    let { enableMobileMenu, setEnableMobileMenu } = props
    setEnableMobileMenu(!enableMobileMenu)
  }

  return (
    <Fragment>
      <h5 className='app-sidebar__heading text-dark mb-4 pt-3'>
        {t('common.menu')}
      </h5>

      <div className='d-flex flex-column'>
        {menu.map((item, i) => {
          return (
            <NavLink
              key={i}
              to={item.to}
              className='vertical-nav-menu w-100 mb-2 text-decoration-none'>
              <Button
                style={{ display: 'flex', }}
                onClick={toggleEnableMobileMenu}
                size='lg'
                color='light'
                className='sidebar-menu-item btn border-0 w-100 text-left d-flex align-items-center'>
                {item.icon}
                {<span>{item.label}</span>}
              </Button>
            </NavLink>
          )
        })}
      </div>
    </Fragment>
  )
}

const mapStateToProps = state => ({
  enableMobileMenu: state.ThemeOptions.enableMobileMenu
})

const mapDispatchToProps = dispatch => ({
  setEnableMobileMenu: enable => dispatch(setEnableMobileMenu(enable))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Nav))
