import React, { Fragment } from 'react'
import logo from '../../assets/utils/images/creative-logo.svg'

class AppFooter extends React.Component {
  render() {
    return (
      <Fragment>
        <div className='app-footer'>
          <div className='app-footer__inner'>
            <div className='app-footer-left' />
            <div className='app-footer-right'>
              <div
                className='menu-header-image mt-2'
                style={{
                  backgroundImage: 'url(' + logo + ')',
                  backgroundSize: 'contain',
                  backgroundRepeat: 'no-repeat',
                  width: '140px',
                  height: '50px'
                }}
              />
            </div>
          </div>
        </div>
      </Fragment>
    )
  }
}

export default AppFooter
