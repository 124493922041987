import { Row, Col, Card, CardBody } from 'reactstrap'
import { useTranslation } from 'react-i18next'
import Loader from 'react-loaders'
import React, { Fragment } from 'react'
import 'react-table-hoc-fixed-columns/lib/styles.css'
import { formatUnits } from '../../../utils/index'
import { DynamicTable } from '../Tables/DynamicTable'

const HouseOwnersTable = ({
  chartsLoading,
  data,
  svjId,
  chartsData,
  rowLimit,
  setSort,
  fetchData,
  ...props
}) => {
  const { t } = useTranslation()
  const columns = [
    {
      Header: t('common.address'),
      accessor: 'address'
    },
    {
      Header: t('common.entrance'),
      accessor: 'entrance',
      width: 80,
      Cell: ({ value }) => {
        return <div style={{ textAlign: 'center', width: '100%' }}>{value}</div>
      }
    },
    {
      Header: t('common.flat'),
      accessor: 'floor_flat',
      width: 175,
      Cell: ({ value }) => {
        return <div style={{ textAlign: 'center', width: '100%' }}>{value}</div>
      }
    },
    {
      Header: t('common.name'),
      accessor: 'name',
      Cell: ({ value }) => {
        return <div style={{ textAlign: 'center', width: '100%' }}>{value}</div>
      }
    },
    {
      Header: t('common.surname'),
      accessor: 'surname',
      Cell: ({ value }) => {
        return <div style={{ textAlign: 'center', width: '100%' }}>{value}</div>
      }
    },
    {
      Header: t('common.coldWaterSpent'),
      accessor: 'coldWaterSpent',
      maxWidth: 120,
      Cell: ({ value }) => {
        return <div style={{ textAlign: 'right', width: '100%' }}>{value}</div>
      }
    },
    {
      Header: t('common.hotWaterSpent'),
      accessor: 'hotWaterSpent',
      maxWidth: 120,
      Cell: ({ value }) => {
        return <div style={{ textAlign: 'right', width: '100%' }}>{value}</div>
      }
    },
    {
      Header: t('common.heatSpent'),
      accessor: 'heatSpent',
      maxWidth: 120,
      Cell: ({ value }) => {
        return <div style={{ textAlign: 'right', width: '100%' }}>{value}</div>
      }
    },
    {
      Header: t('common.action'),
      accessor: 'action',
      maxWidth: 140
    }
  ]

  return (
    <Fragment>
      <Card className='main-card mb-3'>
        <Row className='no-gutters'>
          <Col sm='3'>
            <div className='widget-chart'>
              <div className='icon-wrapper rounded-circle'>
                <div className='icon-wrapper-bg bg-primary' />
                <i className='lnr-apartment text-primary' />
              </div>
              <div className='widget-numbers font-size-xlg'>
                {chartsLoading ? (
                  <Loader type='ball-beat' innerClassName='height-25' />
                ) : (
                  chartsData.total_number_of_flats
                )}
              </div>
              <div className='widget-description text-success'>
                <span className='pl-1' style={{ color: '#545cd8' }}>
                  {t('common.totalNumberOfFlats')}
                </span>
              </div>
            </div>
          </Col>
          <Col sm='3'>
            <div className='widget-chart'>
              <div className='icon-wrapper rounded-circle'>
                <div className='icon-wrapper-bg bg-info' />
                <i className='lnr-drop text-info' />
              </div>
              <div className='widget-numbers font-size-xlg'>
                {chartsLoading ? (
                  <Loader type='ball-beat' innerClassName='height-25' />
                ) : (
                  <React.Fragment>
                    {chartsData.total_cold_water_spent}
                    <span className='font-size-md'>
                      {' '}
                      {formatUnits(chartsData.total_cold_water_spent_unit)}
                    </span>
                  </React.Fragment>
                )}
              </div>
              <div className='widget-description text-info'>
                <span className='pl-1'>{t('common.totalColdWaterSpent')}</span>
              </div>
            </div>
          </Col>
          <Col sm='3'>
            <div className='widget-chart'>
              <div className='icon-wrapper rounded-circle'>
                <div className='icon-wrapper-bg bg-danger' />
                <i className='lnr-drop text-danger' />
              </div>
              <div className='widget-numbers font-size-xlg'>
                {chartsLoading ? (
                  <Loader type='ball-beat' innerClassName='height-25' />
                ) : (
                  <React.Fragment>
                    {chartsData.total_hot_water_spent}
                    <span className='font-size-md'>
                      {' '}
                      {formatUnits(chartsData.total_hot_water_spent_unit)}
                    </span>
                  </React.Fragment>
                )}
              </div>
              <div className='widget-description text-warning'>
                <span className='pr-1' style={{ color: '#d92550' }}>
                  {t('common.totalHotWaterSpent')}
                </span>
              </div>
            </div>
          </Col>
          <Col sm='3'>
            <div className='widget-chart'>
              <div className='icon-wrapper rounded-circle'>
                <div className='icon-wrapper-bg bg-warning' />
                <i className='lnr-database text-warning' />
              </div>
              <div className='widget-numbers font-size-xlg'>
                {chartsLoading ? (
                  <Loader type='ball-beat' innerClassName='height-25' />
                ) : (
                  <React.Fragment>
                    {chartsData?.total_heater_spent &&
-                    chartsData?.total_heater_spent !== 'N/A'
                      ? chartsData.total_heater_spent
                      : chartsData?.total_heat_spent}
                    <span className='font-size-md'>
                      {' '}
                      {formatUnits(
                        chartsData?.total_heater_spent_unit &&
                          chartsData?.total_heater_spent_unit !== 'N/A'
                          ? chartsData.total_heater_spent_unit
                          : chartsData?.total_heat_spent_unit
                      )}
                    </span>
                  </React.Fragment>
                )}
              </div>
              <div className='widget-description text-primary'>
                <span className='pl-1' style={{ color: '#f7b924' }}>
                  {t('common.totalHeatWaterSpent')}
                </span>
              </div>
            </div>
          </Col>
        </Row>
      </Card>

      <Row>
        <Col md='12'>
          <Card className='main-card mb-3'>
            <CardBody>
              <DynamicTable
                data={data}
                columns={columns}
                loading={props.loading}
                page={props.page}
                limit={props.limit}
                setPage={props.onSetPage}
                setLimit={props.onSetLimit}
                lastPage={props.lastPage}
                setSortValue={props.setSortValue}
                sort={props.sort}
                setSort={setSort}
                sortValue={props.sortValue}
                sortValues={props.sortValues}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Fragment>
  )
}

export default HouseOwnersTable
