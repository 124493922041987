import React, { useState, createContext } from 'react'

export const BreadcrumbContext = createContext()

export const BreadcrumbProvider = ({ children }) => {
  const [sessionParams, setSessionParams] = useState({
    buildingId: 1,
    flatOwnerId: 1,
    svjId: 1,
    svjVId: 1,
    flatId: 1
  })
  return (
    <BreadcrumbContext.Provider
      value={{
        sessionParams,
        setSessionParams
      }}>
      {children}
    </BreadcrumbContext.Provider>
  )
}
