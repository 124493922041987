import { NotificationManager } from 'react-notifications'
import { useTranslation } from 'react-i18next'
import axios from 'axios'
import * as querystring from 'querystring'
import React, { Fragment, useEffect, useState } from 'react'
import { ReactComponent as LogoCog } from '../../../assets/images/LogoComponents/ios-cog.svg'

import DataForm from './DataForm'
import PageTitleAlt from '../../../Layout/AppMain/PageTitleAlt'
import { Button, Modal, ModalBody } from 'reactstrap'

const CompanyDetails = ({ location, history }) => {
  let qs = location.search.replace('?', '')
  const { isFirst } = querystring.parse(qs)
  const { t, i18n } = useTranslation()
  const [passwords, setPasswords] = useState({
    current_password: '',
    new_password: '',
    new_password_confirmation: ''
  })
  const [open, setOpen] = useState(false)
  const [company, setCompany] = useState({})
  const [loading, setLoading] = useState(false)
  const [submitting, setSubmitting] = useState(false)
  const [passwordSubmitting, setPasswordSubmitting] = useState(false)

  useEffect(() => {
    if (isFirst) {
      setOpen(true)
    }
    const fetchData = async () => {
      setLoading(true)
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/v1/users/profile`,
        {
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('authToken'),
            'Content-Language': i18n.language === 'cz' ? 'cs' : 'en'
          }
        }
      )
      const userData = response.data.data
      setCompany(userData)
      setLoading(false)
    }
    fetchData()
  }, [])

  const onHandleFormSubmit = async event => {
    event.preventDefault()
    setSubmitting(true)
    const response = await axios.put(
      `${process.env.REACT_APP_API_URL}/api/v1/users/profile`,
      { ...company },
      {
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': 'Bearer ' + localStorage.getItem('authToken'),
          'Content-Language': i18n.language === 'cz' ? 'cs' : 'en'
        }
      }
    )
    setCompany(response.data.data)
    setSubmitting(false)
    NotificationManager.success(t('common.success'))
  }

  const onHandleChangePasswordSubmit = async event => {
    event.preventDefault()
    setPasswordSubmitting(true)
    try {
      await axios.post(
        `${process.env.REACT_APP_API_URL}/api/v1/password/change-password`,
        { ...passwords },
        {
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('authToken'),
            'Content-Language': i18n.language === 'cz' ? 'cs' : 'en'
          }
        }
      )
      NotificationManager.success(t('common.success'))
      setPasswordSubmitting(false)
    } catch (error) {
      setPasswordSubmitting(false)
    }
  }

  const onHandleChangePasswords = e => {
    setPasswords({ ...passwords, [e.target.name]: e.target.value })
  }

  const onHandleChangeCompany = e => {
    let urlPattern = /^((http|https|ftp):\/\/)/
    let url = null

    if (e.target.name === 'website' && !urlPattern.test(e.target.value)) {
      url = 'https://' + e.target.value
      setCompany({ ...company, [e.target.name]: url })
    } else {
      setCompany({ ...company, [e.target.name]: e.target.value })
    }
  }

  const handleModalClick = () => {
    setOpen(false)
    history.replace('/profile')
  }

  return (
    <Fragment>
      <Modal
        size='md'
        style={{ boxShadow: 'none' }}
        isOpen={open}
        centered
        toggle={() => setOpen(false)}>
        <ModalBody className='p-4'>
          {t('common.resetPasswordNotification')}
          <Button
            className='w-100 mt-3'
            color='primary'
            onClick={handleModalClick}>
            {t('common.iUnderstand')}
          </Button>
        </ModalBody>
      </Modal>
      <PageTitleAlt
        heading={t('pageTitles.settings')}
        icon={<LogoCog className='SVGLogo' style={{ fill: '#343a40' }} />}
      />
      <DataForm
        loading={loading}
        submitting={submitting}
        passwordSubmitting={passwordSubmitting}
        company={company}
        setUset={setCompany}
        passwords={passwords}
        onHandleFormSubmit={onHandleFormSubmit}
        onHandleChangePasswordSubmit={onHandleChangePasswordSubmit}
        onHandleChangePasswords={onHandleChangePasswords}
        onHandleChangeCompany={onHandleChangeCompany}
      />
    </Fragment>
  )
}

export default CompanyDetails
