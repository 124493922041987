import { Row } from 'reactstrap'
import { useTranslation } from 'react-i18next'
import Loader from 'react-loaders'
import React, { Fragment } from 'react'

import BarChartComponent from '../../components/BarChart'
import StatisticCard from '../../components/StatisticCard'
import { formatUnits } from '../../../utils/index'

const HOT_WATER = 'hot_water'
const COLD_WATER = 'cold_water'
const HEATER = 'heaters'

const Dashboard = ({
  allStats,
  comparisonStats,
  loading,
  maxValue,
  originalStatsValues,
  isFilterEnabled
}) => {
  const { t } = useTranslation()

  // If any of the periods don't have any values for the current year or
  // previous year, then hide the chart as a whole
  const shouldChartBeDisplayed = stats => {
    if (!stats || stats.length === 0) {
      return false
    }

    const currentDate = new Date()
    const currentYear = currentDate.getFullYear()
    const prevYear = currentYear - 1

    const hasValue = stats.find(stat => {
      const hasYearValue =
        stat[currentYear.toString()] || stat[prevYear.toString()]
      return hasYearValue
    })
    return hasValue
  }

  return (
    <Fragment>
      {loading && (
        <div className='loader-wrapper w-100 d-flex justify-content-center align-items-center'>
          <Loader type='ball-beat' />
        </div>
      )}

      {!loading && (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <Row
            className='water-spent-row'
            style={{ display: 'flex', justifyContent: 'space-around' }}>
            {allStats
              .filter(stat => stat.device_type !== HEATER)
              .map((stat, i) => {
                let heading, borderStyle, iconStyle
                const subheading = `${t(
                  'common.fabrication_no'
                )} - ${stat.fabrication_number || ''}`

                if (stat.device_type === COLD_WATER) {
                  heading = 'common.coldWaterSpent'
                  borderStyle = 'border-info'
                  iconStyle = 'text-info'
                }

                if (stat.device_type === HOT_WATER) {
                  heading = 'common.hotWaterSpent'
                  borderStyle = 'border-danger'
                  iconStyle = 'text-danger'
                }

                return (
                  <StatisticCard
                    key={i}
                    title={t(heading)}
                    value={stat.last_reading || ''}
                    label={stat.measure_unit || ''}
                    borderStyle={borderStyle}
                    iconStyle={iconStyle}
                    subheading={subheading}
                    isFilterEnabled={isFilterEnabled}
                    originalValue={originalStatsValues[i].current_device_figure}
                    lastReadingText={t('common.lastReading')}
                  />
                )
              })}
          </Row>

          <Row
            className='heat-spent-row'
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              flexWrap: 'wrap'
            }}>
            {allStats
              .filter(
                stat =>
                  stat.device_type !== COLD_WATER &&
                  stat.device_type !== HOT_WATER
              )
              .map((stat, i) => {
                let heading, borderStyle, iconStyle
                const subheading = `${t(
                  'common.fabrication_no'
                )} - ${stat.fabrication_number || ''}`

                if (stat.device_type === HEATER) {
                  heading = 'common.heatSpent'
                  borderStyle = 'border-warning'
                  iconStyle = 'text-dark'
                }

                return (
                  <StatisticCard
                    key={i}
                    style={{ minWidth: 170, height: '100%', display: 'flex' }}
                    title={t(heading)}
                    value={parseInt(stat.last_reading) || '0'}
                    label={stat.measure_unit || ''}
                    borderStyle={borderStyle}
                    iconStyle={iconStyle}
                    subheading={subheading}
                  />
                )
              })}
          </Row>

          {shouldChartBeDisplayed(comparisonStats.cold_water_comparison) && (
            <BarChartComponent
              maxValue={maxValue}
              data={comparisonStats.cold_water_comparison}
              title={`${t('common.coldWaterSpent')} (${formatUnits('m3')})`}
              color2='#1da1fc'
              color1='#ff8745'
            />
          )}

          {shouldChartBeDisplayed(comparisonStats.hot_water_comparison) && (
            <BarChartComponent
              maxValue={maxValue}
              data={comparisonStats.hot_water_comparison}
              title={`${t('common.hotWaterSpent')} (${formatUnits('m3')})`}
              color2='#d5214e'
              color1='#ff8745'
            />
          )}

          {shouldChartBeDisplayed(comparisonStats.heat_comparison) && (
            <BarChartComponent
              data={comparisonStats.heat_comparison}
              title={`${t('common.heatSpent')} (${formatUnits('.d')})`}
              color2='#f4b102'
              color1='#ff8745'
            />
          )}
        </div>
      )}
    </Fragment>
  )
}

export default Dashboard
