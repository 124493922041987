import { Button, Row } from 'reactstrap'
import { useHistory, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import axios from 'axios'
import React, { useContext, useEffect, useState } from 'react'
import moment from 'moment'

import DateRangerWithPicker from '../../components/DateRangerWithPicker'
import HouseOwnersTable from '../../components/HouseOwnersTable/HouseOwnersTable'
import PageTitleAlt from '../../../Layout/AppMain/PageTitleAlt'
import UserContext from './../../../contexts/authContext'
import { formatUnits } from '../../../utils/index'
import { SearchFiled } from '../../components/SearchField'
import { ReactComponent as LogoAlbums } from '../../../assets/images/LogoComponents/ios-albums.svg'
import { CUSTOM_PERIOD } from '../../../constants'
import Dashboard2ExportModal from '../../components/Dashboard2ExportModal'

const sortTable = {
  name: 'name',
  surname: 'surname',
  entrance: 'entrance',
  floor_flat: 'floor_flat',
  heatSpent: 'heat_spend',
  coldWaterSpent: 'cold_water_spend',
  hotWaterSpent: 'hot_water_spend'
}

const Dashboard2 = () => {
  const history = useHistory()
  const { user } = useContext(UserContext)
  const { t, i18n } = useTranslation()
  const { id } = useParams()
  const [filterValue, setFilterValue] = useState('')
  const [searchQuery, setSearchQuery] = useState('')
  const [lastPage, setLastPage] = useState(1)
  const [loading, setLoading] = useState(false)
  const [loadingDownloadFile, setLoadingDownloadFile] = useState(false)
  const [chartsLoading, setChartsLoading] = useState(true)
  const [limit, setLimit] = useState(10)
  const [updatedRowLimit, setUpdatedRowLimit] = useState()
  const [page, setPage] = useState(1)
  const [data, setData] = useState([])
  const [totalOwnersPages, setTotalOwnersPages] = useState(0)
  const [period, setPeriod] = useState('yearly')
  const [sortValue, setSortValue] = useState('')
  const [exportModal, setExportModal] = useState(false)
  const [optionsBuildings, setOptionsBuildings] = useState([])
  const [buildingId, setBuildingId] = useState(null)
  const [currentFileType, setCurrentFileType] = useState('csv')
  const [startDateRange, setStartDateRange] = useState()
  const [endDateRange, setEndDateRange] = useState(null)
  const [placeholder, setPlaceholder] = useState('')
  const [startDateRangeModal, setStartDateRangeModal] = useState()
  const [endDateRangeModal, setEndDateRangeModal] = useState(null)
  const [dateRangeModal, setDateRangeModal] = useState('')

  const [chartsData, setChartsData] = useState({
    total_number_of_flats: null,
    total_cold_water_spent: null,
    total_hot_water_spent: null,
    total_heat_spent: null
  })
  const fileType = [
    { value: 'csv', label: 'csv' },
    { value: 'pdf', label: 'pdf' },
    { value: 'xls', label: 'xls' }
  ]
  const [apiHeaders, setApiHeaders] = useState({
    'Content-Type': 'application/json',
    'Accept': 'application/json',
    'svj-id': user.svj_header_id,
    'Authorization': 'Bearer ' + localStorage.getItem('authToken'),
    'Content-Language': i18n.language === 'cz' ? 'cs' : 'en'
  })
  const [sort, setSort] = useState('asc')
  const fetchData = async (
    sortCol = false,
    col,
    startDateFetch,
    endDateFetch
  ) => {
    setLoading(true)
    try {
      let response = null
      let sortQuery = ''
      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/v1/get-all-buildings-by-svj/${user.svj_header_id}`
      )
      setOptionsBuildings(
        data.data.map(item => {
          return { value: item.id, label: item.address }
        })
      )

      let dateQuery = `period=${period}`
      if (startDateFetch && endDateFetch && period === CUSTOM_PERIOD) {
        const start = moment(`${startDateFetch}`).format('YYYY-MM-DD')
        const end = moment(`${endDateFetch}`).format('YYYY-MM-DD')
        dateQuery += `&from_date=${start}&to_date=${end}`
      }

      if (sortCol) {
        sortQuery = `&order[0][column]=${col}&order[0][dir]=${sort}`
      }

      if (searchQuery === '') {
        response = await axios.get(
          `${
            process.env.REACT_APP_API_URL
          }/api/v1/flat-owners-with-readings-grid?${dateQuery}&length=${limit}&start=${page *
            limit}${sortQuery}&page=${page}`,
          {
            headers: apiHeaders
          }
        )
      } else {
        response = await axios.get(
          `${
            process.env.REACT_APP_API_URL
          }/api/v1/flat-owners-with-readings-grid?${dateQuery}&length=${limit}&start=${page *
            limit}&${sortQuery}&search[value]=${searchQuery}&page=${page}`,
          {
            headers: apiHeaders
          }
        )
      }
      setLastPage(response.data.data.last_page)
      const periodValue = `${period}_totals`
      setData(
        response.data.data.data.map(item => {
          let coldWaterUsage = '0'
          let hotWaterUsage = '0'
          let heatUsage = '0'

          if (period === CUSTOM_PERIOD) {
            coldWaterUsage = `${item[periodValue].cold_water_spend} ${item[periodValue].cold_water_spend_unit}`
            hotWaterUsage = `${item[periodValue].hot_water_spend} ${item[periodValue].hot_water_spend_unit}`
            heatUsage = `${item[periodValue].heat_spend} ${item[periodValue].heat_spend_unit}`
          } else if (item[periodValue].length !== 0) {
            coldWaterUsage = `${item[periodValue][0].cold_water_spend} ${item[periodValue][0].cold_water_spend_unit}`
            hotWaterUsage = `${item[periodValue][0].hot_water_spend} ${item[periodValue][0].hot_water_spend_unit}`
            heatUsage = `${item[periodValue][0].heat_spend} ${item[periodValue][0].heat_spend_unit}`
          }

          return {
            ...item,
            coldWaterSpent: formatUnits(coldWaterUsage),
            hotWaterSpent: formatUnits(hotWaterUsage),
            heatSpent: formatUnits(heatUsage),
            action: (
              <Button
                onClick={() =>
                  history.push(`/owners/flat-owner/detail/${item.id}`)
                }
                color='primary'
                className='w-100'>
                {t('common.ownerDetail')}
              </Button>
            )
          }
        })
      )

      if (searchQuery === '') {
        setTotalOwnersPages(Math.ceil(response.data.data.recordsTotal / limit))
        setUpdatedRowLimit(response.data.data.recordsTotal)
      } else {
        setTotalOwnersPages(
          Math.ceil(response.data.data.recordsFiltered / limit)
        )
        setUpdatedRowLimit(response.data.data.recordsFiltered)
      }
    } catch (error) {
      console.log(error)
    }
    setLoading(false)
  }

  const calculateRange = (startDate, endDate) => {
    const start = moment(`${startDate}`).format('YYYY-MM-DD')
    const end = moment(`${endDate}`).format('YYYY-MM-DD')
    return `&from_date=${start}&to_date=${end}`
  }

  const getChartsData = async (startDateCharts, endDateCharts) => {
    setChartsLoading(true)

    const range =
      period === 'custom' && startDateCharts && endDateCharts
        ? calculateRange(startDateCharts, endDateCharts)
        : ''

    try {
      let response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/v1/get-all-stats?period=${period}${range}`,
        {
          headers: apiHeaders
        }
      )
      setChartsData(response.data.data)
      setChartsLoading(false)
    } catch (error) {
      setChartsLoading(false)
    }
  }

  useEffect(() => {
    if (id !== undefined) {
      setApiHeaders({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'svj-id': id ? id : user.user.id,
        'Authorization': 'Bearer ' + localStorage.getItem('authToken')
      })
    }

    if (period !== CUSTOM_PERIOD) {
      if (sortValue && sort !== '') fetchData(true, sortTable[sortValue])
      else fetchData()
    }

    if (period === CUSTOM_PERIOD && startDateRange && endDateRange) {
      if (sortValue && sort !== '')
        fetchData(true, sortTable[sortValue], startDateRange, endDateRange)
      else fetchData(false, false, startDateRange, endDateRange)
    }
  }, [page, limit, period, t, sort, searchQuery])

  useEffect(() => {
    // TODO: Refactor header settings and stop repeating same code
    if (id !== undefined) {
      setApiHeaders({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'svj-id': id ? id : user.user.id,
        'Authorization': 'Bearer ' + localStorage.getItem('authToken')
      })
    }
    getChartsData()
  }, [page, limit, period])

  const handleSetLimit = limit => {
    setLimit(limit)
  }

  const handleSetPage = page => {
    setPage(page)
  }

  const handleDowloadFile = async () => {
    setLoadingDownloadFile(true)
    let start, end
    if (startDateRangeModal && endDateRangeModal) {
      start = moment(`${startDateRangeModal}`).format('YYYY-MM-DD')
      end = moment(`${endDateRangeModal}`).format('YYYY-MM-DD')
    }
    const config = {
      method: 'get',
      url: `${process.env.REACT_APP_API_URL}/api/v1/generate-reports`,
      params: {
        customer: user.svj_committee_id,
        installation: buildingId,
        file_type: currentFileType,
        return_type: 'file_path',
        from: start,
        to: end
      }
    }
    const response = await axios(config)
    if (response) {
      const a = document.createElement('a')
      a.href = `${process.env.REACT_APP_API_URL}/${response.data.file_path}`
      a.download = `${process.env.REACT_APP_API_URL}/${response.data.file_path}`
      a.target = '_blank'
      document.body.appendChild(a)
      a.click()
      a.remove()
      window.URL.revokeObjectURL(
        `${process.env.REACT_APP_API_URL}/${response.file_path}`
      )
    }
    setLoadingDownloadFile(false)
  }

  const toggleExportModal = () => {
    setBuildingId(null)
    setExportModal(!exportModal)
  }

  const rightBlock = (
    <Row>
      <SearchFiled
        filterValue={filterValue}
        setFilterValue={setFilterValue}
        setSearchQuery={setSearchQuery}
      />
      <DateRangerWithPicker
        sortValue={sortValue}
        sort={sort}
        sortTable={sortTable}
        period={period}
        placeholder={placeholder}
        getChartsData={getChartsData}
        fetchData={fetchData}
        startDateRange={startDateRange}
        endDateRange={endDateRange}
        setPeriod={setPeriod}
        setStartDateRange={setStartDateRange}
        setEndDateRange={setEndDateRange}
        setPlaceholder={setPlaceholder}
      />
      {!id && (
        <Button
          color='primary'
          className='mr-2 ml-2 mb-2'
          size='lg'
          style={{ marginTop: '2px' }}
          onClick={() => toggleExportModal()}>
          {t('common.export')}
        </Button>
      )}
    </Row>
  )

  return (
    <>
      <PageTitleAlt
        heading={t('pageTitles.dashboard')}
        icon={<LogoAlbums className='SVGLogo' style={{ fill: '#343a40' }} />}
        rightBlock={rightBlock}
        hint={t('common.dataHint')}
      />

      <HouseOwnersTable
        lastPage={lastPage}
        chartsLoading={chartsLoading}
        chartsData={chartsData}
        data={data}
        limit={limit}
        rowLimit={updatedRowLimit}
        loading={loading}
        page={page}
        totalOwnersPages={totalOwnersPages}
        onSetLimit={handleSetLimit}
        onSetPage={handleSetPage}
        fetchData={fetchData}
        setSortValue={setSortValue}
        sort={sort}
        setSort={setSort}
        sortValue={sortValue}
        sortValues={sortTable}
      />
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Dashboard2ExportModal
          buildingId={buildingId}
          exportModal={exportModal}
          optionsBuildings={optionsBuildings}
          fileType={fileType}
          startDateRangeModal={startDateRangeModal}
          endDateRangeModal={endDateRangeModal}
          dateRangeModal={dateRangeModal}
          setBuildingId={setBuildingId}
          toggleExportModal={toggleExportModal}
          setCurrentFileType={setCurrentFileType}
          handleDowloadFile={handleDowloadFile}
          loadingDownloadFile={loadingDownloadFile}
          setStartDateRangeModal={setStartDateRangeModal}
          setEndDateRangeModal={setEndDateRangeModal}
          setDateRangeModal={setDateRangeModal}
        />
      </div>
    </>
  )
}

export default Dashboard2
