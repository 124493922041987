import { Card, CardBody, Col, Row, Badge } from 'reactstrap'
import { useTranslation } from 'react-i18next'
import React from 'react'
import { DynamicTable } from '../../components/Tables/DynamicTable'
import { ReactComponent as LogoInfo } from '../../../assets/images/LogoComponents/ios-information.svg'
import { ReactComponent as LogoCheckmarkCircle } from '../../../assets/images/LogoComponents/ios-checkmark-circle.svg'

const sortTable = {
  // device_serial_number: 'device_serial_number',
  // name_customer: 'name_customer',
  // error_translations: 'error_flag_decimal',
  installation_date: 'installation_date',
  next_change_of_the_device: 'next_change_of_the_device'
}

const ListOfDevices = ({ data, pageLoading, setSort, fetchData, ...props }) => {
  const { t, i18n } = useTranslation()

  const errorElement = (errorType, msg, i) => {
    let els = document.querySelectorAll('.rt-td-error')
    els.forEach(item => {
      item.classList.add('justify-content-start')
    })
    return (
      <div key={`${errorType}-${i}`}>
        <Badge
          className='mr-2'
          color='warning'
          pill
          style={{
            padding: '1px 9px 1px 2px',
            width: '65px',
            textAlign: 'left',
            backgroundColor:
              errorType === 'is' || errorType === 'je' ? '#ffbdcd' : '#ffedc3'
          }}>
          <LogoInfo
            style={{
              fill:
                errorType === 'is' || errorType === 'je' ? '#d92550' : '#f7b924'
            }}
            className='mr-1 SVGLogo'
          />
          {errorType}
        </Badge>

        <span>{msg}</span>
      </div>
    )
  }

  const renderErrors = ({ is, was }) => {
    let errorElements = []

    is.forEach((msg, i) => {
      if (i18n.language === 'cz') {
        errorElements.push(errorElement(t('common.is'), msg.cs, i))
      } else {
        errorElements.push(errorElement(t('common.is'), msg.en, i))
      }
    })

    was.forEach((msg, i) => {
      if (i18n.language === 'cz') {
        errorElements.push(errorElement(t('common.was'), msg.cs, i))
      } else {
        errorElements.push(errorElement(t('common.was'), msg.en, i))
      }
    })

    return errorElements
  }

  const columns = [
    {
      Header: t('common.serialNumber'),
      accessor: 'device_serial_number',
      filterable: false,
      maxWidth: 100,
      sortable: false,
      Cell: ({ value }) => {
        return <div style={{ textAlign: 'center', width: '100%' }}>{value}</div>
      }
    },
    {
      Header: t('common.condition'),
      accessor: 'error_translations',
      filterable: false,
      maxWidth: 400,
      width: 325,
      sortable: false,
      Cell: value => {
        if (!value.row.original.error_flag_decimal) {
          return (
            <div className='text-success btn-icon-horizontal btn-transition btn-block d-flex align-items-center'>
              <LogoCheckmarkCircle
                style={{ fill: '#3ac47d' }}
                className='mr-2 SVGLogo'
              />
              {t('common.normal')}
            </div>
          )
        } else {
          return (
            <div className='errorElementsList d-flex flex-column'>
              {renderErrors(value.row.original.error_translations)}
            </div>
          )
        }
      }
    },
    {
      Header: t('common.deviceAddress'),
      accessor: 'customer_address',
      filterable: false,
      sortable: false,
      Cell: ({ value }) => {
        return <div style={{ textAlign: 'center', width: '100%' }}>{value}</div>
      }
    },
    {
      Header: t('common.deviceCustomerName'),
      accessor: 'name_customer',
      filterMethod: (filter, row) =>
        row.name_customer.indexOf(filter.value) !== -1,
      filterable: false,
      sortable: false,
      maxWidth: 250,
      Cell: ({ value }) => {
        return <div style={{ textAlign: 'center', width: '100%' }}>{value}</div>
      }
    },
    {
      Header: t('common.dateOfInstallation'),
      accessor: 'installation_date',
      filterable: false,
      maxWidth: 200,
      Cell: ({ value }) => {
        return <div style={{ textAlign: 'center', width: '100%' }}>{value}</div>
      }
    },
    {
      Header: t('common.deviceNexChange'),
      accessor: 'next_change_of_the_device',
      filterable: false,
      maxWidth: 100,
      Cell: ({ value }) => {
        return <div style={{ textAlign: 'center', width: '100%' }}>{value}</div>
      }
    },
    {
      Header: t('common.action'),
      accessor: 'action',
      filterable: false,
      sortable: false,
      maxWidth: 150,
      Cell: ({ value }) => {
        return <div style={{ textAlign: 'center', width: '100%' }}>{value}</div>
      }
    }
  ]

  return (
    <>
      <Row>
        <Col md='12'>
          <Card className='main-card mb-3'>
            <CardBody>
              <DynamicTable
                data={data}
                columns={columns}
                loading={props.loading}
                limit={props.limit}
                page={props.page}
                setLimit={props.onSetLimit}
                setPage={props.onSetPage}
                lastPage={props.lastPage}
                setLastPage={props.setLastPage}
                setSortValue={props.setSortValue}
                sort={props.sort}
                setSort={setSort}
                sortValue={props.sortValue}
                sortValues={sortTable}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  )
}

export default ListOfDevices
