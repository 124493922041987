import { Col, Card } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { formatUnits } from '../../../utils/index'

const StatisticCard = ({
  title,
  value,
  icon,
  label,
  borderStyle,
  iconStyle,
  colMd,
  colXl,
  subheading,
  style,
  isFilterEnabled,
  originalValue,
  lastReadingText
}) => {
  // return conditional text for last reading value
  const getCardValue = () => {
    if (!isFilterEnabled) {
      return (
        <>
          {value}
          <small className='opacity-8 pl-1'>{formatUnits(label)}</small>
        </>
      )
    }
    return (
      <>
        {value}
        <small className='opacity-8 pl-1'> {formatUnits(label)} </small>
        <small className='opacity-8 pl-1'>
          ( {lastReadingText} - {originalValue} {formatUnits(label)})
        </small>
      </>
    )
  }

  return (
    <Col style={style} md={colMd} xl={colXl}>
      <Card
        className={
          'widget-chart widget-chart2 text-left mb-3 card-btm-border card-shadow-danger ' +
          borderStyle
        }>
        <div className='widget-chat-wrapper-outer'>
          <div className='widget-chart-content'>
            <div className='widget-title opacity-9 font-weight-bold text-md text-primary '>
              {title}
            </div>
            <div className='widget-numbers mt-2 fsize-4 mb-0 w-100'>
              <div className='widget-chart-flex align-items-center'>
                <div>
                  {icon && (
                    <span className={'opacity-10 pr-2 ' + iconStyle}>
                      <FontAwesomeIcon icon={icon} />
                    </span>
                  )}
                  {getCardValue()}
                </div>
              </div>
            </div>
            <div className={`widget-subheading opacity-8 pt-3 text-dark`}>
              {subheading}
            </div>
          </div>
        </div>
      </Card>
    </Col>
  )
}

export default StatisticCard
