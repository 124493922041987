import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { Input, Button } from 'reactstrap'
import { useTranslation } from 'react-i18next'

export const SearchFiled = ({ filterValue, setFilterValue, setSearchQuery }) => {

  const { t } = useTranslation()

  const handleSearchData = async e => {
    e.preventDefault()
    setSearchQuery(filterValue)
  }


  const onDeleteAll = e => {
    if (
      (e.key === 'Backspace' || e.key === 'Delete') &&
      e.target.value === ''
    ) {
      handleSearchData(e)
    }
  }

  return (
      <form
        onSubmit={handleSearchData}
        className='mr-4'
        style={{ display: 'flex', alignItems: 'center' }}>
        <Input
          type='text'
          style={{ position: "relative" }}
          value={filterValue}
          onKeyUp={onDeleteAll}
          onChange={({ target }) => setFilterValue(target.value)}
          className={'form-control mr-2'}
        />
        {!filterValue && <FontAwesomeIcon style={{ marginLeft: "1rem", position: "absolute" }} icon={faSearch} />}
        <Button type='submit' color='secondary' size='lg'>
          {t('formFields.search')}
        </Button>
      </form>
  )
}
