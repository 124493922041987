import React, { useState, createContext } from 'react'

export const OwnerContext = createContext()

export const OwnerProvider = ({ children }) => {
  // Context state
  const [owner, setOwner] = useState({user_details: {}, flat: { flat_history: {} }});
  const [loading, setLoading] = useState(false);
  const [deviceReadings, setDeviceReadings] = useState([]);
  const [submitting, setSubmitting] = useState(false);

  return (
    <OwnerContext.Provider
      value={{
        owner,
        setOwner,
        loading,
        setLoading,
        deviceReadings,
        setDeviceReadings,
        submitting,
        setSubmitting,
      }}
    >
      {children}
    </OwnerContext.Provider>
  );
};
