import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import { NotificationManager } from 'react-notifications'
import { useTranslation } from 'react-i18next'
import axios from 'axios'
import React from 'react'

const DeleteSVJModal = ({
  modal,
  setModal,
  selectedSVJ,
  fetchData,
  page,
  limit,
  allSVJs
}) => {
  const { t, i18n } = useTranslation()

  const handleDeleteSVJ = async () => {
    try {
      await axios.delete(
        `${process.env.REACT_APP_API_URL}/api/v1/svj-committees/${selectedSVJ}`,
        {
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('authToken'),
            'Content-Language': i18n.language === 'cz' ? 'cs' : 'en'
          }
        }
      )
      fetchData({ page, pageSize: limit })
      setModal(false)
      NotificationManager.success(t('common.success'))
    } catch (error) {
      console.error('Error removing customer: ', error)
    }
  }
  const oneSVJ = allSVJs.find(oneSVJ => selectedSVJ === oneSVJ.id)

  return (
    <Modal isOpen={modal} toggle={() => setModal(false)}>
      <ModalHeader toggle={() => setModal(false)}>
        {oneSVJ ? oneSVJ.name : ''}
      </ModalHeader>
      <ModalBody>{t('common.areYouSureYouWantToDelete')}</ModalBody>
      <ModalFooter>
        <Button color='danger' onClick={() => handleDeleteSVJ()}>
          {t('common.delete')}
        </Button>{' '}
        <Button color='secondary' onClick={() => setModal(false)}>
          {t('common.cancel')}
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default DeleteSVJModal
