import React, { useContext, useMemo } from 'react'
import { Breadcrumb, BreadcrumbItem, Col } from 'reactstrap'
import { useTranslation } from 'react-i18next'
import { Link, useParams, useRouteMatch } from 'react-router-dom'
import { OwnerContext, UserContext } from '../../../contexts'
import { BreadcrumbContext } from '../../../contexts'
import { breadCrumbsPaths } from '../../../helpers/breadCrumbsPaths'

function BreadcrumbsMapPaths({ userRole, user }) {
  const { owner } = useContext(OwnerContext)
  const { sessionParams, setSessionParams } = useContext(BreadcrumbContext)
  const params = useParams()
  const { t } = useTranslation()
  const { path } = useRouteMatch()
  const result = useMemo(() => {
    const currentLocation = breadCrumbsPaths({
      params,
      sessionParams,
      setSessionParams,
      flatOwner: owner.name,
      translation: t
    })[userRole]

    if (path === '/overview/flat-owner/detail/:flatOwnerId') {
      return currentLocation[path].map(
        (item, index) =>
          item.link !== '/owners' && (
            <BreadcrumbItem>
              <Link
                to={item.link}
                style={
                  currentLocation[path].length - 1 === index
                    ? { pointerEvents: 'none', color: '#495057' }
                    : {}
                }
                key={index}>
                {`\u00A0${item.text}`}
              </Link>
            </BreadcrumbItem>
          )
      )
    } else
      return currentLocation[path].map((item, index) => (
        <BreadcrumbItem>
          <Link
            to={item.link}
            style={
              currentLocation[path].length - 1 === index
                ? { pointerEvents: 'none', color: '#495057' }
                : {}
            }
            key={index}>
            {`\u00A0${item.text}`}
          </Link>
        </BreadcrumbItem>
      ))
  }, [owner, user, path])
  return result
}

const Breadcrumbs = () => {
  const { userRole, user } = useContext(UserContext)

  // role ids => 2: 'Owner', 3: 'Svj', 4: 'Manager'
  // hide breadcrumbs for flat owners
  if (user.role_id !== 2) {
    return (
      <Col md={{ span: 12 }}>
        <Breadcrumb style={{ display: 'flex', marginTop: 10, marginBottom: 0 }}>
          <BreadcrumbsMapPaths userRole={userRole} user={user} />
        </Breadcrumb>
      </Col>
    )
  } else {
    return null
  }
}

export default React.memo(Breadcrumbs)
