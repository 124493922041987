import { NotificationManager } from 'react-notifications'
import { useTranslation } from 'react-i18next'
import axios from 'axios'
import { Button, Modal, ModalBody } from 'reactstrap'
import querystring from 'querystring'
import React, { Fragment, useEffect, useState } from 'react'
import { ReactComponent as LogoCog } from '../../../assets/images/LogoComponents/ios-cog.svg'

import DataForm from './DataForm'
import PageTitleAlt from '../../../Layout/AppMain/PageTitleAlt'

const FlatOwnerDetail = ({ location, history }) => {
  let qs = location.search.replace('?', '')
  const { isFirst } = querystring.parse(qs)
  const { t, i18n } = useTranslation()
  const [open, setOpen] = useState(false)
  const [passwords, setPasswords] = useState({
    current_password: '',
    new_password: '',
    new_password_confirmation: ''
  })
  const [user, setUser] = useState({})
  const [loading, setLoading] = useState(false)
  const [submitting, setSubmitting] = useState(false)
  const [passwordSubmitting, setPasswordSubmitting] = useState(false)

  useEffect(() => {
    if (isFirst) {
      setOpen(true)
    }
    const fetchData = async () => {
      setLoading(true)
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/v1/users/profile`,
          {
            headers: {
              'Content-Type': 'application/json',
              'Accept': 'application/json',
              'Authorization': 'Bearer ' + localStorage.getItem('authToken'),
              'Content-Language': i18n.language === 'cz' ? 'cs' : 'en'
            }
          }
        )
        const userData = response.data.data
        setUser(userData)
        setLoading(false)
      } catch (error) {
        setLoading(false)
      }
    }
    fetchData()
  }, [])

  const onHandleFormSubmit = async event => {
    event.preventDefault()
    setSubmitting(true)
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/api/v1/users/profile`,
        { ...user },
        {
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('authToken'),
            'Content-Language': i18n.language === 'cz' ? 'cs' : 'en'
          }
        }
      )
      setUser(response.data.data)
      NotificationManager.success(t('common.success'))
      setSubmitting(false)
    } catch (error) {
      setSubmitting(false)
    }
  }

  const onHandleChangePasswordSubmit = async event => {
    event.preventDefault()
    setPasswordSubmitting(true)
    try {
      await axios.post(
        `${process.env.REACT_APP_API_URL}/api/v1/password/change-password`,
        { ...passwords },
        {
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('authToken'),
            'Content-Language': i18n.language === 'cz' ? 'cs' : 'en'
          }
        }
      )
      NotificationManager.success(t('common.success'))
      setPasswordSubmitting(false)
    } catch (error) {
      setPasswordSubmitting(false)
    }
  }

  const onHandleChangePasswords = e => {
    setPasswords({ ...passwords, [e.target.name]: e.target.value })
  }

  const onHandleChangeUser = e => {
    setUser({ ...user, [e.target.name]: e.target.value })
  }

  const handleModalClick = () => {
    setOpen(false)
    history.replace('/profile')
  }

  return (
    <Fragment>
      <Modal
        size='md'
        style={{ boxShadow: 'none' }}
        isOpen={open}
        centered
        toggle={() => setOpen(false)}>
        <ModalBody className='p-4'>
          {t('common.resetPasswordNotification')}
          <Button
            className='w-100 mt-3'
            color='primary'
            onClick={handleModalClick}>
            {t('common.iUnderstand')}
          </Button>
        </ModalBody>
      </Modal>
      <PageTitleAlt
        heading={t('pageTitles.settings')}
        icon={<LogoCog className='SVGLogo' style={{ fill: '#343a40' }} />}
      />

      <DataForm
        loading={loading}
        submitting={submitting}
        passwordSubmitting={passwordSubmitting}
        user={user}
        setUset={setUser}
        passwords={passwords}
        onHandleFormSubmit={onHandleFormSubmit}
        onHandleChangePasswordSubmit={onHandleChangePasswordSubmit}
        onHandleChangePasswords={onHandleChangePasswords}
        onHandleChangeUser={onHandleChangeUser}
      />
    </Fragment>
  )
}

export default FlatOwnerDetail
