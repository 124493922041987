import { useTranslation } from 'react-i18next'
import React, { Fragment } from 'react'

import {
  Col,
  Card,
  CardBody,
  Label,
  Input,
  Form,
  FormGroup,
  Row,
  Button,
  CardTitle
} from 'reactstrap'
import Loader from 'react-loaders'

const DataForm = ({
  user,
  passwords,
  onHandleChangePasswords,
  onHandleFormSubmit,
  loading
}) => {
  const { t } = useTranslation()

  return (
    <Fragment>
      {loading && (
        <div className='loader-wrapper w-100 d-flex justify-content-center align-items-center'>
          <Loader type='ball-beat' />
        </div>
      )}

      {!loading && (
        <React.Fragment>
          <Card className='main-card mb-3'>
            <CardBody>
              <CardTitle>{t('common.yourInformation')}</CardTitle>
              <Row>
                <Col md={6}>
                  <Form>
                    <FormGroup>
                      <Label for='name'>{t('common.name')}</Label>
                      <Input
                        id='name'
                        name='name'
                        value={user.first_name}
                        readOnly
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label for='surname'>{t('common.surname')}</Label>
                      <Input
                        id='surname'
                        name='surname'
                        value={user.last_name}
                        readOnly
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label for='email'>
                        {t('formFields.ownersEmailAddress')}
                      </Label>
                      <Input
                        id='email'
                        name='email'
                        value={user.email}
                        readOnly
                      />
                    </FormGroup>
                  </Form>
                </Col>
              </Row>
            </CardBody>
          </Card>
          <Card className='main-card mb-3'>
            <CardBody>
              <CardTitle>{t('common.changePassword')}</CardTitle>
              <Row>
                <Col>
                  <Form onSubmit={onHandleFormSubmit}>
                    <Row>
                      <Col md={4} sm={12}>
                        <FormGroup>
                          <Label for='currentPassword'>
                            {t('formFields.currentPassword')}
                          </Label>
                          <Input
                            id='currentPassword'
                            onChange={onHandleChangePasswords}
                            name='current_password'
                            type='password'
                            value={passwords.current_password}
                          />
                        </FormGroup>
                      </Col>
                      <Col md={4} sm={12}>
                        <FormGroup>
                          <Label for='newPassword'>
                            {t('formFields.newPassword')}
                          </Label>
                          <Input
                            id='newPassword'
                            onChange={onHandleChangePasswords}
                            name='new_password'
                            type='password'
                            value={passwords.new_password}
                          />
                        </FormGroup>
                      </Col>
                      <Col md={4} sm={12}>
                        <FormGroup>
                          <Label for='confirmPassword'>
                            {t('formFields.confirmNewPassword')}
                          </Label>
                          <Input
                            id='confirmPassword'
                            onChange={onHandleChangePasswords}
                            name='new_password_confirmation'
                            type='password'
                            value={passwords.new_password_confirmation}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <FormGroup>
                      <Button
                        type='submit'
                        color='primary'
                        style={{ width: 120 }}>
                        {t('common.save')}
                      </Button>
                    </FormGroup>
                  </Form>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </React.Fragment>
      )}
    </Fragment>
  )
}

export default DataForm
