import { useTranslation } from 'react-i18next'
import axios from 'axios'
import React, { Fragment, useState, useEffect } from 'react'
import { Row } from 'reactstrap'
import DeviceNotifications from './DeviceNotifications'
import PageTitleAlt from '../../../Layout/AppMain/PageTitleAlt'
import { SearchFiled } from '../../components/SearchField'
import { ReactComponent as LogoInfo } from '../../../assets/images/LogoComponents/ios-information.svg';

const sortTable = {
  "device_serial_number": "device_serial_number",
  "name_customer": "name_customer",
  "error_translations": "error_flag_decimal"
}

const DeviceNotificationsPage = ({ match }) => {
  const { t, i18n } = useTranslation()

  const [apiHeaders] = useState({
    'Content-Type': 'application/json',
    'Accept': 'application/json',
    'Authorization': 'Bearer ' + localStorage.getItem('authToken'),
    'Content-Language': i18n.language === 'cz' ? 'cs' : 'en'
  })
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState()
  const [sort, setSort] = useState('asc')
  const [filterValue, setFilterValue] = useState("")
  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(10)
  const [sortValue, setSortValue] = useState("")
  const [searchQuery, setSearchQuery] = useState('')
  const [lastPage, setLastPage] = useState(1)

  const fetchData = async (sortCol = false, col) => {
    setLoading(true)
    let sortQuery = ''
    try {
      if (sortCol) {
        sortQuery = `&order[0][column]=${col}&order[0][dir]=${sort}`
      }
      let response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/v1/devices?error_devices_only=1&length=${limit}&page=${page}${sortQuery}&search[value]=${searchQuery}`,
        {
          headers: apiHeaders
        }
      )
      setLastPage(response.data.data.last_page)
      setData(response.data.data.data)
    } catch (error) { console.log(error) }
    setLoading(false)
  }

  useEffect(() => {
    if (sortValue && sort !== "") fetchData(true, sortTable[sortValue])
    else fetchData()

  }, [page, limit, t, sort, searchQuery])

  const rightBlock = (
    <Row>
      <SearchFiled filterValue={filterValue} setFilterValue={setFilterValue} setSearchQuery={setSearchQuery} />
    </Row>
  )

  return (
    <Fragment>
      <PageTitleAlt
        heading={t('pageTitles.deviceNotifications')}
        icon={<LogoInfo className="SVGLogo" style={{ fill: '#343a40' }} />}
        rightBlock={rightBlock}
      />
      <DeviceNotifications
        data={data || []}
        loading={loading}
        fetchData={fetchData}
        page={page}
        limit={limit}
        setPage={setPage}
        setLimit={setLimit}
        lastPage={lastPage}
        setSortValue={setSortValue}
        sort={sort}
        setSort={setSort}
        sortValue={sortValue}
      />
    </Fragment>
  )
}

export default DeviceNotificationsPage
