import { connect } from 'react-redux'
import axios from 'axios'
import cx from 'classnames'
import React, { Fragment, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import HeaderDots from './Components/HeaderDots'
import HeaderLogo from '../AppLogo'
import UserBox from './Components/UserBox'
import { PopoverBody, UncontrolledPopover } from 'reactstrap'

export const Header = ({
  headerBackgroundColor,
  enableMobileMenuSmall,
  enableHeaderShadow,
  enableMobileMenu,
  ...props
}) => {
  const { i18n, t } = useTranslation()
  // eslint-disable-next-line
  const [apiHeaders, setApiHeaders] = useState({
    'Content-Type': 'application/json',
    'Accept': 'application/json',
    'Authorization': 'Bearer ' + localStorage.getItem('authToken'),
    'Content-Language': i18n.language === 'cz' ? 'cs' : 'en'
  })
  const [headerData, setHeaderData] = useState({})
  const [loading, setLoading] = useState(false)

  const getHeaderData = async () => {
    setLoading(true)
    try {
      let response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/v1/get-header-navigation`,
        {
          headers: apiHeaders
        }
      )

      setHeaderData(response.data)
      setLoading(false)
    } catch (error) {}
  }

  useEffect(() => {
    getHeaderData()
  }, [apiHeaders])

  return (
    <Fragment>
      <div
        component='div'
        className={cx('app-header', headerBackgroundColor, {
          'header-shadow': enableHeaderShadow
        })}>
        <HeaderLogo />
        <div className='mobile-contact'>
          <div className='mr-4'>{headerData.header_parentlevel_contact}</div>
          <div className='mr-4'>{headerData.header_parentlevel_email}</div>
        </div>

        {loading ? (
          <div />
        ) : (
          <div
            className={cx('app-header__content', {
              'header-mobile-open': enableMobileMenuSmall
            })}>
            <div className='app-header-right'>
              {!enableMobileMenuSmall ? (
                <div className='hide-on-mobile'>
                  <div className='mr-4' id='PopoverLegacy' type='button'>
                    {t('common.needHelp')}
                  </div>
                  <UncontrolledPopover
                    placement='bottom'
                    trigger='legacy'
                    target='PopoverLegacy'>
                    <PopoverBody>
                      <p className='m0'>{t('common.helpQuestion')}</p>
                      <a
                        className='tip__link'
                        target='_blank'
                        rel='noopener noreferrer'
                        href={require(`../../../src/assets/files/${t(
                          'common.installationManual'
                        )}`)}>
                        {t('common.helpLinkText')}
                      </a>
                      <hr className='mtb-3' />
                      <a
                        className='tip__link'
                        target='_blank'
                        rel='noopener noreferrer'
                        href={require(`../../../src/assets/files/${t(
                          'common.csvLink'
                        )}`)}>
                        {t('common.csvLinkText')}
                      </a>
                    </PopoverBody>
                  </UncontrolledPopover>
                  <div className='mr-4'>
                    {headerData.header_parentlevel_contact}
                  </div>
                  <div className='mr-4'>
                    {headerData.header_parentlevel_email}
                  </div>
                </div>
              ) : null}
              <HeaderDots />
              <UserBox name={headerData.header_authuser_name || 'User'} />
            </div>
          </div>
        )}
      </div>
    </Fragment>
  )
}

const mapStateToProps = state => ({
  enableHeaderShadow: state.ThemeOptions.enableHeaderShadow,
  enableMobileMenu: state.ThemeOptions.enableMobileMenu,
  closedSmallerSidebar: state.ThemeOptions.closedSmallerSidebar,
  headerBackgroundColor: state.ThemeOptions.headerBackgroundColor,
  enableMobileMenuSmall: state.ThemeOptions.enableMobileMenuSmall
})

const mapDispatchToProps = dispatch => ({})

export default connect(mapStateToProps, mapDispatchToProps)(Header)
