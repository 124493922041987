export const formatUnits = str => {
  if (!str) {
    return ''
  }

  let copy = str

  if (str.includes('.d')) copy = str.replace(/[^A-Za-z\d ]/g, '')
  if (str.includes('m3')) copy = str.replace(/m3/g, 'm\u00B3')
  if (str.includes('m2')) copy = str.replace(/m2/g, 'm\u00B2')
  return copy
}
