import React, { Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Button,
  Card,
  CardBody,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row
} from 'reactstrap'
import Loader from 'react-loaders'

const DataForm = ({
  user,
  setUser,
  passwords,
  onHandleChangePasswordSubmit,
  onHandleFormSubmit,
  onHandleChangePasswords,
  onHandleChangeUser,
  loading,
  passwordSubmitting,
  submitting
}) => {
  const { t } = useTranslation()

  return (
    <Fragment>
      {loading && (
        <div className='loader-wrapper w-100 d-flex justify-content-center align-items-center'>
          <Loader type='ball-beat' />
        </div>
      )}

      {!loading && (
        <React.Fragment>
          <Card className='main-card mb-3'>
            <CardBody>
              <Col md={6}>
                <Form onSubmit={onHandleFormSubmit}>
                  <FormGroup>
                    <Label for='name'>
                      {t('formFields.nameOfTheContactPerson')}
                    </Label>
                    <Input
                      onChange={onHandleChangeUser}
                      id='name'
                      name='first_name'
                      defaultValue={user.first_name || ''}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label for='surname'>
                      {t('formFields.surnameOfTheContactPerson')}
                    </Label>
                    <Input
                      onChange={onHandleChangeUser}
                      id='surname'
                      name='last_name'
                      defaultValue={user.last_name || ''}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label for='phone'>
                      {t('formFields.contactPhoneNumber')}
                    </Label>
                    <Input
                      onChange={onHandleChangeUser}
                      id='phone'
                      name='contact_no'
                      defaultValue={user.contact_no || ''}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label for='email'>
                      {t('formFields.contactEmailAddress')}
                    </Label>
                    <Input
                      onChange={onHandleChangeUser}
                      id='email'
                      name='contact_email'
                      defaultValue={user.contact_email || ''}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label for='website'>
                      {t('formFields.houseOwnersWebsite')}
                    </Label>
                    <Input
                      onChange={onHandleChangeUser}
                      id='website'
                      name='website'
                      defaultValue={user.website || ''}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Button
                      type='submit'
                      color='primary'
                      disabled={submitting}
                      style={{ width: 120 }}>
                      {submitting ? (
                        <Loader
                          color='#ffffff'
                          innerClassName='d-flex align-items-center justify-content-center'
                          style={{ height: 18 }}
                          type='ball-beat'
                        />
                      ) : (
                        t('common.save')
                      )}
                    </Button>
                  </FormGroup>
                </Form>
              </Col>
            </CardBody>
          </Card>
          <Card className='main-card mb-3'>
            <CardBody>
              <Col>
                <Form onSubmit={onHandleChangePasswordSubmit}>
                  <Row>
                    <Col md={4} sm={12}>
                      <FormGroup>
                        <Label for='currentPassword'>
                          {t('formFields.currentPassword')}
                        </Label>
                        <Input
                          id='currentPassword'
                          onChange={onHandleChangePasswords}
                          name='current_password'
                          type='password'
                          value={passwords.current_password}
                        />
                      </FormGroup>
                    </Col>
                    <Col md={4} sm={12}>
                      <FormGroup>
                        <Label for='newPassword'>
                          {t('formFields.newPassword')}
                        </Label>
                        <Input
                          id='newPassword'
                          onChange={onHandleChangePasswords}
                          name='new_password'
                          type='password'
                          value={passwords.new_password}
                        />
                      </FormGroup>
                    </Col>
                    <Col md={4} sm={12}>
                      <FormGroup>
                        <Label for='confirmPassword'>
                          {t('formFields.confirmNewPassword')}
                        </Label>
                        <Input
                          id='confirmPassword'
                          onChange={onHandleChangePasswords}
                          name='new_password_confirmation'
                          type='password'
                          value={passwords.new_password_confirmation}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <FormGroup>
                    <Button
                      type='submit'
                      color='primary'
                      disabled={passwordSubmitting}
                      style={{ width: 120 }}>
                      {passwordSubmitting ? (
                        <Loader
                          color='#ffffff'
                          innerClassName='d-flex align-items-center justify-content-center'
                          style={{ height: 18 }}
                          type='ball-beat'
                        />
                      ) : (
                        t('common.save')
                      )}
                    </Button>
                  </FormGroup>
                </Form>
              </Col>
            </CardBody>
          </Card>
        </React.Fragment>
      )}
    </Fragment>
  )
}

export default DataForm
