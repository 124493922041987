import React from 'react'
import { useTranslation } from 'react-i18next'
import { Input } from 'reactstrap'

const DataRanger = ({
  handlePeriodChange,
  placeholderText,
  period,
}) => {
  const { t } = useTranslation()
  return (
    <div className='data-ranger-wrap'>
      <Input
        onChange={handlePeriodChange}
        type='select'
        name='dataRanger'
        value={period}
        id='dataRanger'
        placeholder={placeholderText}>
        <option value='lifetime'>{t('common.lifetime')}</option>
        <option value='weekly'>{t('common.weekly')}</option>
        <option value='monthly'>{t('common.monthly')}</option>
        <option value='yearly'>{t('common.yearly')}</option>
        <option value='custom'>{t('common.range')}</option>
      </Input>
    </div>
  )
}

export default DataRanger
