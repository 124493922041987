import { Link } from 'react-router-dom'
import { NotificationManager } from 'react-notifications'
import { useTranslation } from 'react-i18next'
import axios from 'axios'
import DatePicker from 'react-datepicker'
import React, { Fragment, useState } from 'react'
import Select from 'react-select'
import moment from 'moment'
import 'react-datepicker/dist/react-datepicker.css'
import { ReactComponent as LogoAlbums } from '../../../assets/images/LogoComponents/ios-albums.svg'
import { ReactComponent as LogoPersonAdd } from '../../../assets/images/LogoComponents/ios-person-add.svg'

import {
  Button,
  Input,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Label,
  Spinner,
  Col
} from 'reactstrap'
import PageTitleAlt from '../../../Layout/AppMain/PageTitleAlt'
import Dashboard from './Dashboard3rdLevel'
import { SearchFiled } from '../../components/SearchField'
import { addDays } from 'date-fns'
import HelpMenu from 'assets/components/help-menu'

const Dashboard3rdLevel = () => {
  const { t, i18n } = useTranslation()
  const [uploadModal, setUploadModal] = useState(false)
  const [exportModal, setExportModal] = useState(false)
  const [csv, setCsv] = useState(null)
  const [uploadingCsv, setUploadingCsv] = useState(false)
  const [endDate, setEndDate] = useState(addDays(new Date(), -1))
  const [loading, setLoading] = useState(false)
  const [loadingExport, setLoadingExport] = useState(false)
  const [loadingDownloadFiles, setLoadingDownloadFiles] = useState(false)
  const [totalPages, setTotalPages] = useState(1)
  const [recordsCount, setRecordsCount] = useState(0)
  const [allSVJs, setAllSVjs] = useState([])
  const [lastPage, setLastPage] = useState(1)
  const [allCustomersInfo, setAllCustomersInfo] = useState([])
  const [filterValue, setFilterValue] = useState('')
  const [searchQuery, setSearchQuery] = useState('')
  const [pageLimit, setPageLimit] = useState(10)
  const [currentPage, setCurrentPage] = useState(1)
  const [optionsSVJ, setOptionsSVJ] = useState([])
  const [selectedOptionSVJ, setSelectedOptionSVJ] = useState(null)
  const [optionsInstallation, setOptionsInstallation] = useState([])
  const [selectedOptionInstallation, setSelectedOptionInstallation] = useState(
    null
  )
  const [startDateRange, setStartDateRange] = useState()
  const [endDateRange, setEndDateRange] = useState(null)
  const [isPickerOpen, setIsPickerOpen] = useState(false)
  const [dateRange, setDateRange] = useState('')
  const [currentFileType, setCurrentFileType] = useState('csv')
  const [SVJId, setSVJId] = useState()
  const [buildingId, setBuildingId] = useState()
  const [sort, setSort] = useState('asc')
  const [sortValue, setSortValue] = useState('')
  const fileType = [
    { value: 'csv', label: 'csv' },
    { value: 'pdf', label: 'pdf' },
    { value: 'xls', label: 'xls' }
  ]

  let apiHeaders = {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
    'Authorization': 'Bearer ' + localStorage.getItem('authToken'),
    'Content-Language': i18n.language === 'cz' ? 'cs' : 'en'
  }

  const toggleUploadModal = async () => {
    setSelectedOptionSVJ(null)
    setSelectedOptionInstallation(null)
    if (!optionsSVJ.length) {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/v1/svj-committees`,
          {
            headers: apiHeaders
          }
        )
        setAllCustomersInfo(response.data.data)
        setOptionsSVJ(
          response.data.data.map(svj => {
            return {
              value: svj.id,
              label: svj.name
            }
          })
        )
      } catch (error) {
        NotificationManager.error(t('common.error'))
      }
    }
    setUploadModal(!uploadModal)
  }
  const toggleExportModal = async () => {
    setSelectedOptionSVJ(null)
    setSelectedOptionInstallation(null)
    if (!optionsSVJ.length) {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/v1/svj-committees`,
          {
            headers: apiHeaders
          }
        )
        setAllCustomersInfo(response.data.data)
        setOptionsSVJ(
          response.data.data.map(svj => {
            return {
              value: svj.id,
              label: svj.name
            }
          })
        )
      } catch (error) {
        NotificationManager.error(t('common.error'))
      }
    }
    setExportModal(!exportModal)
  }

  const handleChange = event => {
    setCsv(event.target.files)
  }

  /* CSV upload handle */
  const handleUploadCSV = async () => {
    try {
      setUploadingCsv(true)
      const body = new FormData()
      body.append('device_list', csv[0])
      body.append('building_id', selectedOptionInstallation)
      body.append('svj_id', selectedOptionSVJ)
      await axios.post(
        `${process.env.REACT_APP_API_URL}/api/v1/upload-device-installation-csv`,
        body,
        {
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('authToken')
          }
        }
      )
      toggleUploadModal()
      NotificationManager.success(t('common.success'))
    } catch (error) {
      NotificationManager.error(t('common.error'))
    }
    setUploadingCsv(false)
  }

  const handleFetchData = async (sortCol = false, col) => {
    setLoading(true)
    const sortParams = sort ? `&order[0][dir]=${sort}` : ''
    const apiSearchEndpoint = `${
      process.env.REACT_APP_API_URL
    }/api/v1/svj-committees-grid?search[value]=${searchQuery}&length=${pageLimit}&page=${currentPage}&order[0][column]=${col ||
      'name'}${sortParams}`

    try {
      let response = null
      let sortQuery = ''

      if (sortCol) {
        sortQuery = `&order[0][column]=${col}&order[0][dir]=${sort}`
      }
      if (searchQuery === '') {
        response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/v1/svj-committees-grid?length=${pageLimit}&page=${currentPage}${sortQuery}`,
          {
            headers: apiHeaders
          }
        )
      } else {
        response = await axios.get(apiSearchEndpoint, {
          headers: apiHeaders
        })
      }
      setLastPage(response.data.data.last_page)
      setAllSVjs(response.data.data.data)

      if (searchQuery === '') {
        setTotalPages(Math.ceil(response.data.data.recordsTotal / pageLimit))
        setRecordsCount(response.data.data.recordsTotal)
      } else {
        setTotalPages(Math.ceil(response.data.data.recordsFiltered / pageLimit))
        setRecordsCount(response.data.data.recordsFiltered)
      }
    } catch (err) {
      NotificationManager.error(err.response.data.message)
    }
    setLoading(false)
  }

  const rightBlock = (
    <Row>
      <SearchFiled
        filterValue={filterValue}
        setFilterValue={setFilterValue}
        setSearchQuery={setSearchQuery}
      />
      <Col className='d-flex align-items-center'>
        <Link to='/svj/create' className='d-flex'>
          <Button
            className='ml-4 mb-2 mt-2 d-flex align-items-center'
            color='primary'>
            <LogoPersonAdd className='mr-1 SVGLogo' />
            {t('common.createANewSVJ')}
          </Button>
        </Link>
        <HelpMenu />
      </Col>

      {/* <Button
        color='primary'
        className='ml-4 mb-2 mt-2 d-flex align-items-center'
        onClick={() => toggleUploadModal()}>
          <LogoCloudUpload className='mr-1 SVGLogo'/>
        {t('common.upload')}
      </Button> */}
      <Button
        color='primary'
        className='mr-4 ml-4 mb-2 mt-2 d-flex align-items-center'
        onClick={() => toggleExportModal()}>
        {t('common.export')}
      </Button>
    </Row>
  )

  const handleChangeUploadSVJ = async selectedOption => {
    setSelectedOptionSVJ(selectedOption.value)
    setSVJId(selectedOption.value)
    setSelectedOptionInstallation(null)
    setOptionsInstallation([])
    try {
      const customerBuildings = allCustomersInfo.find(
        oneCustomer => oneCustomer.id === selectedOption.value
      ).buildings

      setOptionsInstallation(
        customerBuildings.map(item => {
          return {
            value: item.id,
            label: item.address
          }
        })
      )
    } catch (error) {
      NotificationManager.error(t('common.error'))
    }
  }

  const handleChangeUploadInstallation = async selectedOption => {
    setSelectedOptionInstallation(selectedOption.value)
    setBuildingId(selectedOption.value)
  }
  const handleDowloadFile = async () => {
    setLoadingDownloadFiles(true)
    const formatEndDate = `${endDate.getFullYear()}-${endDate.getMonth() +
      1}-${endDate.getDate()}`
    const config = {
      method: 'get',
      url: `${process.env.REACT_APP_API_URL}/api/v1/generate-status-report`,
      params: {
        customer: SVJId,
        installation: buildingId,
        file_type: currentFileType,
        return_type: 'file_path',
        date: formatEndDate
      }
    }
    const response = await axios(config)
    if (response) {
      const a = document.createElement('a')
      a.href = `${process.env.REACT_APP_API_URL}/${response.data.file_path}`
      a.download = `${process.env.REACT_APP_API_URL}/${response.data.file_path}`
      a.target = '_blank'
      document.body.appendChild(a)
      a.click()
      a.remove()
      window.URL.revokeObjectURL(
        `${process.env.REACT_APP_API_URL}/${response.file_path}`
      )
    }
    setLoadingDownloadFiles(false)
  }

  const handleDowloadFileStatuses = async () => {
    setLoadingExport(true)
    let start, end
    if (startDateRange && endDateRange) {
      start = moment(`${startDateRange}`).format('YYYY-MM-DD')
      end = moment(`${endDateRange}`).format('YYYY-MM-DD')
    }
    const config = {
      method: 'get',
      url: `${process.env.REACT_APP_API_URL}/api/v1/generate-reports`,
      params: {
        customer: SVJId,
        installation: buildingId,
        file_type: currentFileType,
        return_type: 'file_path',
        from: start,
        to: end
      }
    }
    const response = await axios(config)
    if (response) {
      const a = document.createElement('a')
      a.href = `${process.env.REACT_APP_API_URL}/${response.data.file_path}`
      a.download = `${process.env.REACT_APP_API_URL}/${response.data.file_path}`
      a.target = '_blank'
      document.body.appendChild(a)
      a.click()
      a.remove()
      window.URL.revokeObjectURL(
        `${process.env.REACT_APP_API_URL}/${response.file_path}`
      )
    }
    setLoadingExport(false)
  }

  const handleChangeFileType = selectedOption => {
    setCurrentFileType(selectedOption.value)
  }

  const onChangeDateRange = dates => {
    if (!dates) {
      setStartDateRange()
      setEndDateRange()
      setDateRange()
      return setIsPickerOpen(false)
    }
    const [start, end] = dates

    setStartDateRange(start)
    setEndDateRange(end)
    if (start && end) {
      setDateRange(
        moment(`${start}`).format('DD.MM.YYYY') +
          ' - ' +
          moment(`${end}`).format('DD.MM.YYYY')
      )
      setIsPickerOpen(false)
    }
  }

  const onClickOutside = () => {
    if (!startDateRange || !endDateRange) {
      setStartDateRange()
      setEndDateRange()
      setDateRange()
    }
    setIsPickerOpen(false)
  }

  return (
    <Fragment>
      <PageTitleAlt
        heading={t('pageTitles.dashboard')}
        icon={<LogoAlbums style={{ fill: '#343a40' }} className='SVGLogo' />}
        rightBlock={rightBlock}
      />

      <Dashboard
        searchQuery={searchQuery}
        limit={pageLimit}
        setLimit={setPageLimit}
        setPage={setCurrentPage}
        lastPage={lastPage}
        allSVJs={allSVJs}
        loading={loading}
        recordsCount={recordsCount}
        totalPages={totalPages}
        onSetLimit={setPageLimit}
        onSetPage={setCurrentPage}
        currentPage={currentPage}
        fetchData={handleFetchData}
        setSortValue={setSortValue}
        sort={sort}
        setSort={setSort}
        sortValue={sortValue}
      />
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {/* <Modal
          isOpen={uploadModal}
          toggle={() => !uploadingCsv && toggleUploadModal()}>
          <ModalHeader toggle={() => !uploadingCsv && toggleUploadModal()}>
            {t('common.upload')}
          </ModalHeader>
          <ModalBody>
            <div style={{ margin: '10px 5px' }}>
              <Select
                options={optionsSVJ}
                onChange={handleChangeUploadSVJ}
                className='basic-multi-select'
                classNamePrefix='select'
              />
            </div>
            {selectedOptionSVJ && optionsInstallation.length > 0 && (
              <div style={{ margin: '10px 5px' }}>
                <Select
                  options={optionsInstallation}
                  onChange={handleChangeUploadInstallation}
                  className='basic-multi-select'
                  classNamePrefix='select'
                />
              </div>
            )}
            <Input
              type='file'
              name='file'
              label='Upload CSV'
              labelPosition='right'
              placeholder='UploadCSV...'
              onChange={handleChange}
            />
          </ModalBody>
          <ModalFooter>
            <Button
              disabled={uploadingCsv}
              color='danger'
              onClick={() => toggleUploadModal()}>
              {t('common.cancel')}
            </Button>{' '}
            {!uploadingCsv ? (
              <Button color='secondary' onClick={() => handleUploadCSV()}>
                {t('common.upload')}
              </Button>
            ) : (
                <Spinner size='sm' color='secondary' />
              )}
          </ModalFooter>
        </Modal> */}
        <Modal isOpen={exportModal} toggle={() => toggleExportModal()}>
          <ModalHeader toggle={() => toggleExportModal()}>
            {t('common.export')}
          </ModalHeader>
          <ModalBody>
            <div style={{ margin: '10px 5px' }}>
              <Select
                options={optionsSVJ}
                onChange={handleChangeUploadSVJ}
                className='basic-multi-select'
                classNamePrefix='select'
                placeholder={t('common.selectPlaceholder')}
              />
            </div>
            {selectedOptionSVJ &&
              (optionsInstallation.length !== 0 ? (
                <div style={{ margin: '10px 5px' }}>
                  <Select
                    options={optionsInstallation}
                    onChange={handleChangeUploadInstallation}
                    className='basic-multi-select'
                    classNamePrefix='select'
                    placeholder={t('common.selectPlaceholder')}
                  />
                </div>
              ) : (
                <Label className='ml-2'>
                  This customer has no installations
                </Label>
              ))}

            <div style={{ margin: '10px 5px' }}>
              <Select
                options={fileType}
                onChange={handleChangeFileType}
                className='basic-multi-select'
                classNamePrefix='select'
                defaultValue={{ label: 'csv', value: 'csv' }}
              />
            </div>
            <div
              style={{
                margin: '10px 5px',
                display: 'flex',
                flexDirection: 'column'
              }}>
              <Label className='mr-2'>{t('common.tillDate')}</Label>
              <div className='date-picker-with-btn'>
                <DatePicker
                  locale={i18n.language}
                  customInput={<Input />}
                  selected={endDate}
                  onChange={date => setEndDate(date)}
                  dateFormat='dd.MM.yyyy'
                  maxDate={addDays(new Date(), -1)}
                />
                {SVJId && buildingId && !loadingDownloadFiles ? (
                  <Button
                    className='export-btn'
                    color='secondary'
                    onClick={handleDowloadFile}
                    disabled={loadingExport}>
                    {t('common.exportStatuses')}
                  </Button>
                ) : null}
                {loadingDownloadFiles && (
                  <Button className='export-btn' color='light'>
                    <Spinner
                      as='span'
                      type='grow'
                      size='sm'
                      role='status'
                      aria-hidden='true'
                    />{' '}
                    {t('common.exportData')}
                  </Button>
                )}
              </div>
            </div>
            <div
              style={{
                margin: '10px 5px',
                display: 'flex',
                flexDirection: 'column'
              }}>
              <Label className='mr-2'>{t('common.fromTo')}</Label>
              <div className='date-picker-with-btn'>
                <DatePicker
                  locale={i18n.language}
                  customInput={<Input />}
                  selected={startDateRange}
                  onChange={onChangeDateRange}
                  selectsEnd
                  startDate={startDateRange}
                  endDate={endDateRange}
                  selectsRange
                  onInputClick={() => setIsPickerOpen(true)}
                  open={isPickerOpen}
                  value={dateRange}
                  maxDate={addDays(new Date(), -1)}
                  onClickOutside={() => onClickOutside()}
                  className='datepicker'
                  placeholderText={t('common.dateRangePlaceholder')}
                  isClearable
                  dateFormat='dd.MM.yyyy'
                  filterDate={d => {
                    return new Date() > d
                  }}
                />
                {SVJId && buildingId && !loadingExport ? (
                  <Button
                    className='export-btn'
                    color='secondary'
                    onClick={handleDowloadFileStatuses}
                    disabled={loadingDownloadFiles}>
                    {t('common.exportSpent')}
                  </Button>
                ) : null}
                {loadingExport && (
                  <Button className='export-btn' color='light'>
                    <Spinner
                      as='span'
                      type='grow'
                      size='sm'
                      role='status'
                      aria-hidden='true'
                    />{' '}
                    {t('common.exportData')}
                  </Button>
                )}
              </div>
            </div>
          </ModalBody>
        </Modal>
      </div>
    </Fragment>
  )
}

export default Dashboard3rdLevel
