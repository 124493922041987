import { Button, Col, Form, FormGroup, Input, Label, Row } from 'reactstrap'
import { NotificationManager } from 'react-notifications'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import axios from 'axios'
import React, { Fragment, useCallback, useContext, useState } from 'react'

import SliderLeft from '../../components/SliderLeft'
import UserContext from '../../../contexts/authContext'

const Login = () => {
  const { t, i18n } = useTranslation()
  const history = useHistory()
  const [email, setEmail] = useState(null)
  const [password, setPassword] = useState(null)
  const [wrongcredentials, setWrongCredentials] = useState(false)
  const [showError, setShowError] = useState(
    { noEmail: false },
    { noPassword: false }
  )
  const { setUser } = useContext(UserContext)

  const handleClickRecoverPassword = () => {
    history.push('/forgot-password')
  }

  const signIn = useCallback(e => {
    e.preventDefault()
    setShowError({ noEmail: false }, { noPassword: false })
    setWrongCredentials(false)
    if (!email) {
      setShowError({ noEmail: true })
      return
    }
    if (!password) {
      setShowError({ noPassword: true })
      return
    }

    const data = { email: email, password: password }
    axios
      .post(`${process.env.REACT_APP_API_URL}/api/v1/auth/login`, data, {
        headers: {
          'Content-Language': i18n.language === 'cz' ? 'cs' : 'en'
        }
      })
      .then(function(response) {
        // handle success
        setUser(response.data.data.user)
        localStorage.setItem('authToken', response.data.data.access_token)
        localStorage.setItem('email', response.data.data.email)
        localStorage.setItem('name', response.data.data.name)
        localStorage.setItem('roleId', response.data.data.user.role_id)
        if (response.data.data.user.is_first)
          history.push('/profile?isFirst=true')
        else history.push('/dashboard')
        NotificationManager.success(t('common.success'))
      })
      .catch(function(error) {
        // handle error
        setWrongCredentials(true)
        return true
      })
      .finally(() => {
        return true
      })
  })

  const onKeyDownHandler = e => {
    if (e.key === 'Enter') {
      signIn(e)
    }
  }

  localStorage.setItem('roleId', '')

  return (
    <Fragment>
      <div className='h-100'>
        <Row className='h-100 no-gutters'>
          <Col lg='4' className='d-none d-lg-block'>
            <div className='slider-light'>
              <SliderLeft />
            </div>
          </Col>
          <Col
            lg='8'
            md='12'
            className='h-100 d-flex bg-white justify-content-center align-items-center'>
            <Col lg='9' md='10' sm='12' className='mx-auto app-login-box'>
              <div
                className='app-logo'
                style={{
                  backgroundImage: `url(${process.env.REACT_APP_LOGO})`,
                  width: `${process.env.REACT_APP_LOGO_WIDTH}px`,
                  height: `${process.env.REACT_APP_LOGO_HEIGHT}px`
                }}
              />
              <h4 className='mb-0'>
                <span>{t('authentication.pleaseSignInToYourAccount')}.</span>
              </h4>
              <Row className='divider' />
              <div>
                <Form onSubmit={signIn} onKeyDown={onKeyDownHandler}>
                  <Row form>
                    <Col md={6}>
                      <FormGroup>
                        <Label for='exampleEmail'>
                          {t('formFields.email')}
                        </Label>
                        <Input
                          type='email'
                          name='email'
                          id='exampleEmail'
                          placeholder={t('formFields.emailPlaceholder')}
                          onChange={e => setEmail(e.currentTarget.value)}
                        />
                      </FormGroup>
                    </Col>
                    <Col md={6}>
                      <FormGroup>
                        <Label for='examplePassword'>
                          {t('formFields.password')}
                        </Label>
                        <Input
                          type='password'
                          name='password'
                          id='examplePassword'
                          placeholder={t('formFields.passwordPlaceholder')}
                          onChange={e => setPassword(e.currentTarget.value)}
                        />
                      </FormGroup>
                    </Col>
                  </Row>

                  <FormGroup check>
                    <Input type='checkbox' name='check' id='exampleCheck' />
                    <Label for='exampleCheck' check>
                      {t('authentication.keepMeLoggedIn')}
                    </Label>
                  </FormGroup>
                  <Row className='divider' />
                  {wrongcredentials && (
                    <Row className='text-danger mx-auto pb-3'>
                      <span>{t('common.wrongCredentialsMessage')}</span>
                    </Row>
                  )}
                  {showError.noEmail && (
                    <Row className='text-danger mx-auto pb-3'>
                      <span>{t('common.noEmailMessage')}</span>
                    </Row>
                  )}
                  {showError.noPassword && (
                    <Row className='text-danger mx-auto pb-3'>
                      <span>{t('common.noPasswordMessage')}</span>
                    </Row>
                  )}
                  <div className='d-flex align-items-center'>
                    <div className='ml-auto'>
                      <span
                        className='btn-lg btn'
                        onClick={handleClickRecoverPassword}>
                        {t('authentication.recoverPassword')}
                      </span>{' '}
                      <Button color='primary' size='lg' type='submit'>
                        {t('authentication.loginToDashboard')}
                      </Button>
                    </div>
                  </div>
                </Form>
              </div>
            </Col>
          </Col>
        </Row>
      </div>
    </Fragment>
  )
}

export default Login
